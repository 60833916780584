import { v } from "~libs/valibot";

export enum TabSearchParams {
  SUMMARY = "summary",
  SCRIPT = "script",
  CHAT = "chat",
}

export const TabsSearchSchema = v.object({
  tab: v.optional(v.enum(TabSearchParams), TabSearchParams.SUMMARY),
});

export enum TAB_KEYS {
  SUMMARY = 0,
  SCRIPT = 1,
  CHAT = 2,
}

export const TABS = [
  { key: TAB_KEYS.SUMMARY, nameKr: "요약 내용", nameEn: "summary" },
  { key: TAB_KEYS.SCRIPT, nameKr: "스크립트", nameEn: "script" },
  { key: TAB_KEYS.CHAT, nameKr: "앨런과 대화", nameEn: "chat" },
] as const;
