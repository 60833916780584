import {
  type InferOutput,
  nullable,
  parse,
  pipe,
  safeParse,
  transform,
  array as vArray,
  literal as vLiteral,
  number as vNumber,
  object as vObject,
  string as vString,
} from "valibot";
import { MESSAGE_ERROR_CONTENTS } from "~features/messages/constants";
import { RESPONSE_MESSAGE_TYPES } from "./constants";

/** @deprecated - use MESSAGE_STATUS */
enum ChatMessageTypes {
  SAVED_MESSAGE = "message-saved",
  START_AGENT = "start-agent",
  TOOL_ACTION = "tool-action",
  IN_ANSWER = "in-answer",
  FINISH_ANSWER = "complete-answer",
  PAUSED_BY_LENGTH = "paused-by-length",
  CONTINUE_START_AGENT = "continue-start-agent",
  SUGGESTIONS = "suggestions",
  ERROR = "error",
  CANCELED = "canceled",
}

const SavedMessageSchema$Socket = vObject({
  data: vObject({
    type: vLiteral(RESPONSE_MESSAGE_TYPES.SAVED_MESSAGE),
    request_id: vString(),
  }),
});

type SavedMessage$Socket = InferOutput<typeof SavedMessageSchema$Socket>;

export const isSavedMessage$Socket = (
  input: unknown,
): input is SavedMessage$Socket =>
  safeParse(SavedMessageSchema$Socket, input, { abortEarly: true }).success;

const SavedMessageSchema = pipe(
  SavedMessageSchema$Socket,
  transform((input) => ({
    type: ChatMessageTypes.SAVED_MESSAGE,
    requestId: input.data.request_id,
  })),
);

type SavedMessage = InferOutput<typeof SavedMessageSchema>;

export const parseSavedMessage = (input: unknown): SavedMessage =>
  parse(SavedMessageSchema, input);

const StartAgentMessageSchema$Socket = vObject({
  data: vObject({
    type: vLiteral(RESPONSE_MESSAGE_TYPES.START_AGENT),
    request_id: vString(),
    persona_id: vString(),
  }),
});

type StartAgentMessage$Socket = InferOutput<
  typeof StartAgentMessageSchema$Socket
>;

export const isStartAgentMessage$Socket = (
  input: unknown,
): input is StartAgentMessage$Socket =>
  safeParse(StartAgentMessageSchema$Socket, input, { abortEarly: true })
    .success;

const StartAgentMessageSchema = pipe(
  StartAgentMessageSchema$Socket,
  transform((result) => ({
    type: ChatMessageTypes.START_AGENT,
    requestId: result.data.request_id,
    personaId: result.data.persona_id,
  })),
);

export type StartAgentMessage = InferOutput<typeof StartAgentMessageSchema>;

export const parseStartAgentMessage = (input: unknown): StartAgentMessage =>
  parse(StartAgentMessageSchema, input);

const ToolActionMessageSchema$Socket = vObject({
  data: vObject({
    type: vLiteral(RESPONSE_MESSAGE_TYPES.ACTION),
    request_id: vString(),
    persona_id: vString(),
    actions: vArray(
      vObject({
        name: vString(),
        speak: vString(),
      }),
    ),
  }),
});

type ToolActionMessage$Socket = InferOutput<
  typeof ToolActionMessageSchema$Socket
>;

export const isToolActionMessage$Socket = (
  input: unknown,
): input is ToolActionMessage$Socket =>
  safeParse(ToolActionMessageSchema$Socket, input, { abortEarly: true })
    .success;

const ToolActionMessageSchema = pipe(
  ToolActionMessageSchema$Socket,

  transform((result) => ({
    type: ChatMessageTypes.TOOL_ACTION,
    payload: {
      personaId: result.data.persona_id,
    },
  })),
);

export type ToolActionMessage = InferOutput<typeof ToolActionMessageSchema>;

export const parseToolActionMessage = (input: unknown): ToolActionMessage =>
  parse(ToolActionMessageSchema, input);

const InAnswerMessageSchema$Socket = vObject({
  data: vObject({
    type: vLiteral(RESPONSE_MESSAGE_TYPES.IN_ANSWER),
    request_id: vString(),
    content: vString(),
    order: vNumber(),
  }),
});

type InAnswerMessage$Socket = InferOutput<typeof InAnswerMessageSchema$Socket>;

export const isInAnswerMessage$Socket = (
  input: unknown,
): input is InAnswerMessage$Socket =>
  safeParse(InAnswerMessageSchema$Socket, input, { abortEarly: true }).success;

const InAnswerMessageSchema = pipe(
  InAnswerMessageSchema$Socket,
  transform((result) => ({
    type: ChatMessageTypes.IN_ANSWER,
    payload: {
      token: result.data.content,
      order: result.data.order,
    },
  })),
);

type InAnswerMessage = InferOutput<typeof InAnswerMessageSchema>;

export const parseInAnswerMessage = (input: unknown): InAnswerMessage =>
  parse(InAnswerMessageSchema, input);

const FinishAnswerMessageSchema$Socket = vObject({
  data: vObject({
    type: vLiteral(RESPONSE_MESSAGE_TYPES.FINISH_ANSWER),
    id: vString(),
    stop_reason: nullable(vLiteral("stop")),
    content: vString(),
  }),
});

type FinishAnswerMessage$Socket = InferOutput<
  typeof FinishAnswerMessageSchema$Socket
>;

export const isFinishAnswerMessage$Socket = (
  input: unknown,
): input is FinishAnswerMessage$Socket =>
  safeParse(FinishAnswerMessageSchema$Socket, input, { abortEarly: true })
    .success;

const FinishAnswerMessageSchema = pipe(
  FinishAnswerMessageSchema$Socket,

  transform((result) => ({
    type: ChatMessageTypes.FINISH_ANSWER,
    payload: {
      id: result.data.id,
      content: result.data.content,
    },
  })),
);

type FinishAnswerMessage = InferOutput<typeof FinishAnswerMessageSchema>;

export const parseFinishAnswerMessage = (input: unknown): FinishAnswerMessage =>
  parse(FinishAnswerMessageSchema, input);

const PausedByLengthMessageSchema$Socket = vObject({
  data: vObject({
    id: vString(),
    type: vLiteral(RESPONSE_MESSAGE_TYPES.FINISH_ANSWER),
    stop_reason: vLiteral("length"),
    content: vString(),
  }),
});

type PausedByLengthMessage$Socket = InferOutput<
  typeof PausedByLengthMessageSchema$Socket
>;

export const isPausedByLengthMessage$Socket = (
  input: unknown,
): input is PausedByLengthMessage$Socket =>
  safeParse(PausedByLengthMessageSchema$Socket, input, { abortEarly: true })
    .success;

const PausedByLengthMessageSchema = pipe(
  PausedByLengthMessageSchema$Socket,
  transform((result) => ({
    id: result.data.id,
    type: ChatMessageTypes.PAUSED_BY_LENGTH,
    payload: result.data.content,
  })),
);

type PausedByLengthMessage = InferOutput<typeof PausedByLengthMessageSchema>;

export const parsePausedByLengthMessage = (
  input: unknown,
): PausedByLengthMessage =>
  parse(PausedByLengthMessageSchema, input, { abortEarly: true });

const SuggestionsMessageSchema$Socket = vObject({
  data: vObject({
    suggest_questions: vArray(vString()),
  }),
});

type SuggestionsMessage$Socket = InferOutput<
  typeof SuggestionsMessageSchema$Socket
>;

export const isSuggestionsMessage$Socket = (
  input: unknown,
): input is SuggestionsMessage$Socket =>
  safeParse(SuggestionsMessageSchema$Socket, input, { abortEarly: true })
    .success;

const SuggestionsMessageSchema = pipe(
  SuggestionsMessageSchema$Socket,

  transform((result) => ({
    type: ChatMessageTypes.SUGGESTIONS,
    payload: result.data.suggest_questions,
  })),
);

type SuggestionsMessage = InferOutput<typeof SuggestionsMessageSchema>;

export const parseSuggestionsMessage = (input: unknown): SuggestionsMessage =>
  parse(SuggestionsMessageSchema, input);

const ContentFilterErrorInStopReasonMessageSchema$Socket = vObject({
  data: vObject({
    type: vLiteral(RESPONSE_MESSAGE_TYPES.FINISH_ANSWER),
    stop_reason: vLiteral("content_filter"),
  }),
});

type ContentFilterErrorMessageInStopReason$Socket = InferOutput<
  typeof ContentFilterErrorInStopReasonMessageSchema$Socket
>;

export const isContentFilterErrorInStopReasonMessage$Socket = (
  input: unknown,
): input is ContentFilterErrorMessageInStopReason$Socket =>
  safeParse(ContentFilterErrorInStopReasonMessageSchema$Socket, input, {
    abortEarly: true,
  }).success;

const ContentFilterErrorInStopReasonMessageSchema = pipe(
  ContentFilterErrorInStopReasonMessageSchema$Socket,

  transform((result) => ({
    type: ChatMessageTypes.ERROR,
    payload: result.data.type,
  })),
);

type ContentFilterErrorInStopReasonMessage = InferOutput<
  typeof ContentFilterErrorInStopReasonMessageSchema
>;

export const parseContentFilterErrorMessage = (
  input: unknown,
): ContentFilterErrorInStopReasonMessage =>
  parse(ContentFilterErrorInStopReasonMessageSchema, input);

/**
 */
const ContentFilterErrorInTypeMessageSchema$Socket = vObject({
  data: vObject({
    type: vLiteral(RESPONSE_MESSAGE_TYPES.CONTENT_FILTERED_ERROR),
    stop_reason: nullable(
      vLiteral(RESPONSE_MESSAGE_TYPES.CONTENT_FILTERED_ERROR),
    ),
    content: vString(),
  }),
});

type ContentFilterErrorInTypeMessage$Socket = InferOutput<
  typeof ContentFilterErrorInTypeMessageSchema$Socket
>;

export const isContentFilterErrorInTypeMessage$Socket = (
  input: unknown,
): input is ContentFilterErrorInTypeMessage$Socket =>
  safeParse(ContentFilterErrorInTypeMessageSchema$Socket, input, {
    abortEarly: true,
  }).success;

const ContentFilterErrorInTypeMessageSchema = pipe(
  ContentFilterErrorInTypeMessageSchema$Socket,

  transform((result) => ({
    type: ChatMessageTypes.ERROR,
    payload: result.data.type,
  })),
);

type ContentFilterErrorInTypeMessage = InferOutput<
  typeof ContentFilterErrorInTypeMessageSchema
>;

export const parseContentFilterErrorInTypeMessage = (
  input: unknown,
): ContentFilterErrorInTypeMessage =>
  parse(ContentFilterErrorInTypeMessageSchema, input);

const CanceledMessageSchema$Socket = vObject({
  data: vObject({
    type: vLiteral(RESPONSE_MESSAGE_TYPES.CANCEL),
    request_id: vString(),
  }),
});

type CanceledMessage$Socket = InferOutput<typeof CanceledMessageSchema$Socket>;

export const isCanceledMessage$Socket = (
  input: unknown,
): input is CanceledMessage$Socket =>
  safeParse(CanceledMessageSchema$Socket, input, { abortEarly: true }).success;

const CanceledMessageSchema = pipe(
  CanceledMessageSchema$Socket,
  transform((result) => ({
    requestId: result.data.request_id,
    type: RESPONSE_MESSAGE_TYPES.ERROR,
    content: vLiteral(MESSAGE_ERROR_CONTENTS.canceled),
  })),
);

export type CanceledMessage = InferOutput<typeof CanceledMessageSchema>;

export const parseCanceledMessage = (input: unknown): CanceledMessage =>
  parse(CanceledMessageSchema, input);
