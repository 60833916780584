import StopIcon from "~assets/images/btn_search_stop.png";
import { Button } from "~features/ui/button";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./sending-button.module.scss";

type buttonShape = "default" | "black" | "stop";

interface SendingButtonProps {
  type?: buttonShape;
  onClick?: () => void;
  onSubmit?: () => void;
}

export const SendingButton: React.FC<SendingButtonProps> = ({
  onClick,
  onSubmit,
  ...props
}) => {
  const _onClick = createEventHandler({
    handler: () => {
      onClick?.();
    },
  });

  if (props.type === "stop") {
    return (
      <Button
        {...props}
        onClick={_onClick}
        type="onlyIcon"
        variant="radius"
        aria-label="search button"
        iconBefore={<img src={StopIcon} alt="" />}
      />
    );
  }

  const _onSubmit = createEventHandler({
    handler: () => {
      onSubmit?.();
    },
  });

  return (
    <Button
      {...props}
      onClick={_onSubmit}
      overrideClass={cn(styles.btn_send, {
        [styles.black]: props.type === "black",
      })}
      type="onlyIcon"
      variant="radius"
      aria-label="search button"
      iconBefore={
        <svg
          width="20"
          height="15"
          viewBox="0 0 20 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M12 1L18.5 7.5L12 14" stroke="white" strokeWidth="2" />
          <path d="M18.5 7.5H0.5" stroke="white" strokeWidth="2" />
        </svg>
      }
    />
  );
};
