import { useMemo } from "react";
import { useWindowSize } from "usehooks-ts";
import {
  DESKTOP_WIDTH_DEFAULT,
  MOBILE_WIDTH_DEFAULT,
  MOBILE_WIDTH_SMALL,
} from "~constants/sizes";

export const useWindowWidth = () => {
  const { width } = useWindowSize();

  return useMemo(
    () => ({
      isMobileSmallSize: width <= MOBILE_WIDTH_SMALL,
      isMobileSize: width <= MOBILE_WIDTH_DEFAULT,
      isNotMobileSize: width > MOBILE_WIDTH_DEFAULT,
      isDesktopSize: width >= DESKTOP_WIDTH_DEFAULT,
    }),
    [width],
  );
};
