import { NativeBridgeCore } from "./_core";
import { RequestTypes } from "./request.types";

declare global {
  interface Window {
    AndroidInterface?: {
      handleJsMessage: (args?: any) => void;
    };
  }
}

export class NativeBridgeAndroid extends NativeBridgeCore {
  constructor() {
    super();
    if (!this.bridge) {
      this.setupBridgeInterface();
    }
  }

  protected setupBridgeInterface(): void {
    this.bridge = window.AndroidInterface?.handleJsMessage;

    if (this.bridge && this.requestQueue.length > 0) {
      this.runQueueMessages();
    }
  }

  protected sendMessageToBridge(message: string) {
    window.AndroidInterface?.handleJsMessage(message);
  }

  requestUpdateSttState(isSttMode: boolean) {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.setSttOperation,
      data: isSttMode,
    });

    this.postRequestMessage({ message: requestMessage });
  }
}
