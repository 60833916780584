import {
  ErrorPopup,
  NoticeModal,
  MaintenanceModal,
  useErrorInfo,
  useModal,
  useModalVisibleLocal,
} from "~features/modals";

/** @todo 겹치면? - 단일 모달만 뜨도록 리팩터링 */
export function ModalsCoreLayout() {
  const { hasModalContent } = useModal();
  const { isModalVisible, updateModalVisible } = useModalVisibleLocal();
  const { hasError } = useErrorInfo();

  return (
    <>
      {hasError && <ErrorPopup />}
      {hasModalContent && <NoticeModal />}
      {isModalVisible && (
        <MaintenanceModal
          title="앨런 서비스 점검 안내"
          details={
            "아래 기간 동안 서비스가 일시 중단됩니다.\n이용에 참고해주세요.\n서비스 이용에 불편을 드려 죄송합니다."
          }
          schedule="2024. 12. 4(수) 11:00 ~ 12:00"
          onClickConfirm={() => {
            updateModalVisible(false);
          }}
        />
      )}
    </>
  );
}
