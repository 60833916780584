import { v } from "~libs/valibot";
import { MESSAGE_STATUS$SERVER } from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @example
 ```json
 {
  "type": "message",
  "from": "group",
  "fromUserId": null,
  "group": "66967876e303453a6e23441d",
  "dataType": "json",
  "data": {
    "id": null,
    "request_id": "3ca88dfc-437f-11ef-ab5a-568aa2b74468",
    "persona_id": "669191ccfa9cf2010f533369",
    "channel_id": "66967876e303453a6e23441d",
    "user_id": "65af73b6583a64a91bd6e002",
    "client_id": "7b96ccc8-f019-45e0-82df-62f40aa26553",
    "user_role": "assistant",
    "type": "start_agent",
    "stop_reason": null,
    "content": "",
    "actions": [],
    "metadata": [],
    "gpt_info": {},
    "order": null,
    "created_at": "2024-07-16T14:25:32.017529Z",
    "updated_at": "2024-07-16T14:25:32.017536Z"
  }
 }
 ```
 */
const StartAgentMessageSchema$Socket = v.pipe(
  v.object({
    type: v.literal(MESSAGE_STATUS$SERVER.START_AGENT),
    request_id: MessagesSchemas$Api.Item.entries.request_id,
    persona_id: MessagesSchemas$Api.Item.entries.persona_id,
  }),

  v.transform((input) => ({
    requestId: input.request_id,
    personaId: input.persona_id,
  })),
);

export const parseStartAgentMessage$Socket = (input: unknown) =>
  v.parse(StartAgentMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const StartAgentMessagePattern$Socket = getMessagePattern({
  type: MESSAGE_STATUS$SERVER.START_AGENT,
});
