import { forwardRef } from "react";
import { cn } from "~utils/class-names";

import styles from "./intersection-observing-area.module.scss";

export const IntersectionObservingArea = forwardRef<HTMLLIElement>(
  (_props, ref) => {
    return <li ref={ref} className={cn(styles.observing_area, styles.p_0)} />;
  },
);
