import { v } from "~libs/valibot";
import { logger } from "~utils/logger";
import { EMPTY_NATIVE_MESSAGE } from "./receive.constants";
import {
  type NativeMessageAccessTokenResponse,
  NativeMessageAccessTokenResponseDataSchema,
  type NativeMessageCore,
  NativeMessageSchemaCore,
} from "./receive.types";
import {
  type PostRequestMessageArgs,
  type RequestCallbacks,
  type RequestMessage,
  type RequestNotification,
  RequestTypes,
} from "./request.types";
import {
  type IsForegroundResponseData,
  IsForegroundResponseDataSchema,
} from "./schemas/is-foreground-message";
import {
  type PermissionCheckRequest,
  PermissionCheckRequestSchema,
  PermissionNames,
  type PermissionRequest,
  PermissionRequestSchema,
  type PermissionResponseData,
  PermissionResponseDataSchema,
} from "./schemas/permission-message";
import {
  type RefreshAccessToken,
  RefreshAccessTokenRequestSchema,
  RefreshAccessTokenResponseDataSchema,
} from "./schemas/refresh-access-token-message";

export class NativeBridgeCore {
  protected requestId = 0;
  protected bridge: undefined | ((message: string) => void) = undefined;
  protected permissions: Set<PermissionNames> = new Set();

  requestQueue: RequestMessage[] = [];
  /** @todo callback types, WeakMap 활용 */
  protected responseCallbacks = new Map<
    string | number,
    {
      func: (message: NativeMessageCore, ...args: any[]) => void;
      isCanceled: boolean;
    }
  >();

  protected setupBridgeInterface() {}

  /** Receive */
  parseNativeMessage(message = ""): NativeMessageCore {
    if (!message) return EMPTY_NATIVE_MESSAGE;

    try {
      return v.parse(NativeMessageSchemaCore, JSON.parse(message));
    } catch (error) {
      logger.debugRemote({
        scope: "NativeBridgeCore.parseNativeMessage.error",
        error: (error as Error).message,
      });
      return EMPTY_NATIVE_MESSAGE;
    }
  }

  runCallback(message: NativeMessageCore): void {
    const callbackId = message.requestId;
    if (!this.responseCallbacks.has(callbackId)) return;

    this.responseCallbacks.get(callbackId)!.func(message);
    this.responseCallbacks.delete(callbackId);
  }

  /**
   * Request
   */

  /**
  * @example
    ```json
    {
      "_id": 1,
      "type": "try_login",
      "data": ""
    }
    ```
  */
  requestLogin() {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.login,
      data: "",
    });
    this.postRequestMessage({ message: requestMessage });
  }

  async requestAccessTokenAsync(): Promise<NativeMessageAccessTokenResponse> {
    /** @todo timeout - reject */
    return new Promise((resolve, reject) => {
      this.requestAccessToken({
        callback: (message: NativeMessageCore) => {
          const parseResult = v.safeParse(
            NativeMessageAccessTokenResponseDataSchema,
            message.data,
          );

          parseResult.success
            ? resolve(parseResult.output)
            : reject(parseResult.issues);
        },
      });
    });
  }

  /**
   * api status code UNAUTHORIZED 또는 에러코드 EXPIRED_TOKEN 일 경우 호출
   * @example
    ```json
    {
      "_id": 1,
      "type": "get_access_token",
      "data": ""
    }
    ```
  */
  requestAccessToken({
    callback,
    callbackName = "requestAccessToken",
  }: RequestCallbacks) {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.accessToken,
      data: "",
    });

    this.postRequestMessage({
      message: requestMessage,
      callback: {
        name: callbackName,
        func: callback,
      },
    });
  }

  async requestRefreshAccessTokenAsync({
    accessToken,
    tokenType,
    expiresIn,
  }: RefreshAccessToken): Promise<NativeMessageAccessTokenResponse> {
    /** @todo timeout - reject */
    return new Promise((resolve, reject) => {
      this.requestRefreshAccessToken({
        accessToken,
        tokenType,
        expiresIn,

        callback: (message: NativeMessageCore) => {
          const parseResult = v.safeParse(
            RefreshAccessTokenResponseDataSchema,
            message.data,
          );

          parseResult.success
            ? resolve(parseResult.output)
            : reject(parseResult.issues);
        },
      });
    });
  }

  /**
  * @example
    ```json
    {
      "_id": 1,
      "type": "refresh_access_token",
      "data": {
        "access_token": "abcd",
        "token_type": "bearer",
        "expires_in": 1800 // seconds
      }
    }
    ```
  */
  requestRefreshAccessToken({
    accessToken,
    tokenType,
    expiresIn,
    callback,
    callbackName = "requestRefreshAccessToken",
  }: RefreshAccessToken & RequestCallbacks) {
    const requestData = v.parse(RefreshAccessTokenRequestSchema, {
      accessToken,
      tokenType,
      expiresIn,
    });

    const requestMessage = this.createRequestMessage({
      type: RequestTypes.refreshAccessToken,
      data: requestData,
    });

    this.postRequestMessage({
      message: requestMessage,
      callback: {
        name: callbackName,
        func: callback,
      },
    });
  }

  /**
  * @example
  ```json
  {
    "_id": 1,
    "type": "push_notification",
    "data": {
      "notification": {
        "title": "답변이 도착했어요.",
        "body": `[${persona.name}]의 답변이 도착했습니다.`,
        "data": {
          "deeplink": "deeplink/webview_landing?path=`/channels/${channelId}${window.location.search}`"
        }
      }
    }
  }
  ```
  */
  async requestNotification(request: RequestNotification) {
    if (!this.permissions.has(PermissionNames.pushNotification)) {
      const { granted } = await this.requestCheckPermissionAsync({
        permission: PermissionNames.pushNotification,
      });

      if (!granted) return;
    }

    const { isForeground } = await this.requestIsForegroundAsync();
    if (isForeground) return;

    // TODO: 타입 정의
    const notificationData = {
      notification: {
        title: request.title,
        body: request.content,
      },
      deeplink: `deeplink/webview_landing?path=${encodeURIComponent(request.uri)}`,
    };

    this.postRequestMessage({
      message: this.createRequestMessage({
        type: RequestTypes.pushNotification,
        data: notificationData,
      }),
    });
  }

  /**
   * @example
   ```json
   {
     "_id": 1,
     "type": "select_persona",
     "data": {
       "persona_id": "669191ccfa9cf2010f533369"
     }
   }
   ```
   */
  requestSetPersona(personaId: string) {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.setPersona,
      data: { persona_id: personaId },
    });
    this.postRequestMessage({ message: requestMessage });
  }

  async requestPermissionAsync({
    permission,
    message,
  }: PermissionRequest): Promise<PermissionResponseData> {
    const request = v.parse(PermissionRequestSchema, { permission, message });

    /** @todo timeout - reject */
    return new Promise((resolve, reject) => {
      this.requestPermission({
        ...request,

        callback: (message: NativeMessageCore) => {
          const parseResult = v.safeParse(
            PermissionResponseDataSchema,
            message.data,
          );

          if (!parseResult.success) {
            reject(parseResult.issues);
            return;
          }

          parseResult.output.granted && this.permissions.add(permission);
          resolve(parseResult.output);
        },
      });
    });
  }

  hasPermission(permission: PermissionNames): boolean {
    return this.permissions.has(permission);
  }

  /**
  * @example
    ```json
    {
      "_id": 1,
      "type": "request_permission",
      "data": {
        "permission": "push_notification",
        "message": "답장이 왔을 때 알려드릴까요?"
      }
    }
    ```
  */
  requestPermission({
    permission,
    message = "",
    callback,
    callbackName = "requestPermission",
  }: PermissionRequest & RequestCallbacks): void {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.permission,
      data: { permission, message },
    });

    this.postRequestMessage({
      message: requestMessage,
      callback: {
        name: callbackName,
        func: callback,
      },
    });
  }

  async requestCheckPermissionAsync(
    request: PermissionCheckRequest,
  ): Promise<PermissionResponseData> {
    const { permission } = v.parse(PermissionCheckRequestSchema, request);

    /** @todo timeout - reject */
    return new Promise((resolve, reject) => {
      this.requestCheckPermission({
        permission,
        callback: (message: NativeMessageCore) => {
          const parseResult = v.safeParse(
            PermissionResponseDataSchema,
            message.data,
          );

          parseResult.success ? resolve(parseResult.output) : reject();
        },
      });
    });
  }

  /**
  * @example
  ```json
  {
    "_id": 1,
    "type": "check_permission",
    "data": "push_notification" // permissionName
  }
  ```
  */
  requestCheckPermission({
    permission,
    callback,
    callbackName = "requestCheckPermission",
  }: PermissionCheckRequest & RequestCallbacks) {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.checkPermission,
      data: permission,
    });

    this.postRequestMessage({
      message: requestMessage,
      callback: {
        name: callbackName,
        func: callback,
      },
    });
  }

  async requestIsForegroundAsync(): Promise<IsForegroundResponseData> {
    /** @todo timeout - reject */
    return new Promise((resolve, reject) => {
      this.requestIsForeground({
        callback: (message: NativeMessageCore) => {
          const parseResult = v.safeParse(
            IsForegroundResponseDataSchema,
            message.data,
          );

          parseResult.success
            ? resolve(parseResult.output)
            : reject(parseResult.issues);
        },
      });
    });
  }

  /**
  * @example
  ```json
  {
    "_id": 1,
    "type": "is_foreground",
    "data": ""
  }
  ```
  */
  requestIsForeground({
    callback,
    callbackName = "requestIsForeground",
  }: RequestCallbacks): void {
    this.postRequestMessage({
      message: this.createRequestMessage({
        type: RequestTypes.isForeground,
        data: "",
      }),
      callback: {
        name: callbackName,
        func: callback,
      },
    });
    return;
  }

  requestOpenSettings() {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.openSettings,
      data: "",
    });
    this.postRequestMessage({ message: requestMessage });
  }

  protected postRequestMessage({ message, callback }: PostRequestMessageArgs) {
    if (callback) {
      this.responseCallbacks.set(message._id, {
        func: callback.func,
        /** @todo isCanceled 가 true로 변경되는 시점이 없는 듯? */
        isCanceled: false,
      });
    }

    if (!this.bridge) {
      this.requestQueue.push(message);
      this.setupBridgeInterface();
      return;
    }

    this.sendMessageToBridge(JSON.stringify(message));
  }

  protected runQueueMessages() {
    this.requestQueue.forEach((queuedMessage) => {
      this.sendMessageToBridge(JSON.stringify(queuedMessage));
    });
    this.requestQueue = [];
  }

  protected sendMessageToBridge(message: string) {}

  protected createRequestMessage({ type, data }: Omit<RequestMessage, "_id">) {
    return {
      _id: this.createId(),
      type,
      data,
    };
  }

  /** @description 0부터 차례로 시작하지 않으면 동작안함..? */
  protected createId() {
    return this.requestId++;
  }
}
