import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./title.module.scss";

type TitleCoreProps = PropsWithChildren<{
  className?: string;
}>;

export function Title1({ className, children }: TitleCoreProps) {
  return <h1 className={cn(styles.h1, className)}>{children}</h1>;
}

export function Title4({ className, children }: TitleCoreProps) {
  return <h4 className={cn(styles.h4, className)}>{children}</h4>;
}

export function Title7({ className, children }: TitleCoreProps) {
  return <h6 className={cn(styles.h6, className)}>{children}</h6>;
}
