import { memo } from "react";
import { SkeletonPulse } from "~features/ui/skeleton";

export const ResultSkeleton = memo(function ResultSkeleton() {
  return (
    <>
      <SkeletonPulse />
      <SkeletonPulse animationDelay="100ms" />
      <SkeletonPulse animationDelay="200ms" />
      <SkeletonPulse animationDelay="300ms" />
      <SkeletonPulse animationDelay="500ms" />
    </>
  );
});
