import { P } from "ts-pattern";
import { transformQuestions } from "~features/search/schemas/transform.utils";
import { v } from "~libs/valibot";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @example
 ```json
 {
  "type": "message",
  "from": "group",
  "fromUserId": null,
  "group": "66967876e303453a6e23441d",
  "dataType": "json",
  "data": {
    "message_id": "66967dd355081cdcc01c7dd6",
    "suggest_questions": [
      "Cursor IDE의 자동완성 기능인 Copilot++에 대해 더 설명해 주실 수 있나요?",
      "Cursor IDE와 GitHub Copilot의 코드 추천 방식의 차이점은 무엇인가요?",
      "Cursor IDE에 통합된 GPT-4 기능의 장점은 무엇인가요?"
    ]
  }
 }
 ```
 */
const RelatedQuestionsMessageSchema$Socket = v.pipe(
  v.object({
    message_id: MessagesSchemas$Api.Item.entries._id,
    suggest_questions: MessagesSchemas$Api.Item.entries.suggest_questions,
  }),

  v.transform((input) => ({
    messageId: input.message_id,
    questions: transformQuestions(input.suggest_questions),
  })),
);

export const parseRelatedQuestionsMessage$Socket = (input: unknown) =>
  v.parse(RelatedQuestionsMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const RelatedQuestionsMessagePattern$Socket = getMessagePattern({
  suggest_questions: P.array(P.string),
});
