import { useNativeBridge } from "~clients/native-bridge";
import { redirectToOAuthLogin } from "~features/auth";
import { useUserAgent } from "~features/user-agent";

import { AppIcon } from "./images";
import styles from "./modal.module.scss";

export function LoginModal() {
  const { isWindows } = useUserAgent();
  const { nativeBridge } = useNativeBridge();

  const handleWebLogin = () => {
    redirectToOAuthLogin();
  };

  const handleNativeLogin = () => {
    nativeBridge?.requestLogin();
  };

  const onClickLoginButton = () => {
    isWindows ? handleNativeLogin() : handleWebLogin();
  };

  return (
    <div className={`${styles.modal_wrap} ${styles.login}`}>
      <div className={styles.modal}>
        <div className={styles.modal_contents}>
          <img src={AppIcon} alt="" />
          <h3>
            앨런은 로그인이 필요한 서비스입니다.
            <br /> 로그인 후 이용해 주세요.
          </h3>

          {isWindows && (
            <p>앨런 PC 버전은 알매니저를 통해 로그인할 수 있습니다.</p>
          )}
        </div>
        <div className={styles.modal_btn_wrap}>
          <button type="button" onClick={onClickLoginButton}>
            <span>로그인</span>
          </button>
        </div>
      </div>
    </div>
  );
}
