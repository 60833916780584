import ky from "ky";
import { useNativeBridge } from "~clients/native-bridge";
import { useAccessTokenStore } from "~features/auth";
import { useUserAgent } from "~features/user-agent";
import {
  API_BASE_URL,
  API_VERSION_PREFIXES,
  ERROR_CODES,
  HEADER_KEY,
  STATUS_CODES,
} from "./constants";
import { isKyHttpError, redirectToErrorPage$web } from "./ky.utils";

/**
 * @todo Native Promise에 timeout 적용
 */
export const KyClient = ky.create({
  prefixUrl: API_BASE_URL.concat(API_VERSION_PREFIXES.v1),
  credentials: "include",
  retry: {
    limit: 1,
    statusCodes: [
      STATUS_CODES.UNAUTHORIZED,
      STATUS_CODES.FORBIDDEN,
      STATUS_CODES.REQUEST_TIMEOUT,
      STATUS_CODES.SERVER_ERROR,
    ],
  },
  hooks: {
    beforeRequest: [
      async (request, options) => {
        if (useUserAgent.getState().isWeb) {
          return;
        }

        options.credentials = "omit";

        const nativeAccessToken = useAccessTokenStore.getState().accessToken;
        if (nativeAccessToken) {
          request.headers.set(
            HEADER_KEY.AUTHORIZATION,
            `Bearer ${nativeAccessToken}`,
          );
          return;
        }

        const nativeBridge = useNativeBridge.getState().nativeBridge;
        if (!nativeBridge) {
          throw new Error("[KyClient.beforeRequest] No Native Handler");
        }

        /** @todo: refactor - 함수 분리 - cancel promise after timeout */
        const abortController = new AbortController();
        const signal = abortController.signal;

        const accessTokenData = await nativeBridge.requestAccessTokenAsync();
        request.headers.set(
          HEADER_KEY.AUTHORIZATION,
          `Bearer ${accessTokenData.accessToken}`,
        );

        // TODO: signal - timeout 적용
        //   signal.addEventListener("abort", () => {
        //     reject("[KyClient.beforeRequest] getAccessToken timeout");
        //   });

        // TODO: Native response timeout 확인
        setTimeout(() => {
          abortController.abort();
        }, 2_000);
      },
    ],

    beforeRetry: [
      /** @todo Refactor - 함수 분리 */
      async ({ error, request, retryCount }) => {
        if (!isKyHttpError(error)) return;

        request.headers.set(HEADER_KEY.RETRY_COUNT, `${retryCount}`);

        const { status: statusCode } = error.response;

        if (useUserAgent.getState().isWeb) {
          if (
            statusCode === STATUS_CODES.UNAUTHORIZED ||
            statusCode === STATUS_CODES.FORBIDDEN
          ) {
            throw new Error(
              `beforeRetry hook Ignored in Web\nstatusCode: ${statusCode}`,
            );
          }
          return;
        }

        const nativeBridge = useNativeBridge.getState().nativeBridge;
        if (!nativeBridge) {
          throw new Error("[KyClient.beforeRetry] No Native Bridge");
        }

        const { accessToken, tokenType, expiresIn, setAccessToken } =
          useAccessTokenStore.getState();

        const responseData = await error.response.json();

        if (
          statusCode === STATUS_CODES.UNAUTHORIZED ||
          responseData?.code === ERROR_CODES.EXPIRED_TOKEN
        ) {
          const newAccessToken =
            await nativeBridge.requestRefreshAccessTokenAsync({
              accessToken,
              tokenType,
              expiresIn,
            });

          setAccessToken(newAccessToken);
          request.headers.set(
            HEADER_KEY.AUTHORIZATION,
            `Bearer ${newAccessToken}`,
          );
        }

        if (
          statusCode === STATUS_CODES.FORBIDDEN ||
          responseData?.code === ERROR_CODES.INVALID_TOKEN
        ) {
          const newAccessToken = await nativeBridge.requestAccessTokenAsync();

          setAccessToken(newAccessToken);
          request.headers.set(
            HEADER_KEY.AUTHORIZATION,
            `Bearer ${newAccessToken}`,
          );
        }
      },
    ],

    afterResponse: [
      /** @todo 기존 상태 그대로 유지하고, react-router로 이동할 수 있는 방법? */
      (request, _options, response) => {
        if (
          useUserAgent.getState().isWeb &&
          response.status >= 500 &&
          !request.url.includes("thumbs") &&
          !request.url.includes("suggest")
        ) {
          redirectToErrorPage$web();
        }
      },
    ],
  },
});
