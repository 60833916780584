import { Status } from "~features/util-types/status";
import type { SearchResult } from "./schemas";

export const findTargetResultByRequestId = (
  results: SearchResult.Page["items"],
  requestId: string,
) => results.slice(-3).find((result) => result.requestId === requestId);

export const findTargetResultStatusInit = (
  results: SearchResult.Page["items"],
  requestId: string,
) =>
  results
    .slice(-3)
    .find(
      (result) =>
        result.requestId === requestId ||
        result.status === Status.INIT ||
        result.requestId === "",
    );

export const findTargetResultByMessageId = (
  results: SearchResult.Page["items"],
  messageId: string,
) => results.slice(-3).find((result) => result.id === messageId);
