/** @todo storageKy enum */
export const getLocalStorageValue = <T = unknown>(storageKey: string) => {
  try {
    const item = window.localStorage.getItem(storageKey);
    if (!item) return undefined;

    return JSON.parse(item) as T;
  } catch {
    return undefined;
  }
};

export const setLocalStorageValue = <T = unknown>(
  storageKey: string,
  value: T,
) => {
  try {
    window.localStorage.setItem(storageKey, JSON.stringify(value as T));
  } catch {
    // ignore
  }
};
