import { Button } from "~features/ui/button";
import { cn } from "~utils/class-names";
import styles from "./more-button.module.scss";

type MoreButtonProps = {
  isGray?: boolean;
  onClick?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
};

const MoreButton: React.FC<MoreButtonProps> = (props) => {
  return (
    <Button
      overrideClass={cn(styles.btn_more, { [styles.gray]: props.isGray })}
      onClick={props.onClick}
      onKeyDown={props.onKeyDown}
      size="medium"
      type="onlyIcon"
      aria-label="more menu button"
      iconBefore={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="14"
          height="3"
          viewBox="0 0 14 3"
          fill="none"
        >
          <circle cx="6.94995" cy="1.75" r="1.25" fill="#1A212C" />
          <circle cx="1.94995" cy="1.75" r="1.25" fill="#1A212C" />
          <circle cx="11.95" cy="1.75" r="1.25" fill="#1A212C" />
        </svg>
      }
      {...props}
    />
  );
};

export default MoreButton;
