import { createContext, useContext } from "react";

type RawTextContextStates = {
  rawText: string;
  setRawText: (rawText: string) => void;

  rawKeyboardText?: string;
  setRawKeyboardText?: (rawKeyboardText: string) => void;

  sttTextEnabled?: boolean;
  rawSttText?: string;
  setRawSttText?: (rawSttText: string) => void;
};

export const RawTextContext = createContext<RawTextContextStates | undefined>(
  undefined,
);

export const useRawTextContext = () => {
  const context = useContext(RawTextContext);
  if (!context) {
    throw new Error("useRawTextContext must be used within a RawTextProvider");
  }
  return context;
};
