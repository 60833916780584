import { create } from "zustand";

export enum PLACE_HOLDER_TEXTS {
  START = "무슨 생각을 하고 계신가요?",
  PENDING = "응답을 기다리는 중이에요.",
  STOPPED = "종료된 서비스입니다.",
  YOUTUBE_INIT = "요약할 유튜브 링크를 입력해주세요.",
}

type TextareaStates = {
  userText: string;
  enabled: boolean;
  placeholderText?: PLACE_HOLDER_TEXTS;
  autoFocus: boolean;
};

type TextareaActions = {
  setUserText: (value: string) => void;
  setTextareaEnabled: (enabled: boolean) => void;
  setPlaceholderText: (placeholderText: PLACE_HOLDER_TEXTS) => void;
  setAutoFocus: (autoFocus: boolean) => void;
};

const INIT_STATES: TextareaStates = {
  userText: "",
  enabled: true,
  placeholderText: PLACE_HOLDER_TEXTS.START,
  autoFocus: false,
};

export const useTextarea = create<TextareaStates & TextareaActions>((set) => ({
  ...INIT_STATES,
  setUserText: (userText) => set({ userText }),
  setTextareaEnabled: (enabled) => set({ enabled }),
  setPlaceholderText: (placeholderText) => set({ placeholderText }),
  setAutoFocus: (autoFocus) => set({ autoFocus }),
}));
