import { RouterProvider, createRouter } from "@tanstack/react-router";
import { tanStackQueryClient } from "~features/providers/tanstack-query";
import { routeTree } from "./generated-route.tree";

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof tanStackRouter;
  }
}

export const tanStackRouter = createRouter({
  routeTree,
  context: {
    queryClient: tanStackQueryClient,
  },
  defaultPreload: "intent",
  defaultPreloadStaleTime: 0,
});

export function TanStackRouterProvider() {
  return <RouterProvider router={tanStackRouter} />;
}
