import Lottie from "lottie-react";
import sttLottie from "./stt-lottie.json";
import styles from "./stt.module.scss";

type SttLottieProps = {
  onClickSttLottie: () => void;
};

export default function SttLottie({ onClickSttLottie }: SttLottieProps) {
  return (
    <Lottie
      className={styles.stt}
      animationData={sttLottie}
      onClick={onClickSttLottie}
    />
  );
}
