import { Platforms } from "./platform.constants";

/**
 * @example
 * - Gepeto/1.8.1 (Windows)
 * - Gepeto/1.8.1 (iOS)
 * - Gepeto/1.8.1 (Android)
 */
const ALAN_REGEX =
  /\bGepeto\/([0-9]+\.[0-9]+\.[0-9]+)\s+\((Windows|iOS|Android)\)(?:\b|$)/;

export const parseUserAgent = () => {
  const userAgent =
    window.navigator.userAgent || navigator.vendor || window.opera;

  const matchResult = userAgent.match(ALAN_REGEX);
  if (!matchResult)
    return {
      version: "",
      platform: Platforms.web,
      isMobile: false,
      isIos: false,
      isAndroid: false,
      isWindows: false,
      isWeb: true,
    };

  const version = matchResult[1].toLowerCase() ?? "";
  const platform = (matchResult[2].toLowerCase() as Platforms) ?? Platforms.web;

  const isIos = platform === Platforms.ios;
  const isAndroid = platform === Platforms.android;
  const isMobile = isIos || isAndroid;
  const isWindows = platform === Platforms.windows;
  const isWeb = platform === Platforms.web;

  return {
    version,
    platform,
    isMobile,
    isIos,
    isAndroid,
    isWindows,
    isWeb,
  };
};

export const getAndroidVersion = (): number | null => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  const match = userAgent.match(/Android\s([0-9\.]*)/);
  try {
    return match ? Number(match[1].split(".")[0]) : null;
  } catch (_e) {
    console.error("android version parsing error");
    return null;
  }
};

export const compareVersions = (version1: string, version2: string): number => {
  const v1Components = version1.split(".").map(Number);
  const v2Components = version2.split(".").map(Number);

  const maxLength = Math.max(v1Components.length, v2Components.length);

  for (let i = 0; i < maxLength; i++) {
    const v1Component = i < v1Components.length ? v1Components[i] : 0;
    const v2Component = i < v2Components.length ? v2Components[i] : 0;

    if (v1Component < v2Component) {
      return -1;
    }

    if (v1Component > v2Component) {
      return 1;
    }
  }

  return 0;
};
