import {
  type UseQueryOptions,
  queryOptions,
  useQuery,
} from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { useUser } from "~features/auth";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { type Channel, transformResponse } from "./channel.schema";

export const useQueryChannel = (channelId: string) => {
  const { isLoggedIn } = useUser();

  return useQuery(
    Object.assign({}, getChannelQueryOptions(channelId), {
      enabled: isLoggedIn && channelId.length > 0,
    } as UseQueryOptions<Channel>),
  );
};

export const getChannelQueryOptions = (channelId: string) =>
  queryOptions({
    queryKey: QUERY_KEYS.CHANNELS.getChannelQueryKey(channelId),
    queryFn: () => fetchChannel(channelId).then(transformResponse),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    staleTime: TIMES.SEC * 5,
    retry: false,
  });

/**
 * example
 * -  @see {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v1/channels/66d1332bb6af94b7ef74e61e}
 *
 * @todo
 * - 에러 핸들링 추가
 */
const fetchChannel = async (channelId: string) =>
  await KyClient.get(`channels/${channelId}`).json();
