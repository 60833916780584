import { createContext, useContext } from "react";

export type CompletionContextStates = {
  isMobileSize: boolean;
  setKeywordAsTextValue: (keyword: string) => void;
  textareaRef?: React.RefObject<HTMLTextAreaElement>;
};

export const CompletionContext = createContext<
  CompletionContextStates | undefined
>(undefined);

export const useCompletionContext = () => {
  const context = useContext(CompletionContext);
  if (!context) {
    throw new Error(
      "useCompletionContext must be used within a CompletionProvider",
    );
  }

  return context;
};
