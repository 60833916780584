import { useNavigate } from "@tanstack/react-router";
import { useCallback } from "react";
import { useNativeBridge } from "~clients/native-bridge";
import { ERROR_MESSAGE_USAGE } from "~constants/errors";
import { redirectToOAuthLogin, useUser } from "~features/auth";
import { useMutationCreateChannelId } from "~features/channel";
import { useSendSearchTitle } from "~features/search";
import { setDefaultErrorToast } from "~features/toast";
import { useSidebar } from "~features/ui/side-bar";
import { useUserAgent } from "~features/user-agent";

/** @todo 공통로직 분리 */
export const useSendSuggestion = () => {
  const { user, isLoggedIn } = useUser();
  const createChannelMutation = useMutationCreateChannelId();
  const navigate = useNavigate();
  const { sendSearchTitleMutation, requestNativeNotificationPermission } =
    useSendSearchTitle();

  const { setIsOpen } = useSidebar();

  const { nativeBridge } = useNativeBridge();
  const { isWeb } = useUserAgent();

  const sendSuggestion = useCallback(
    async (suggestion: string) => {
      if (!isLoggedIn) {
        isWeb
          ? redirectToOAuthLogin(
              encodeURI(
                window.location.origin.concat(`/search?q=${suggestion}`),
              ),
            )
          : nativeBridge?.requestLogin();
        return;
      }

      if (user.usageCount >= user.usageLimit) {
        setDefaultErrorToast(ERROR_MESSAGE_USAGE.message);
        return;
      }

      createChannelMutation.mutateAsync().then(({ channelId }) => {
        navigate({
          to: "/search/$channelId",
          params: { channelId },
        }).then(() => {
          setIsOpen(false);
          sendSearchTitleMutation.mutateAsync({
            channelId,
            message: suggestion,
          });

          requestNativeNotificationPermission();
        });
      });
    },
    [
      user,
      isLoggedIn,
      createChannelMutation,
      sendSearchTitleMutation,
      navigate,
      requestNativeNotificationPermission,
      nativeBridge,
      isWeb,
      setIsOpen,
    ],
  );

  return {
    sendSuggestion,
  };
};
