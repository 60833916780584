import { memo } from "react";
import { LoadingIcon } from "~components/chat/loading-icon";
import { cn } from "~utils/class-names";
import { MessageContainer } from "./message-container";
import { MessageRowContentOnly } from "./message-row-content";

import loadingIconStyles from "~components/chat/loading-icon/loading-icon.module.scss";
import styles from "./message-loading-icon.module.scss";
import rowStyles from "./message-row.module.scss";

function _MessageLoadingIcon() {
  return (
    <MessageContainer className={cn(styles.loading_icon)}>
      <MessageRowContentOnly>
        <LoadingIcon
          className={cn(rowStyles.content, loadingIconStyles.ml_0)}
        />
      </MessageRowContentOnly>
    </MessageContainer>
  );
}

export const MessageLoadingIcon = memo(_MessageLoadingIcon);
