import { createEventHandler } from "~utils/create-event-handler";

import styles from "./message-assistant-resume-button.module.scss";
import { useMutationAnswerResume } from "./use-mutation-answer-resume";

type MessageAssistantResumeButtonProps = {
  messageId: string;
  onClick?: () => void;
};

const BUTTON_TITLE = "이어서 보기";

export function MessageAssistantResumeButton(
  props: MessageAssistantResumeButtonProps,
) {
  const requestResumeMutation = useMutationAnswerResume();

  const onClick = createEventHandler({
    handler: () => {
      requestResumeMutation.mutateAsync(props.messageId);
      props.onClick?.();
    },
  });

  return (
    <button type="button" onClick={onClick} className={styles.resume_button}>
      <span className={styles.text}>{BUTTON_TITLE}</span>
      <i className={styles.icon} />
    </button>
  );
}
