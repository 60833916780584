import {
  type InferOutput,
  parse,
  pipe,
  transform,
  enum as vEnum,
  object as vObject,
  string as vString,
} from "valibot";
import { VotedTypes } from "./message-feedback.schema";

export type SendVote = InferOutput<typeof SendVoteSchema>;

const SendVoteSchema = vObject({
  messageId: vString(),
  type: vEnum(VotedTypes),
});

const SendVoteRequestSchema = pipe(
  SendVoteSchema,
  transform(({ type, messageId }) => ({
    message_id: messageId,
    type,
  })),
);

export const transformRequest = (request: SendVote) =>
  parse(SendVoteRequestSchema, request);
