const SEC = 1_000;
const MIN = 60 * SEC;
const HOUR = 60 * MIN;
const DAY = 24 * HOUR;

export const TIMES = {
  SEC,
  MIN,
  HOUR,
  DAY,
} as const;
