import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./lists.module.scss";

type MarkdownULProps = PropsWithChildren;

export function MarkdownUL(props: MarkdownULProps) {
  return <ul className={styles.ul}>{props.children}</ul>;
}

export function MarkdownOL(props: MarkdownULProps) {
  return <ol className={styles.ol}>{props.children}</ol>;
}

type MarkdownListItemProps = PropsWithChildren;

export function MarkdownListItem(props: MarkdownListItemProps) {
  return (
    <li className={cn(styles.li, styles.padding_x0, styles.padding_y2)}>
      <span className={cn(styles.li_text)}>{props.children}</span>
    </li>
  );
}
