import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./footer.module.scss";

type FooterProps = PropsWithChildren<{
  className?: string;
}>;

export function Footer({ className, children }: FooterProps) {
  return (
    <footer className={cn(styles.container, className)}>{children}</footer>
  );
}
