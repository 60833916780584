import type {
  ComponentPropsWithoutRef,
  MouseEvent,
  PropsWithChildren,
} from "react";
import { cn } from "~utils/class-names";

import styles from "./tooltip.module.scss";

type WrapperProps = PropsWithChildren<{
  className?: string;
}>;

function Wrapper({ children, className = "" }: WrapperProps) {
  return (
    <div className={cn(styles.tooltip_wrapper, className)}>{children}</div>
  );
}

type ContentsProps = ComponentPropsWithoutRef<"div">;

function Contents(props: ContentsProps) {
  return (
    <div className={cn(styles.tooltip_contents, props.className)}>
      {props.children}
    </div>
  );
}

type CloseButtonProps = ComponentPropsWithoutRef<"button"> & {
  onClick?: () => void;
};

function CloseButton(props: CloseButtonProps) {
  const _onClickCloseButton = (e: MouseEvent) => {
    e.preventDefault();
    props.onClick?.();
  };

  return (
    <button
      type="button"
      onClick={_onClickCloseButton}
      aria-label="닫기"
      draggable="false"
      className={cn(styles.tooltip_close_button)}
    />
  );
}

type TitleProps = ComponentPropsWithoutRef<"strong">;

function Title(props: TitleProps) {
  return (
    <strong className={cn(styles.tooltip_title, props.className)}>
      {props.children}
    </strong>
  );
}

type NewTagProps = ComponentPropsWithoutRef<"i">;

function NewTag(props: NewTagProps) {
  return (
    <i className={cn(styles.tooltip_new_tag, props.className)}>
      {props.children}
    </i>
  );
}

export const Tooltip = {
  Wrapper,
  Contents,
  CloseButton,
  Title,
  NewTag,
} as const;
