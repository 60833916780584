import { Cross1Icon } from "@radix-ui/react-icons";
import type { MouseEvent } from "react";

type CloseButtonProps = {
  closeDrawer: (e: MouseEvent) => void;
};

export function CloseButton({ closeDrawer }: CloseButtonProps) {
  return (
    <button
      type="button"
      onClick={closeDrawer}
      className="absolute cursor-pointer top-4 right-3"
    >
      <Cross1Icon />
    </button>
  );
}
