import { VotingButtons } from "~components/chat/message-buttons";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";

import feedbackStyles from "./message-assistant-feedback.module.scss";

type MessageAssistantUpVoteButtonProps = {
  messageId: string;
  voted: boolean;
  onClick: () => void;
};

const UP_VOTE_TITLE = "마음에 들어요";

export function MessageAssistantUpVoteButton(
  props: MessageAssistantUpVoteButtonProps,
) {
  const onClickUpVote = createEventHandler({
    handler: () => {
      if (props.voted) return;

      props.onClick();
    },
  });

  return (
    <button
      type="button"
      className={cn(feedbackStyles.feedback_button, {
        [feedbackStyles.voted]: props.voted,
      })}
      aria-label={UP_VOTE_TITLE}
      onClick={onClickUpVote}
    >
      <VotingButtons.UpSvg />
    </button>
  );
}
