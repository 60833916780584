import { Carousel } from "~features/ui/carousel";
import type { SearchResult } from "../schemas";

type SearchResultImagesProps = SearchResult.ImageSources & {
  title?: string;
};

export function SearchResultImages({
  title = "이미지",
  total,
  items,
}: SearchResultImagesProps) {
  return (
    <Carousel.List title={title} total={total}>
      {items.map((image) => (
        <Carousel.Card
          key={`result-image-${image.id}`}
          cardId={image.id}
          cardThumbnailSrc={image.url}
          cardTitle={image.title}
          cardSource={image.source}
          cardUpdatedAt={image.updatedAt}
          cardUrl={image.sourceUrl ?? ""}
        />
      ))}
    </Carousel.List>
  );
}
