import { useNavigate } from "@tanstack/react-router";
import { useMutationCreateChannelId } from "~features/channel";
import { usePersonas } from "~features/personas";
import { Carousel } from "~features/ui/carousel";
import { useMutationRequestSummary } from "~features/youtube-summary/summary-result/use-mutation-request-summary";
import { createEventHandler } from "~utils/create-event-handler";
import type { SearchResult } from "../schemas";

type SearchResultVideosProps = SearchResult.VideoSources & {
  title?: string;
};

export function SearchResultVideos({
  title = "동영상",
  total,
  items,
}: SearchResultVideosProps) {
  const navigate = useNavigate();
  const { youtubePersonaId } = usePersonas();
  const createChannelMutation = useMutationCreateChannelId(youtubePersonaId);
  const requestSummaryMutation = useMutationRequestSummary();

  const onClickSummaryButton = (videoId: string) =>
    createEventHandler({
      handler: () => {
        createChannelMutation.mutateAsync().then(({ channelId }) => {
          navigate({
            to: "/summary/youtube/$channelId",
            params: { channelId },
          }).then(() => {
            requestSummaryMutation.mutateAsync({
              channelId,
              url: `https://www.youtube.com/watch?v=${videoId}`,
            });
          });
        });
      },
    });

  return (
    <Carousel.List title={title} total={total}>
      {items.map((video) => (
        <Carousel.Card
          key={`result-video-${video.id}-${video.videoId}`}
          cardType="video"
          cardId={video.id}
          cardThumbnailSrc={getYoutubeThumbnailSrc({
            videoId: video.videoId,
            thumbnailSrc: video.thumbnailSrc,
          })}
          cardTitle={video.title}
          cardSource={video.source}
          cardUpdatedAt={video.updatedAt}
          cardUrl={video.url}
          onClickButton={onClickSummaryButton(video.videoId ?? "")}
        />
      ))}
    </Carousel.List>
  );
}

const getYoutubeThumbnailSrc = ({
  videoId,
  thumbnailSrc,
}: {
  thumbnailSrc?: string;
  videoId?: string;
}) => {
  if (thumbnailSrc) {
    return thumbnailSrc;
  }

  if (!videoId) return "";

  /** @see {@link https://stackoverflow.com/questions/2068344/how-do-i-get-a-youtube-video-thumbnail-from-the-youtube-api} */
  return `https://img.youtube.com/vi/${videoId}/sddefault.jpg`;
};
