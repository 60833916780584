import {
  type VirtualItem,
  type Virtualizer,
  useVirtualizer,
} from "@tanstack/react-virtual";
import {
  type PropsWithChildren,
  useDeferredValue,
  useEffect,
  useRef,
} from "react";
import { cn } from "~utils/class-names";

type VirtualizedListProps = PropsWithChildren<{
  total: number;
  itemsRenderer: (
    virtualizer: Virtualizer<HTMLDivElement, Element>,
  ) => (virtualItem: VirtualItem) => JSX.Element | undefined;
  channelChanged: boolean;
  className?: string;
}>;

export function VirtualizedList({
  total,
  itemsRenderer,
  channelChanged,
  className = "",
}: VirtualizedListProps) {
  const searchResultsRef = useRef<HTMLDivElement>(null);
  /** @todo overscan 넘어가는 경계선에서 correction 발생으로 이상하게 보이는 이슈 */
  const virtualizer = useVirtualizer({
    count: total,
    getScrollElement: () => searchResultsRef.current,
    estimateSize: (_index) => window.innerHeight,
    enabled: total > 0,
    overscan: 50,
    horizontal: false,
    measureElement(element, _entry, _instance) {
      return element.getBoundingClientRect().height + 1;
    },
  });

  const virtualItems = virtualizer.getVirtualItems();

  const deferredTotal = useDeferredValue(total);
  const totalChanged = deferredTotal !== total;

  useEffect(() => {
    if (!channelChanged && totalChanged) {
      window.setTimeout(() => {
        virtualizer.scrollToIndex(total, {
          align: "start",
          behavior: "smooth",
        });
      }, 50);
    }
  }, [channelChanged, totalChanged, total, virtualizer.scrollToIndex]);

  return (
    <>
      <div
        ref={searchResultsRef}
        className={cn(
          "flex relative flex-col justify-start items-center contain-strict",
          className,
        )}
      >
        <div
          className="relative w-full"
          style={{ height: virtualizer.getTotalSize() }}
        >
          <div
            className="absolute top-0 left-0 w-full"
            style={{
              transform: `translateY(${virtualItems[0]?.start ?? 0}px)`,
            }}
          >
            {virtualItems.map((virtualItem) =>
              itemsRenderer(virtualizer)(virtualItem),
            )}
          </div>
        </div>
      </div>
    </>
  );
}
