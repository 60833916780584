import { useCallback, useMemo } from "react";
import { create } from "zustand";
import type { Persona } from "./personas.schemas";
import { useQueryPersonas } from "./use-query-personas";

type PersonaStates = {
  persona: Persona;
};

type PersonaActions = {
  setPersona: (persona: Persona) => void;
};

const INIT_STATES: PersonaStates = {
  persona: {
    id: "",
    type: "",
    theme: "",
    name: "",
  },
};

const usePersonaStore = create<PersonaStates & PersonaActions>((set) => ({
  ...INIT_STATES,
  setPersona: (persona) => set({ persona }),
}));

export const usePersonas = () => {
  const { data: personas = [], isSuccess, isPending } = useQueryPersonas();
  const getPersonaById = useCallback(
    (personaId: string) => personas.find(({ id }) => id === personaId),
    [personas],
  );
  const { persona, setPersona } = usePersonaStore();

  const youtubePersona = useMemo(
    () => personas.find(({ theme }) => theme === "youtube"),
    [personas],
  );

  const youtubePersonaId = useMemo(
    () => youtubePersona?.id ?? "",
    [youtubePersona],
  );

  return {
    personas,
    getPersonaById,
    isSuccess,
    isPending,
    persona,
    setPersona,
    youtubePersona,
    youtubePersonaId,
    isYoutubePersona: persona.id === youtubePersonaId,
  };
};
