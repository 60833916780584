import { TIMES } from "~constants/times";
import { getYMD } from "~features/date-time";
import type { HistoryItem, HistoryItems } from "./history.schemas";

export enum GROUP_TITLES {
  TODAY = "오늘",
  YESTERDAY = "어제",
  DAYS_30 = "최근 30일",
  PREVIOUS = "이전",
}

type HistoryGroup = {
  title: GROUP_TITLES;
  items: HistoryItems;
};

const DAYS_30 = TIMES.DAY * 30;
const DAYS_2 = TIMES.DAY * 2;

export const groupHistoryItems = (
  historyItems: HistoryItems,
  maxItems = 30,
): HistoryGroup[] => {
  const now = new Date();
  const { year: todayYear, month: todayMonth, day: today } = getYMD(now);

  return Object.entries(
    historyItems
      .sort(sortByUpdatedDateTime)
      .slice(0, maxItems)
      .reduce(
        (groups, item) => {
          // UTC 시간을 로컬 시간으로 변환
          const localDateTime = new Date(item.updatedDateTime.getTime() - (item.updatedDateTime.getTimezoneOffset() * 60000));
          const { year, month, day } = getYMD(localDateTime);

          if (year === todayYear && month === todayMonth && day === today) {
            groups[GROUP_TITLES.TODAY].push(item);
            return groups;
          }

          const timeDiff = now.getTime() - item.updatedDateTime.getTime();

          if (
            timeDiff < DAYS_2 &&
            ((month === todayMonth && day === today - 1) ||
              (month === todayMonth - 1 && today === 1))
          ) {
            groups[GROUP_TITLES.YESTERDAY].push(item);
            return groups;
          }

          if (timeDiff <= DAYS_30) {
            groups[GROUP_TITLES.DAYS_30].push(item);
            return groups;
          }

          groups[GROUP_TITLES.PREVIOUS].push(item);
          return groups;
        },
        {
          [GROUP_TITLES.TODAY]: [] as HistoryItems,
          [GROUP_TITLES.YESTERDAY]: [] as HistoryItems,
          [GROUP_TITLES.DAYS_30]: [] as HistoryItems,
          [GROUP_TITLES.PREVIOUS]: [] as HistoryItems,
        },
      ),
  ).map<HistoryGroup>(([title, items]) => ({
    title: title as GROUP_TITLES,
    items,
  }));
  // .filter(({ items }) => items.length > 0);
};

const sortByUpdatedDateTime = (a: HistoryItem, b: HistoryItem) =>
  b.updatedDateTime.getTime() - a.updatedDateTime.getTime();
