import { v } from "~libs/valibot";
import { MessagesSchemas$Api } from "./schemas.api";

const MessageSchema$Socket = v.object({
  data: v.object({
    ...MessagesSchemas$Api.Item.entries,
    order: v.nullable(v.number()),
  }),
});

export const MessagesSchemas$Socket = {
  Item: MessageSchema$Socket,
};
