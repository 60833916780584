import { memo } from "react";
import { cn } from "~utils/class-names";
import styles from "./loading-icon.module.scss";

type LoadingIconProps = {
  className?: string;
};

function _LoadingIcon({ className = "" }: LoadingIconProps) {
  return <i className={cn(styles.icon, className)} />;
}

export const LoadingIcon = memo(_LoadingIcon);
