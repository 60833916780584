import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./result.module.scss";

type ResultInnerBoxProps = PropsWithChildren<{
  className?: string;
}>;

export function ResultInnerBox({ children, className }: ResultInnerBoxProps) {
  return <div className={cn(styles.inner_wrapper, className)}>{children}</div>;
}
