import hljs from "highlight.js";

type HljsCode = {
  lang: string;
  value: string;
  originCode: string;
};

const INIT_HLJS_CODE: HljsCode = {
  lang: "",
  value: "",
  originCode: "",
} as const;

const LANGUAGE_CSS_PREFIX = "lang-";

export const getHljsCode = ({
  className = "",
  children = "",
}: { className: string; children: string }): HljsCode => {
  if (!children || typeof children !== "string") {
    return INIT_HLJS_CODE;
  }

  const [language] = className
    .split(/\s+/)
    .filter((name) => name.includes(LANGUAGE_CSS_PREFIX))
    .map((name) => name.replace(LANGUAGE_CSS_PREFIX, ""));

  if (!language) {
    return Object.assign({}, INIT_HLJS_CODE, {
      value: children,
      originCode: children,
    });
  }

  const isValidateLanguage = language && hljs.getLanguage(language);
  const highlightedCode = isValidateLanguage
    ? hljs.highlight(children, { language })
    : hljs.highlightAuto(children);

  return {
    lang: highlightedCode.language ?? "",
    value: highlightedCode.value ?? "",
    originCode: children,
  };
};
