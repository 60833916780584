import { Suspense, lazy } from "react";
import { IS_DEV } from "~constants/env";
import { TanStackQueryProvider } from "./tanstack-query";
import { TanStackRouterProvider } from "./tanstack-router";

const TanStackQueryDevtools = IS_DEV
  ? lazy(() =>
      import("@tanstack/react-query-devtools").then((module) => ({
        default: module.ReactQueryDevtools,
      })),
    )
  : () => null;

const TanStackRouterDevtools = IS_DEV
  ? lazy(() =>
      import("@tanstack/router-devtools").then((module) => ({
        default: module.TanStackRouterDevtools,
      })),
    )
  : () => null;

export function Providers() {
  return (
    <>
      <TanStackQueryProvider>
        <TanStackRouterProvider />
      </TanStackQueryProvider>

      <Suspense>
        {/* <TanStackQueryDevtools
          initialIsOpen={false}
          position="bottom"
          buttonPosition="top-right"
          client={tanStackQueryClient}
        /> */}
        {/* <TanStackRouterDevtools
          initialIsOpen={false}
          position="bottom-right"
          router={tanStackRouter}
        /> */}
      </Suspense>
    </>
  );
}
