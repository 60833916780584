import { createEventHandler } from "~utils/create-event-handler";
import { useModalVisibleLocal } from "./use-modal-visible-local";

import { MaintenanceIcon } from "./images";
import styles from "./modal.module.scss";

type MaintenanceModalProps = {
  onClickConfirm: () => void;
  title: string;
  details: string;
  schedule: string;
};

export function MaintenanceModal({
  onClickConfirm,
  title,
  details,
  schedule,
}: MaintenanceModalProps) {
  const { updateModalVisible } = useModalVisibleLocal();

  return (
    <div className={`${styles.modal_wrap} ${styles.maintenance}`}>
      <div className={styles.modal}>
        <div className={styles.modal_contents}>
          <img src={MaintenanceIcon} alt="" />
          <h3>{title}</h3>
          <p>{details}</p>
          {schedule && (
            <div className={styles.schedule_wrap}>
              <p>{schedule}</p>
            </div>
          )}
        </div>
        <div className={styles.modal_btn_wrap}>
          <button
            type="button"
            className={styles.black}
            onClick={createEventHandler({ handler: onClickConfirm })}
          >
            <span>확인</span>
          </button>
        </div>
        <button
          type="button"
          className={styles.btn_today_close}
          onClick={createEventHandler({
            handler: () => updateModalVisible(false, true),
          })}
        >
          <span>다시 보지 않기</span>
        </button>
      </div>
    </div>
  );
}
