import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Providers } from "~features/providers";
import "./gtag";

const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <Providers />
    </StrictMode>,
  );
}
