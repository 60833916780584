import { NativeBridgeCore } from "./_core";
import { RequestTypes } from "./request.types";

declare global {
  interface Window {
    native?: {
      showSettingDlg: () => void;
    };
  }
}

const QWEB_CHANNEL_SCRIPT_ID = "qwebchannel";

export class NativeBridgeWindows extends NativeBridgeCore {
  constructor() {
    super();
    if (!this.bridge) {
      this.setupBridgeInterface();
    }
  }

  protected setupBridgeInterface(): void {
    document.getElementById(QWEB_CHANNEL_SCRIPT_ID)?.remove();

    const scriptEl = document.createElement("script");
    scriptEl.id = QWEB_CHANNEL_SCRIPT_ID;
    scriptEl.src = "qrc:///qtwebchannel/qwebchannel.js";
    scriptEl.async = false;
    scriptEl.onerror = () => {
      console.error("qwebchannel.js load error");
    };
    scriptEl.onload = () => {
      // @ts-ignore
      new QWebChannel(qt.webChannelTransport, (channel) => {
        this.bridge = channel?.objects?.native?.handleJsMessage;

        if (this.bridge && this.requestQueue.length > 0) {
          this.runQueueMessages();
        }
      });
    };

    document.head.appendChild(scriptEl);
  }

  protected sendMessageToBridge(message: string) {
    this.bridge?.(message);
  }

  requestOpenProgramInfo() {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.openProgramInfo$Windows,
      data: "",
    });
    this.postRequestMessage({ message: requestMessage });
  }
}
