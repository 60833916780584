import type { MouseEvent } from "react";
import { REQUIRE_ALMANAGER, REQUIRE_LOGIN } from "~constants/errors";
import { AlertModal } from "./alert";
import { LoginModal } from "./login";
import { useErrorInfo } from "./use-error-info";

export function ErrorPopup() {
  const { error, resetErrorDetail } = useErrorInfo();

  if (error.code === REQUIRE_LOGIN.code) {
    return <LoginModal />;
  }

  if (error.code === REQUIRE_ALMANAGER.code) {
    return (
      <AlertModal
        message="앨런 로그인을 위해서는<br /> 알매니저 설치 및 로그인이 필요합니다."
        title={error.landingInfo?.title}
        url={error.landingInfo?.url}
      />
    );
  }

  return (
    <AlertModal
      message="알 수 없는 오류가 발생했습니다."
      title="확인"
      url="#n"
      action={(e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        resetErrorDetail();
      }}
    />
  );
}
