import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";
import { useUser } from "~features/auth";
import { setDefaultErrorToast } from "~features/toast";
import { useChannel } from "./use-channel";
import { useQueryChannel } from "./use-query-channel";

export const useAuthGuard = () => {
  const { isLoggedIn } = useUser();
  const { channelId } = useChannel();
  const { isError } = useQueryChannel(channelId);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) return;

    // TODO: kyError parse -> 본인채널 아닌 경우 코드
    if (isError) {
      navigate({ to: "/", replace: true, resetScroll: true }).then(() => {
        setDefaultErrorToast("권한이 없는 페이지입니다.");
      });
    }
  }, [isLoggedIn, isError, navigate]);
};
