import { useNativeBridge } from "~clients/native-bridge";
import { useUserAgent } from "~features/user-agent";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { AppDownload } from "./app-download";
import styles from "./bottom.module.scss";
import { SettingButton } from "./setting-button";
import { User } from "./user";

type SidebarBottomProps = {
  className?: string;
};

export function SidebarBottom({ className = "" }: SidebarBottomProps) {
  const { isWeb } = useUserAgent();

  return (
    <section className={cn(styles.sidebar_bottom, className)}>
      <User />

      {isWeb ? <AppDownload /> : <NativeSettingButton />}
    </section>
  );
}

function NativeSettingButton() {
  const { nativeBridge } = useNativeBridge();

  const onClick = createEventHandler({
    handler: () => {
      nativeBridge?.requestOpenSettings();
    },
  });

  return <SettingButton onClick={onClick} />;
}
