import { useCallback } from "react";
import { create } from "zustand";
import { useWindowWidth } from "~hooks/use-window-width";

type MobileLayerStates = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const useMobileLayerStore = create<MobileLayerStates>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set({ isOpen }),
}));

export const useMobileLayer = () => {
  const { isMobileSmallSize: enabled } = useWindowWidth();
  const { isOpen, setIsOpen: _setIsOpen } = useMobileLayerStore();

  const setIsOpen = useCallback(
    (isOpen: boolean) => {
      if (!enabled) return;

      _setIsOpen(isOpen);
    },
    [enabled, _setIsOpen],
  );

  return {
    isOpen,
    setIsOpen,
  };
};
