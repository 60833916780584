import { useParams } from "@tanstack/react-router";
import { useEffect, useMemo } from "react";
import { create, useStore } from "zustand";

type ChannelStates = {
  prevChannelId: string;
  isChanged: boolean;
};

type ChannelActions = {
  setPrevChannelId: (channelId: string) => void;
  setIsChanged: (isChanged: boolean) => void;
};

const store = create<ChannelStates & ChannelActions>((set) => ({
  prevChannelId: "",
  setPrevChannelId: (channelId: string) => set({ prevChannelId: channelId }),

  isChanged: false,
  setIsChanged: (isChanged: boolean) => set({ isChanged }),
}));

export const useChannel = () => {
  const { channelId = "" } = useParams({ strict: false });
  const isInChannel = useMemo(() => channelId.length > 0, [channelId]);

  const states = useStore(store);

  useEffect(() => {
    if (channelId !== states.prevChannelId) {
      states.setPrevChannelId(channelId);
      states.setIsChanged(true);
    }
  }, [
    channelId,
    states.prevChannelId,
    states.setPrevChannelId,
    states.setIsChanged,
  ]);

  return {
    ...states,
    channelId,
    isInChannel,
  };
};
