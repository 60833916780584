import { memo } from "react";
import { CONTAINER_IDS } from "./constants";

import styles from "./video.module.scss";

type VideoProps = {
  containerId?: string;
};

function _Video({ containerId = CONTAINER_IDS.CHANNEL_MAIN }: VideoProps) {
  return (
    <div className={styles.video_wrap}>
      <div className={styles.video_stream} id={containerId} />
    </div>
  );
}

export const Video = memo(_Video);
