import { createContext, useContext } from "react";

type HistorySearchContextStates = {
  searchText: string;
  setSearchText: (text: string) => void;

  inputText: string;
  setInputText: (text: string) => void;
};

export const HistorySearchContext = createContext<
  HistorySearchContextStates | undefined
>(undefined);

export const useHistorySearchContext = () => {
  const context = useContext(HistorySearchContext);
  if (!context) {
    throw new Error(
      "useHistorySearchContext must be used within a HistorySearchProvider",
    );
  }

  return context;
};
