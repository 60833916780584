import type React from "react";
import type { ButtonHTMLAttributes } from "react";
import SettingIcon from "../images/ico_setting.png";
import styles from "./setting-button.module.scss";

type SettingButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const SettingButton: React.FC<SettingButtonProps> = (props) => {
  return (
    <button {...props} className={styles.btn_setting} type="button">
      <img src={SettingIcon} alt="icon" />
      <span>환경 설정</span>
    </button>
  );
};

export default SettingButton;
