import type { FC } from "react";
import searchImg from "~assets/images/ico_search.png";
import { Button } from "~features/ui/button";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./search-button.module.scss";

type SearchButtonProps = {
  onSubmit?: () => void;
};

export const SearchButton: FC<SearchButtonProps> = ({ onSubmit, ...props }) => {
  const _onSubmit = createEventHandler({
    handler: () => {
      onSubmit?.();
    },
  });
  return (
    <Button
      {...props}
      onClick={_onSubmit}
      overrideClass={styles.btn_search}
      type="onlyIcon"
      aria-label="search button"
      iconBefore={<img src={searchImg} alt="검색 버튼" />}
    />
  );
};
