import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  offset,
  shift,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { type PropsWithChildren, useState } from "react";
import { createPortal } from "react-dom";
import type { SearchResult } from "~features/search/schemas";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import styles from "./hover-tooltip.module.scss";

type HoverTooltipProps = PropsWithChildren<{
  link: string;
  source?: SearchResult.WebSource;
  className?: string;
}>;

// @todo composition으로 리팩토링 - trigger-tooltip 분리
export function HoverTooltip({
  link,
  source,
  children,
  className,
}: HoverTooltipProps) {
  const { isMobileSmallSize } = useWindowWidth();
  const [isOpen, setIsOpen] = useState(false);
  const { refs, context, floatingStyles } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: "bottom-start",
    whileElementsMounted: autoUpdate,
    middleware: [
      offset({ mainAxis: 8, crossAxis: 0 }),
      flip({ fallbackAxisSideDirection: "end" }),
      shift(),
    ],
  });

  const hover = useHover(context, { mouseOnly: true });
  const dismiss = useDismiss(context, {});
  const role = useRole(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
    role,
  ]);

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={className}
      >
        {children}
      </div>

      {!isMobileSmallSize && isOpen && source && (
        <FloatingFocusManager context={context} modal={false}>
          {createPortal(
            <a
              ref={refs.setFloating}
              href={link}
              style={floatingStyles}
              className={cn(
                "flex flex-col p-4 bg-white border border-solid rounded-lg select-none gap-y-3 border-black/10 w-[386px] h-max max-h-[178px] focus:outline-none",
                styles.shadow,
              )}
              {...getFloatingProps()}
            >
              <div className="flex flex-col w-full gap-y-1">
                <h5 className="text-base font-semibold text-alan-black-1 line-clamp-2">
                  (출처 {source.id}) {source.title}
                </h5>

                <div
                  className={cn(
                    "flex flex-row items-center justify-start gap-x-1 font-normal text-alan-black-5 flex-nowrap overflow-hidden text-ellipsis",
                    styles.paragraphs,
                  )}
                >
                  {source.favicon && (
                    <img
                      src={source.favicon}
                      alt={""}
                      className="w-4 rounded-full"
                    />
                  )}
                  {source.source && <p className="w-max">{source.source}</p>}
                  {Boolean(source.source && source.updatedAt) && (
                    <p className="w-max">•</p>
                  )}
                  {source.updatedAt && (
                    <p className="w-max">{source.updatedAt}</p>
                  )}
                </div>
              </div>

              <p
                className={cn(
                  "font-normal text-alan-black-4 line-clamp-3",
                  styles.paragraphs,
                )}
              >
                {source.snippet}
              </p>
            </a>,
            document.body,
          )}
        </FloatingFocusManager>
      )}
    </>
  );
}
