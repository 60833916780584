import { v, type InferOutput } from "~libs/valibot";

export enum PermissionNames {
  pushNotification = "push_notification",
}

/** React → Native */
export const PermissionRequestSchema = v.object({
  permission: v.enum(PermissionNames),
  message: v.string(),
});

export type PermissionRequest = InferOutput<typeof PermissionRequestSchema>;

export const PermissionCheckRequestSchema = v.object({
  permission: v.enum(PermissionNames),
});

export type PermissionCheckRequest = InferOutput<
  typeof PermissionCheckRequestSchema
>;

/** Native → React */
export const PermissionResponseDataSchema = v.pipe(
  v.boolean(),

  v.transform((response) => ({
    granted: response,
  })),
);

export type PermissionResponseData = InferOutput<
  typeof PermissionResponseDataSchema
>;
