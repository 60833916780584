import { cn } from "~utils/class-names";
import styles from "./image.module.scss";

type MarkdownImageProps = {
  src: string;
  alt: string;
  title: string;
  className?: string;
};

export function MarkdownImage(props: MarkdownImageProps) {
  return (
    <img
      src={props.src}
      alt={props.alt}
      title={props.title}
      className={cn(styles.image, props.className)}
    />
  );
}
