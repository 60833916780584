import { v } from "~libs/valibot";
import { MESSAGE_STATUS$SERVER } from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @todo 인터페이스 확인
 */
const ErrorMessageSchema$Socket = v.pipe(
  v.object({
    stop_reason: v.literal(MESSAGE_STATUS$SERVER.ERROR),
    request_id: MessagesSchemas$Api.Item.entries.request_id,
  }),

  v.transform((input) => ({
    requestId: input.request_id,
  })),
);

export const parseErrorMessage$Socket = (input: unknown) =>
  v.parse(ErrorMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const ErrorMessagePattern$Socket = getMessagePattern({
  type: MESSAGE_STATUS$SERVER.ERROR,
});
