import type { FC, MouseEvent } from "react";
import styles from "./modal.module.scss";

export const AlertModal: FC<{
  message: string;
  title?: string | undefined;
  url?: string | undefined;
  action?: (e: MouseEvent) => void;
}> = ({ message, title, url, action }) => {
  return (
    <div className={styles.modal_wrap}>
      <div className={styles.modal}>
        <div className={styles.modal_contents}>
          <h3 dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        <div className={styles.modal_btn_wrap}>
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            onClick={(e) => {
              if (action) action(e);
            }}
          >
            <span>{title}</span>
          </a>
        </div>
      </div>
    </div>
  );
};
