import {
  type InferOutput,
  safeParse,
  literal as vLiteral,
  object as vObject,
} from "valibot";

/**
 * @example
  ```json
  {
    "type": "system",
    "event": "connected",
    "userId": null,
    "connectionId": "n_lItS2IyElSY9fQP48W1gRu7ObAw02"
  }    
  ```
 */
export type ConnectedSocketMessage = InferOutput<typeof Connected>;

const Connected = vObject({
  type: vLiteral("system"),
  event: vLiteral("connected"),
});

export const isConnectedMessage = (message: unknown) =>
  safeParse(Connected, message).success;

/**
 * @example
  ```json
  {
    "type": "ack",
    "ackId": 1721131109274,
    "success": true
  }
  ```
 */
export type AckSocketMessage = InferOutput<typeof Ack>;

const Ack = vObject({
  type: vLiteral("ack"),
  success: vLiteral(true),
});

export const isAckMessage = (message: unknown) =>
  safeParse(Ack, message).success;

export type SystemSocketMessages = ConnectedSocketMessage | AckSocketMessage;
