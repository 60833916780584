import { MessageAssistantName } from "./message-assistant-name";
import { MessageRow } from "./message-row";

type MessageAssistantPersonaProps = {
  name: string;
  className?: string;
};

export function MessageAssistantPersona(props: MessageAssistantPersonaProps) {
  return (
    <MessageRow>
      <MessageAssistantName name={props.name} />
    </MessageRow>
  );
}
