import { Link } from "@tanstack/react-router";
import type { PropsWithChildren } from "react";
import topImg from "~/assets/images/img_error_etc.png";
import logoImg from "~/assets/images/logo_alan.png";
import { useUserAgent } from "~features/user-agent";
import styles from "~layouts/maintenance/maintenance.module.scss";

export default function ServerErrorLayout() {
  const { isWeb } = useUserAgent();
  const RedirectToHome = isWeb ? RedirectToHomeWeb : RedirectToHomeNative;

  return (
    <section className={styles.container}>
      <article className={styles.content_wrap}>
        <div className={styles.login}>
          <img src={topImg} alt="" className={styles.img_top} />
          <h2>오류가 발생했습니다.</h2>
          <p>
            죄송합니다. 오류가 발생했습니다.
            <br />
            문제가 반복되면&nbsp;
            <a href="https://altools.co.kr/service/contact">고객센터</a>에
            문의해 주세요.
          </p>
          <div className={styles.btn_wrap}>
            <RedirectToHome>메인으로 이동</RedirectToHome>
          </div>
        </div>
      </article>
      <article className={styles.bottom_wrap}>
        <ul className={styles.links}>
          <li>
            <img className={styles.img_logo} src={logoImg} alt="alan" />
          </li>
        </ul>
      </article>
    </section>
  );
}

function RedirectToHomeWeb({ children }: PropsWithChildren) {
  return (
    <a href={window.location.origin} className={styles.link}>
      {children}
    </a>
  );
}

function RedirectToHomeNative({ children }: PropsWithChildren) {
  return (
    <Link to="/" className={styles.link}>
      {children}
    </Link>
  );
}
