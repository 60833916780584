import { useEffect } from "react";
import { create } from "zustand";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "~utils/localstorage-value";

type TimestampGuideStates = {
  isOpen: boolean;
};

type TimestampGuideActions = {
  setIsOpen: (isOpen: boolean) => void;
};

type TimestampGuideLocalStorage = {
  isOpen?: "CLOSE";
};

const STORAGE_KEY = "alan.tooltip.timestamp.v181";
const CLOSE = "CLOSE";
const INIT_STATE: TimestampGuideStates = {
  isOpen:
    getLocalStorageValue<TimestampGuideLocalStorage>(STORAGE_KEY)?.isOpen !==
    CLOSE,
};

const timestampGuideStore = create<
  TimestampGuideStates & TimestampGuideActions
>((set) => ({
  ...INIT_STATE,
  setIsOpen: (isOpen) => set({ isOpen }),
}));

export const useTimestampGuide = () => {
  const { isOpen, setIsOpen } = timestampGuideStore();

  useEffect(() => {
    if (!isOpen) {
      setLocalStorageValue(STORAGE_KEY, {
        isOpen: CLOSE,
      } as TimestampGuideLocalStorage);
    }
  }, [isOpen]);

  return {
    isOpen,
    setIsOpen,
  };
};
