import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./message-assistant-suggestion.module.scss";

type MessageAssistantSuggestionProps = {
  suggestion: string;
  onClick?: () => void | Promise<void>;
  className?: string;
};

export function MessageAssistantSuggestion(
  props: MessageAssistantSuggestionProps,
) {
  // TODO: 유튜브 요약 앨런에서만 - props로 받든지 click 통계 핸들러 수정하든지
  // const { sendClickChatSuggestion } = useYoutubeStats();

  const onClick = createEventHandler({
    handler: (_e) => {
      props.onClick?.();
      // sendClickChatSuggestion({ question: props.suggestion });
    },
  });

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.suggestion_button, props.className)}
    >
      {props.suggestion}
    </button>
  );
}
