import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useState } from "react";
import { AppDownloadButtons } from "~features/ui/app-download-button";
import MoreButton from "~features/ui/popup-menu/buttons/more-button/more-button";
import MenuBox from "~features/ui/popup-menu/menu-box/menu-box";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./app-download.module.scss";
import DownloadIcon from "./images/ico_download.png";

export function AppDownload() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const onClickMenuButton = createEventHandler({
    handler: () => {
      setIsMenuOpen((prev) => !prev);
    },
  });
  const { refs, context, floatingStyles } = useFloating({
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
    placement: "top-start",
    whileElementsMounted: autoUpdate,
    middleware: [flip({ fallbackAxisSideDirection: "end" }), shift()],
  });

  const dismiss = useDismiss(context, {});
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
  ]);

  return (
    <div className={styles.app_download_wrapper}>
      <div className={styles.btn_app_list}>
        <img src={DownloadIcon} alt="icon" />
        <span>앱 다운로드</span>
      </div>
      <div className={styles.popup_menu}>
        <span role="button" ref={refs.setReference} {...getReferenceProps()}>
          <MoreButton isGray={true} onClick={onClickMenuButton} />
        </span>
        {isMenuOpen && (
          <FloatingFocusManager context={context} modal={false}>
            <div>
              <MenuBox
                ref={refs.setFloating}
                width="200px"
                floatingStyles={floatingStyles}
                {...getFloatingProps()}
              >
                <div className={styles.app_list}>
                  <AppDownloadButtons />
                </div>
              </MenuBox>
            </div>
          </FloatingFocusManager>
        )}
      </div>
    </div>
  );
}
