import { type CSSProperties, type PropsWithChildren, forwardRef } from "react";
import { cn } from "~utils/class-names";
import styles from "./menu-box.module.scss";

type MenuBoxProps = PropsWithChildren<{
  width?: string;
  height?: string;
  padding?: string;
  floatingStyles?: CSSProperties;
  overrideClass?: string;
}>;

const MenuBox = forwardRef<HTMLDivElement, MenuBoxProps>(
  (
    {
      width = "auto",
      height = "auto",
      padding = "14px",
      floatingStyles,
      overrideClass,
      children,
    },
    ref,
  ) => (
    <div
      ref={ref}
      className={cn(styles.menu_box, overrideClass)}
      style={{ width, height, padding, ...floatingStyles }}
    >
      {children}
    </div>
  ),
);

export default MenuBox;
