import { useLocation } from "@tanstack/react-router";
import { useMemo } from "react";
import { useUser } from "~features/auth";
import {
  AlanGuideTooltip,
  DEFAULT_GUIDE_TITLE,
  DefaultGuideContent,
} from "~features/tooltip";
import { cn } from "~utils/class-names";

import styles from "./usage-indicator.module.scss";

const AVAILABLE_QUESTIONS_TITLE = {
  DEFAULT: "남은 개수",
  YOUTUBE: "남은 개수",
} as const;

type UsageIndicatorProps = {
  isFlexEnd?: boolean;
  className?: string;
};

export const UsageIndicator = ({
  isFlexEnd,
  className,
}: UsageIndicatorProps) => {
  const { user, isLoggedIn } = useUser();
  const { pathname } = useLocation();
  const { isYoutubePage } = useMemo(() => {
    const isYoutubePage = pathname.startsWith("/summary/youtube");
    return {
      isYoutubePage,
    };
  }, [pathname]);
  const remainingQuestions = user.usageLimit - user.usageCount;
  const shouldRenderUsageIndicator =
    isLoggedIn && user.usageLimit !== 0 && remainingQuestions <= 5;

  return (
    <div
      className={cn(
        styles.question_number,
        {
          [styles.flex_end]: isFlexEnd,
        },
        className,
      )}
    >
      {shouldRenderUsageIndicator && (
        <>
          {isYoutubePage
            ? AVAILABLE_QUESTIONS_TITLE.YOUTUBE
            : AVAILABLE_QUESTIONS_TITLE.DEFAULT}

          <p>{remainingQuestions}</p>
        </>
      )}

      <AlanGuideTooltip
        isPositionBottom={!isYoutubePage}
        title={DEFAULT_GUIDE_TITLE}
      >
        <DefaultGuideContent />
      </AlanGuideTooltip>
    </div>
  );
};
