import { createContext, useContext } from "react";

type IsPendingContextStates = {
  isPending: boolean;
  setIsPending: (isPending: boolean) => void;
};

export const IsPendingContext = createContext<
  IsPendingContextStates | undefined
>(undefined);

export const useIsPendingContext = () => {
  const context = useContext(IsPendingContext);
  if (!context) {
    throw new Error(
      "useIsPendingContext must be used within a IsPendingProvider",
    );
  }

  return context;
};
