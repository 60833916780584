import { createStore, useStore } from "zustand";

export type ErrorInfo = {
  code: string;
  message: string;
  landingInfo?: {
    title: string;
    url: string;
  };
};

type ErrorStates = {
  hasError: boolean;
  error: ErrorInfo;
};

type ErrorActions = {
  setErrorDetail: (error: ErrorInfo) => void;
  resetErrorDetail: () => void;
};

const INIT_STATES: ErrorStates = {
  hasError: false,
  error: {
    code: "",
    message: "",
  },
} as const;

const store = createStore<ErrorStates & ErrorActions>((set) => ({
  ...INIT_STATES,
  setErrorDetail: (error) => set({ hasError: true, error }),
  resetErrorDetail: () => set(INIT_STATES),
}));

export const useErrorInfo = () => useStore(store);

export const setErrorDetail = (errorDetail: ErrorInfo) =>
  store.getState().setErrorDetail(errorDetail);
