import { useMemo } from "react";
import { useCopyToClipboard } from "usehooks-ts";
import { getAndroidVersion, useUserAgent } from "~features/user-agent";

export const useCopyButton = () => {
  const [_copiedValue, copy] = useCopyToClipboard();
  const { isAndroid } = useUserAgent();
  const toastEnabled = useMemo(
    () => !isAndroid || (getAndroidVersion() ?? Number.MAX_SAFE_INTEGER) < 13,
    [isAndroid],
  );

  return {
    copy,
    toastEnabled,
  };
};
