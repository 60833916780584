import {
  type UndefinedInitialDataOptions,
  queryOptions,
} from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { QUERY_KEYS } from "~features/providers/tanstack-query";

const DEFAULT_QUERY_OPTIONS = {
  staleTime: TIMES.SEC * 0.5,
  refetchOnMount: true,
  refetchOnWindowFocus: true,
} as const;

const defaultFetchMessages = async (channelId: string) =>
  KyClient.get(`channels/${channelId}/messages`).json();

type MessagesQueryOptionsFactoryArgs<Messages = unknown> = {
  parseResponse: (res: unknown) => Messages;
  fetchFn?: (channelId: string) => Promise<unknown>;
};

/** @todo MessageSchema */
export const messagesQueryOptionsFactory =
  <Messages = unknown>({
    parseResponse,
    fetchFn = defaultFetchMessages,
  }: MessagesQueryOptionsFactoryArgs<Messages>) =>
  (channelId: string) => {
    const options = Object.assign({}, DEFAULT_QUERY_OPTIONS, {
      enabled: channelId.length > 0,
      queryKey: QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId),
      queryFn: async () => fetchFn(channelId).then(parseResponse),
      // @todo refetchInterval
    } as UndefinedInitialDataOptions<Messages>);

    return queryOptions(options);
  };
