import { createContext, useContext } from "react";

type DeprecatedPersonaContextStates = {
  isDeprecated: boolean;
};

export const DeprecatedPersonaContext = createContext<
  DeprecatedPersonaContextStates | undefined
>(undefined);

export const useDeprecatedPersonaContext = () => {
  const context = useContext(DeprecatedPersonaContext);
  if (!context) {
    throw new Error(
      "useDeprecatedPersonaContext must be used within a DeprecatedPersonaProvider",
    );
  }

  return context;
};
