import { create } from "zustand";
import { NativeBridgeAndroid } from "./_android";
import type { NativeBridgeCore } from "./_core";
import { NativeBridgeIos } from "./_ios";
import { NativeBridgeWindows } from "./_windows";
import { EMPTY_NATIVE_MESSAGE } from "./receive.constants";
import type { NativeMessageCore } from "./receive.types";

declare global {
  interface Window {
    opera: string;
    handleNativeMessage: (message?: string) => void;
  }
}

type NativeBridgeStore = {
  nativeBridge?: NativeBridgeCore;
  setNativeBridge: (nativeBridge: NativeBridgeCore) => void;
  receivedMessage: NativeMessageCore;
  setReceivedMessage: (message: NativeMessageCore) => void;
  resetReceivedMessage: () => void;
};

export const useNativeBridge = create<NativeBridgeStore>((set) => ({
  nativeBridge: undefined,
  setNativeBridge: (nativeBridge: NativeBridgeCore) => set({ nativeBridge }),

  receivedMessage: EMPTY_NATIVE_MESSAGE,
  setReceivedMessage: (message: NativeMessageCore) =>
    set({ receivedMessage: message }),

  resetReceivedMessage: () => set({ receivedMessage: EMPTY_NATIVE_MESSAGE }),
}));

export const createNativeBridge = (platformInfo: {
  isIos: boolean;
  isAndroid: boolean;
  isWindows: boolean;
}) => {
  if (platformInfo.isWindows) return new NativeBridgeWindows();
  if (platformInfo.isAndroid) return new NativeBridgeAndroid();
  if (platformInfo.isIos) return new NativeBridgeIos();
  return undefined;
};
