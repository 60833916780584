import { infiniteQueryOptions, useInfiniteQuery } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { useUser } from "~features/auth";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { groupHistoryItems } from "./group-history-items";
import { parseHistoryResponse } from "./history.schemas";

export const useHistory = (searchQuery?: string) => {
  const queryResult = useQueryHistory(searchQuery);
  const items = queryResult.data?.pages.flatMap((page) => page.items) ?? [];

  return {
    items,
    itemGroups: groupHistoryItems(items),
    hasNextPage: queryResult.hasNextPage,

    queryResult,
  };
};

type GetHistoryQueryOptionsArgs = {
  isLoggedIn: boolean;
} & FetchHistoryArgs;

export const getHistoryQueryOptions = ({
  isLoggedIn,
  searchQuery = "",
  size = 50,
  page = 0,
}: GetHistoryQueryOptionsArgs) =>
  infiniteQueryOptions({
    enabled: isLoggedIn,
    queryKey: QUERY_KEYS.HISTORY.getHistoryQueryKey({
      searchQuery,
      size,
      page,
    }),
    queryFn: ({ pageParam: page }) =>
      fetchHistory({ searchQuery, page }).then(parseHistoryResponse),
    staleTime: TIMES.MIN * 1,
    refetchInterval: TIMES.MIN * 1,
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    getNextPageParam: ({ total }, _allPages, lastPageParam) => {
      if (lastPageParam + 50 >= total) {
        return undefined;
      }
      return lastPageParam + 50;
    },
    initialPageParam: 0,
  });

const useQueryHistory = (searchQuery?: string) => {
  const { isLoggedIn } = useUser();
  return useInfiniteQuery(getHistoryQueryOptions({ isLoggedIn, searchQuery }));
};

type FetchHistoryArgs = {
  searchQuery?: string;
  size?: number;
  page?: number;
};

const fetchHistory = async ({
  searchQuery = "",
  size = 50,
  page = 0,
}: FetchHistoryArgs) => {
  const searchParams = new URLSearchParams({
    limit: String(size),
    offset: String(page),
  });

  if (searchQuery.length > 0) {
    searchParams.set("keyword", searchQuery);
  }

  return KyClient.get("histories", { searchParams }).json();
};
