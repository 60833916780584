import { queryOptions, useQuery } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { useUserAgent } from "~features/user-agent";
import type { Config, ConfigResponse } from "./config.types";

const WEB_CONFIG_URL = import.meta.env.VITE_CONFIG_API_URL;

export const useQueryConfig = () => {
  const { isWeb } = useUserAgent();

  return useQuery({
    enabled: isWeb,
    ...configQueryOptions,
  });
};

export const configQueryOptions = queryOptions({
  queryKey: ["configs"],
  queryFn: () => fetchConfig().then(transformConfig),
  refetchOnWindowFocus: true,
  refetchInterval: 15 * TIMES.MIN,
  retry: false,
});

const fetchConfig = async () =>
  await KyClient.get(WEB_CONFIG_URL, {
    prefixUrl: undefined,
    timeout: TIMES.SEC * 30,
  }).json<ConfigResponse>();

const transformConfig = (configResponse: ConfigResponse): Config => ({
  message: {
    content: configResponse.infoMessage.content,
    type: configResponse.infoMessage.type,
  },
  halt: {
    isInHalt: configResponse.halt,
    start: configResponse.haltStart,
    end: configResponse.haltEnd || undefined,
  },
});
