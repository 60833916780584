import type { VirtualItem } from "@tanstack/react-virtual";
import { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { MarkdownRenderer } from "~features/markdown-renderer";
import messagesStyles from "~features/messages/messages.module.scss";
import AccordionButton from "~features/ui/accordion-button/accordion-button";
import { Status } from "~features/util-types/status";
import { cn } from "~utils/class-names";
import type { SearchResult as SearchResultTypes } from "../schemas";
import { SearchResultFeedback } from "./feedback";
import { SearchResultImages } from "./images";
import { ResultInnerBox } from "./inner-box";
import { SearchResultRelatedQuestions } from "./questions";
import styles from "./result.module.scss";
import { ResultSection } from "./section";
import { ResultSkeleton } from "./skeleton";
import { SearchResultToolSteps } from "./tool-steps";
import { SearchResultVideos } from "./videos";
import { SearchResultWebSources } from "./web-sources";
type SearchResultProps = SearchResultTypes.Item & { virtualItem: VirtualItem };

export const SearchResult = forwardRef<HTMLDivElement, SearchResultProps>(
  function SearchResult({ virtualItem, ...searchResult }, ref) {
    const { isLoading, isError } = useMemo(
      () => ({
        isLoading:
          searchResult.status === Status.INIT ||
          searchResult.status === Status.LOADING,
        isError: searchResult.status === Status.ERROR,
      }),
      [searchResult.status],
    );

    const MAX_TITLE_HEIGHT = 4.5 * 28;
    const [isTitleOpen, setIsTitleOpen] = useState(false);
    const [isOverflowsTitle, setIsOverflowsTitle] = useState(false); // 타이틀이 넘치는지 여부
    const titleRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      const observer = new ResizeObserver(() => {
        if (titleRef.current) {
          const elementHeight = titleRef.current.scrollHeight;
          setIsOverflowsTitle(elementHeight > MAX_TITLE_HEIGHT);
        }
      });

      if (titleRef.current) {
        observer.observe(titleRef.current);
      }

      return () => {
        if (titleRef.current) {
          observer.unobserve(titleRef.current);
        }
      };
    }, [searchResult.title]);

    const toggleTitleOpen = () => {
      setIsTitleOpen((prev) => !prev);
    };

    if (isError) {
      // TODO: 디자인 확인, 컴포넌트 분리
      return (
        <ResultSection ref={ref} virtualItem={virtualItem}>
          <ResultInnerBox className="">
            <div
              className={cn(
                styles.title_wrap,
                { [styles.overflows]: isOverflowsTitle },
                { [styles.open]: isTitleOpen },
              )}
              style={{
                maxHeight: isTitleOpen ? "none" : `${MAX_TITLE_HEIGHT}px`,
              }}
              onClick={isOverflowsTitle ? toggleTitleOpen : undefined}
            >
              <h2 className={styles.title}>
                {isOverflowsTitle && (
                  <span className={styles.btn_open}>
                    <AccordionButton isOpen={isTitleOpen} />
                  </span>
                )}
                <div ref={titleRef} className={styles.title_text}>
                  {searchResult.title}
                </div>
              </h2>
            </div>
          </ResultInnerBox>

          <ResultInnerBox>
            <p className={styles.error_message}>{searchResult.content}</p>
          </ResultInnerBox>
        </ResultSection>
      );
    }

    return (
      <ResultSection
        ref={ref}
        virtualItem={virtualItem}
        className={cn(isLoading && styles.is_loading)}
      >
        <ResultInnerBox className="">
          <div
            className={cn(
              styles.title_wrap,
              { [styles.overflows]: isOverflowsTitle },
              { [styles.open]: isTitleOpen },
            )}
            style={{
              maxHeight: isTitleOpen ? "none" : `${MAX_TITLE_HEIGHT}px`,
            }}
            onClick={isOverflowsTitle ? toggleTitleOpen : undefined}
          >
            <h2 className={styles.title}>
              {isOverflowsTitle && (
                <span className={styles.btn_open}>
                  <AccordionButton isOpen={isTitleOpen} />
                </span>
              )}
              <div ref={titleRef} className={styles.title_text}>
                {searchResult.title}
              </div>
            </h2>
          </div>

          {searchResult.status === Status.SUCCESS && (
            <SearchResultFeedback
              assistantMessageId={searchResult.messageId?.assistant ?? ""}
              textToCopy={searchResult.content}
            />
          )}
        </ResultInnerBox>

        <ResultInnerBox className="">
          <SearchResultToolSteps
            title="답변 과정"
            toolSteps={searchResult.toolSteps}
            searchStatus={searchResult.status}
          />
        </ResultInnerBox>
        {searchResult.status === Status.INIT && (
          <ResultInnerBox className="flex flex-col gap-2">
            <ResultSkeleton />
          </ResultInnerBox>
        )}
        {searchResult.content && (
          <ResultInnerBox
            className={cn(
              styles.markdown_wrap,
              isLoading && messagesStyles.in_answer,
            )}
          >
            <MarkdownRenderer contents={searchResult.content} />
          </ResultInnerBox>
        )}
        {searchResult.sources && searchResult.sources.total > 0 && (
          <ResultInnerBox className="">
            <SearchResultWebSources
              title="출처 웹문서"
              total={searchResult.sources.total}
              items={searchResult.sources.items}
            />
          </ResultInnerBox>
        )}
        {searchResult.images && searchResult.images.total > 0 && (
          <ResultInnerBox className="">
            <SearchResultImages
              title="이미지"
              total={searchResult.images.total}
              items={searchResult.images.items}
            />
          </ResultInnerBox>
        )}
        {searchResult.videos && searchResult.videos.total > 0 && (
          <ResultInnerBox className="">
            <SearchResultVideos
              total={searchResult.videos.total}
              items={searchResult.videos.items}
            />
          </ResultInnerBox>
        )}
        {searchResult.status === Status.SUCCESS && searchResult.questions && (
          <ResultInnerBox>
            <SearchResultRelatedQuestions
              title="관련된 질문"
              questions={searchResult.questions ?? []}
            />
          </ResultInnerBox>
        )}
      </ResultSection>
    );
  },
);
