import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ulid } from "ulid";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import type { History, HistoryItem } from "~features/history";
import { usePersonas } from "~features/personas";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { cloneObject } from "~utils/clone-object";
import { parseVideoIdFromYoutubeUrl } from "./utils";
import {
  LOADING_DATA,
  SummaryStatus,
  type YoutubeSummary,
} from "./youtube-summary.schema";

export const useMutationRequestSummary = () => {
  const queryClient = useQueryClient();
  const { youtubePersonaId } = usePersonas();

  return useMutation({
    mutationFn: requestSummary,

    onMutate: async ({ channelId, url, videoTitle = "" }) => {
      const channelQueryKey =
        QUERY_KEYS.SUMMARY.getYoutubeResultQueryKey(channelId);

      await queryClient.cancelQueries({ queryKey: channelQueryKey });
      queryClient.setQueryData(channelQueryKey, () => {
        return Object.assign({}, LOADING_DATA, {
          video: {
            title: videoTitle,
            url,
            id: parseVideoIdFromYoutubeUrl(url),
          },
          status: SummaryStatus.start,
        } as Partial<YoutubeSummary>);
      });

      queryClient.setQueryData<History>(
        QUERY_KEYS.HISTORY.getHistoryQueryKey({}),
        (prevHistory) => {
          const now = new Date();
          const newItem = createNewHistory(channelId, youtubePersonaId, now);

          if (!prevHistory) {
            return { items: [newItem], total: 1 };
          }

          const newHistory = cloneObject(prevHistory);
          newHistory.items = [newItem].concat(newHistory.items);

          return newHistory;
        },
      );
    },

    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: QUERY_KEYS.HISTORY.ROOT,
      });
    },
  });
};

const API_PATH = "youtube/summary";

type RequestSummaryArgs = {
  channelId: string;
  url: string;
  videoTitle?: string;
};

const requestSummary = async ({ channelId, url }: RequestSummaryArgs) => {
  return await KyClient.post(API_PATH, {
    json: {
      channel_id: channelId,
      url,
    },
    timeout: TIMES.MIN * 1,
  }).json();
};

const createNewHistory = (
  channelId: string,
  personaId: string,
  now = new Date(),
): HistoryItem => {
  return {
    id: ulid(),
    channelId,
    personaId,
    title: "(비디오 정보 가져오는 중)",
    tags: [],
    content: "",
    updatedDateTime: now,
    updatedOriginal: now.toISOString(),
  };
};
