import { queryOptions, useQuery } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { parsePersonasResponse } from "./personas.schemas";

const fetchPersonas = async () => KyClient.get("personas").json();

export const personasQueryOptions = queryOptions({
  queryKey: QUERY_KEYS.PERSONAS.LIST,
  queryFn: () => fetchPersonas().then(parsePersonasResponse),
  staleTime: TIMES.MIN * 10,
  refetchInterval: TIMES.MIN * 10,
  retry: false,
  placeholderData: [],
});

export const useQueryPersonas = () => {
  return useQuery(personasQueryOptions);
};
