import { memo } from "react";

function _CopySvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="copy"
      width="18"
      height="18"
      fill="none"
    >
      <path
        className="icon_stroke"
        d="M4.5 8.833c0-2.042 0-3.064.635-3.698C5.769 4.5 6.79 4.5 8.833 4.5H11c2.043 0 3.064 0 3.698.635.635.634.635 1.656.635 3.698v3.611c0 2.043 0 3.065-.635 3.699-.634.635-1.655.635-3.698.635H8.833c-2.042 0-3.064 0-3.698-.635-.635-.634-.635-1.656-.635-3.699v-3.61z"
        stroke="#24354A"
        opacity=".7"
        strokeWidth="1.4"
      />
      <path
        className="icon_stroke"
        d="M2.635 13.643A2.167 2.167 0 0 1 2 12.111V7.778c0-2.724 0-4.086.846-4.932C3.692 2 5.054 2 7.778 2h2.889"
        stroke="#24354A"
        opacity=".7"
        strokeWidth="1.4"
        strokeLinecap="round"
      />
    </svg>
  );
}

export const CopySvg = memo(_CopySvg);
