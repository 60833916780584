import type debounce from "just-debounce-it";
import { createContext, useContext } from "react";

type TimeoutErrorContextStates = {
  hasTimeoutError: boolean;
  setHasTimeoutError: (hasTimeoutError: boolean) => void;
  debouncedTimeoutHandler: ReturnType<typeof debounce<() => void>>;
};

export const TimeoutErrorContext = createContext<
  TimeoutErrorContextStates | undefined
>(undefined);

export const useTimeoutError = () => {
  const context = useContext(TimeoutErrorContext);
  if (!context) {
    throw new Error(
      "useTimeoutError must be used within a TimeoutErrorProvider",
    );
  }

  return context;
};
