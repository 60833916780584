export const WEBSOCKET_PROTOCOL = "json.webpubsub.azure.v1";

export enum MESSAGE_TYPE {
  SYSTEM = "system",
  ACK = "ack",
  JOIN_GROUP = "joinGroup",
}

/** @deprecated */
export enum RESPONSE_MESSAGE_TYPES {
  _INIT = "__init__",
  /** @description 메시지 전송후 socket 답변 전까지 노출 */
  LOADING = "__loading__",
  SAVED_MESSAGE = "saved_message",
  START_AGENT = "start_agent",
  ACTION = "action",

  /**
   * @description
   * - 답변 중일 때
   * - 기본/다른모드/이어서보기에 따라 동작 구분 필요
   */
  IN_ANSWER = "answer_continue",

  /**
   * @description
   * - 이어보기
   * - 기본 답변 이어보기 / 다른 모드 답변 이어보기
   */
  CONTINUE_START_AGENT = "continue_start_agent",
  FINISH_ANSWER = "answer_complete",
  ERROR = "error",
  CONTENT_FILTERED_ERROR = "content_filter",
  CANCEL = "canceled",
  _DUMMY = "",

  USER_MESSAGE = "__user_message",
}
