import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./completions.module.scss";
import { useCompletionContext } from "./context";
import type { Completions } from "./types";

type PromptCompletionProps = PropsWithChildren<{
  completion: Completions.Item;
  className?: string;
}>;

export function Completion({ completion, className }: PromptCompletionProps) {
  const { prev, matched, next, original } = completion;
  const { setKeywordAsTextValue, isMobileSize, textareaRef } =
    useCompletionContext();
  const onClickCompletion = createEventHandler({
    handler: () => {
      setKeywordAsTextValue(original);

      window.setTimeout(() => {
        textareaRef?.current?.focus();
      }, 100);
    },
  });

  return (
    <li
      onClick={onClickCompletion}
      className={cn(styles.completion, className)}
    >
      <div className={styles.item}>
        {!matched && <strong>{original}</strong>}

        {prev && <strong>{prev}</strong>}
        {matched && <span className="font-normal">{matched}</span>}
        {next && <strong>{next}</strong>}
      </div>
    </li>
  );
}
