import youtubeIcon from "~assets/images/ico_youtube_thumbnail.png";
import styles from "./thumbnail.module.scss";

type ThumbnailProps = {
  thumbnailSrc?: string;
  isVideo?: boolean;
  alt?: string;
};

export const Thumbnail: React.FC<ThumbnailProps> = (props) => {
  return (
    <div className={styles.thumbnail}>
      {props.thumbnailSrc && (
        <>
          <img src={props.thumbnailSrc} alt="thumbnail" />
          {props.isVideo && (
            <img
              className={styles.icon_youtube}
              src={youtubeIcon}
              alt={props.alt}
              loading="lazy"
            />
          )}
        </>
      )}
    </div>
  );
};
