import {
  type ChangeEvent,
  type KeyboardEvent,
  type MouseEvent,
  memo,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { match } from "ts-pattern";
import LoadingIcon from "~assets/images/Ico_loading.png";
import ArrowImg from "~assets/images/ico_related_questions_arrow.png";
import { ERROR_MESSAGE_USAGE } from "~constants/errors";
import { useUser } from "~features/auth";
import { useChannel } from "~features/channel";
import { useDeprecatedPersonaContext } from "~features/personas";
import { Prompt, RawTextContext } from "~features/prompt";
import { setDefaultErrorToast } from "~features/toast";
import { SearchButton } from "~features/ui/prompt/buttons";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import type { SearchResult } from "../schemas";
import { useIsPendingContext } from "../use-is-pending.context";
import { useSendSearchTitle } from "../use-send-search-title";
import styles from "./question.module.scss";

type SearchResultRelatedQuestionsProps = {
  title: string;
  questions: SearchResult.Question[];
  className?: string;
};

const PLACEHOLDERS = {
  DEFAULT: "궁금한 내용을 이어서 검색하세요.",
  DEPRECATED: "관련된 질문을 입력할 수 없어요.",
} as const;

export function SearchResultRelatedQuestions({
  title = "관련된 질문",
  questions,
  className,
}: SearchResultRelatedQuestionsProps) {
  const { user } = useUser();
  const { channelId } = useChannel();
  const [rawText, setRawText] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const { sendSearchTitleMutation, requestNativeNotificationPermission } =
    useSendSearchTitle();
  const { isPending } = useIsPendingContext();
  const { isDeprecated } = useDeprecatedPersonaContext();

  /** @todo 서버단에서 실패한 경우도 처리해야 하는지 */
  const isQuestionsLoading = useMemo(() => questions.length === 0, [questions]);

  const sendPrompt = useCallback(
    async (prompt?: string) => {
      if (isPending) return;

      const message = (rawText.length > 0 ? rawText : prompt)?.trim() ?? "";
      // TODO: input validator
      if (!message || !channelId) {
        throw new Error("[PromptInSearch.sendPrompt] Invalid Args");
      }

      if (user.usageCount >= user.usageLimit) {
        setDefaultErrorToast(ERROR_MESSAGE_USAGE.message);
        return;
      }

      sendSearchTitleMutation.mutateAsync({
        channelId,
        message,
      });
      requestNativeNotificationPermission();
    },
    [
      isPending,
      user,
      channelId,
      rawText,
      sendSearchTitleMutation,
      requestNativeNotificationPermission,
    ],
  );

  const onChangeTextarea = createEventHandler<ChangeEvent<HTMLTextAreaElement>>(
    {
      handler: (e) => {
        const rawValue = e.currentTarget.value;
        setRawText(rawValue);
      },
    },
  );

  const onKeyDownTextarea = createEventHandler<
    KeyboardEvent<HTMLTextAreaElement>
  >({
    preventDefault: false,
    handler: (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        sendPrompt(rawText);
      }
    },
  });

  const onClickTextarea = createEventHandler<MouseEvent>({
    handler: () => {
      if (isDeprecated) {
        setDefaultErrorToast(
          "이전 대화 모드에서는 추가 질문을 할 수 없습니다.",
        );
      }
    },
    stopPropagation: false,
  });

  return (
    <RawTextContext.Provider value={{ rawText, setRawText }}>
      <Prompt.Root
        context={{
          textareaEnabled: !isDeprecated,
          sendPrompt,
          placeholderText: isDeprecated
            ? PLACEHOLDERS.DEPRECATED
            : PLACEHOLDERS.DEFAULT,
          textareaRef,
          onChangeTextarea,
          onKeyDownTextarea,
          textareaRows: 1,
        }}
        className={cn(className)}
      >
        <div className={styles.question_wrapper}>
          <h4 className={styles.question_title}>{title}</h4>
          <div className={styles.question_box}>
            <Prompt.FormContainer className={cn()}>
              <Prompt.Form>
                <ul className={styles.question_list}>
                  {match({ isQuestionsLoading, isDeprecated })
                    .with({ isDeprecated: true }, () => null)
                    .with({ isQuestionsLoading: true }, () => (
                      <QuestionsLoading />
                    ))
                    .otherwise(() =>
                      questions.map(({ question }) => (
                        <li
                          key={`search-result-${question}`}
                          className={styles.question}
                        >
                          <ArrowIcon />
                          <p
                            onClick={() => {
                              sendPrompt(question);
                            }}
                          >
                            {question}
                          </p>
                        </li>
                      )),
                    )}

                  <li
                    className={cn(styles.question, styles.question_prompt, {
                      [styles.disabled]: isDeprecated,
                    })}
                    onClick={onClickTextarea}
                  >
                    <ArrowIcon
                      className={cn(rawText.length > 0 ? "" : styles.disabled)}
                    />
                    <Prompt.Textarea
                      ref={textareaRef}
                      className={cn({
                        [styles.textarea_disabled]: isDeprecated,
                      })}
                    />
                    {!isDeprecated && (
                      <Prompt.Buttons>
                        <SearchButton onSubmit={sendPrompt} />
                      </Prompt.Buttons>
                    )}
                  </li>
                </ul>
              </Prompt.Form>
            </Prompt.FormContainer>
          </div>
        </div>
      </Prompt.Root>
    </RawTextContext.Provider>
  );
}

const QuestionsLoading = memo(function QuestionsLoading() {
  return (
    <li className={styles.question_loading}>
      <span className={styles.icon}>
        <img src={LoadingIcon} alt="" />
      </span>
      <p>관련된 질문을 생성하고 있어요.</p>
    </li>
  );
});

const ArrowIcon = memo(function ArrowIcon({
  className,
}: {
  className?: string;
}) {
  return (
    <span className={cn(styles.ico_arrow, className)}>
      <img src={ArrowImg} alt="" />
    </span>
  );
});
