import { useQuery } from "@tanstack/react-query";
import { TIMES } from "~constants/times";
import { requestSuggestions } from "./request-suggestions";
import type { SuggestionsRequest } from "./suggestion-query.schema";

export const getSuggestionQueryKey = ({
  channelId,
  messageId,
}: SuggestionsRequest) => ["suggestions", channelId, messageId];

/**
 * @todo 컴포넌트 - Query 연동 방법..
 * - 마지막 답변 ~ suggestion 사이 시차가 있어서 그 사이에 요청 가지 않도록 해야함
 */
export const useQuerySuggestions = (suggestionsRequest: SuggestionsRequest) => {
  return useQuery({
    queryKey: getSuggestionQueryKey(suggestionsRequest),
    queryFn: () => requestSuggestions(suggestionsRequest),
    staleTime: 15 * TIMES.MIN,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    initialData: [],
  });
};
