import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./message-container.module.scss";

type MessageContainerProps = PropsWithChildren<{
  className?: string;
}>;

export function MessageContainer({
  className = "",
  children,
}: MessageContainerProps) {
  return (
    <li className={cn(styles.message_container, styles.flex_col, className)}>
      <div className={styles.message_wrap}>{children}</div>
    </li>
  );
}
