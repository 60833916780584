import { transformSteps } from "~features/search/schemas/transform.utils";
import { Status } from "~features/util-types/status";
import { v } from "~libs/valibot";
import { MESSAGE_STATUS$SERVER } from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @example
 ```json
 {
  "type": "message",
  "from": "group",
  "fromUserId": null,
  "group": "66967876e303453a6e23441d",
  "dataType": "json",
  "data": {
    "id": null,
    "request_id": "3ca88dfc-437f-11ef-ab5a-568aa2b74468",
    "persona_id": "669191ccfa9cf2010f533369",
    "channel_id": "66967876e303453a6e23441d",
    "user_id": "65af73b6583a64a91bd6e002",
    "client_id": "7b96ccc8-f019-45e0-82df-62f40aa26553",
    "user_role": "assistant",
    "type": "action",
    "stop_reason": null,
    "content": "",
    "actions": [
      {
        "name": "search_web",
        "arguments": {},
        "speak": "관련 최신 정보를 확인하고 있어요.",
        "created_at": "2024-07-16T14:25:32.753410Z"
      }
    ],
    "metadata": [],
    "gpt_info": {},
    "order": null,
    "created_at": "2024-07-16T14:25:32.753439Z",
    "updated_at": "2024-07-16T14:25:32.753441Z"
  }
 }
 ```
 */
const ToolActionMessageSchema$Socket = v.pipe(
  v.object({
    type: v.literal(MESSAGE_STATUS$SERVER.ACTION),
    request_id: MessagesSchemas$Api.Item.entries.request_id,
    actions: MessagesSchemas$Api.Item.entries.actions,
  }),

  v.transform((input) => ({
    requestId: input.request_id,
    toolSteps: transformSteps(input.actions, Status.LOADING),
  })),
);

export const parseToolActionMessage$Socket = (input: unknown) =>
  v.parse(ToolActionMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const ToolActionMessagePattern$Socket = getMessagePattern({
  type: MESSAGE_STATUS$SERVER.ACTION,
});
