import { create } from "zustand";
import { parseUserAgent } from "./parse-user-agent";
import type { Platforms } from "./platform.constants";

type UserAgentStates = {
  version: string;
  platform: Platforms;
  isMobile: boolean;
  isIos: boolean;
  isAndroid: boolean;
  isWindows: boolean;
  isWeb: boolean;
};

export const useUserAgent = create<UserAgentStates>(() => parseUserAgent());
