import { type InferOutput, v } from "~libs/valibot";

export const IsForegroundResponseDataSchema = v.pipe(
  v.boolean(),

  v.transform((result) => ({
    isForeground: result,
  })),
);

export type IsForegroundResponseData = InferOutput<
  typeof IsForegroundResponseDataSchema
>;
