import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import type { SuggestionsRequest } from "./suggestion-query.schema";

/**
 * GET `api/v1/channels/${channelId}/suggest?message_id=${messageId}`
 */
export const requestSuggestions = async ({
  channelId,
  messageId,
}: SuggestionsRequest): Promise<null> => {
  return KyClient.get(`channels/${channelId}/suggest`, {
    searchParams: { message_id: messageId },
    timeout: 15 * TIMES.SEC,
    retry: 0,
  }).json();
};
