import { create, useStore } from "zustand";

type SendingStatusStates = {
  isPending: boolean;
  isIdle: boolean;
};

type SendingStatusActions = {
  setIsPending: () => void;
  setIsIdle: () => void;
};

const INIT_STATES: SendingStatusStates = {
  isPending: false,
  isIdle: true,
} as const;

const store = create<SendingStatusStates & SendingStatusActions>((set) => ({
  ...INIT_STATES,
  setIsIdle: () => set(INIT_STATES),
  setIsPending: () => set({ isPending: true, isIdle: false }),
}));

export const useRespondingStatus = () => {
  return useStore(store);
};
