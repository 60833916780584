import type { VotedTypes } from "~features/feedback";
import { useFeedbackSelected } from "~features/search/result";
import {
  MessageAssistantFeedbackContainer,
  MessageAssistantFeedbackForm,
} from "~features/youtube-summary/message-assistant-feedback";
import { cn } from "~utils/class-names";
import { MessageAssistantResumeButton } from "./message-assistant-resume-button";
import { MessageRowContentOnly } from "./message-row-content";

import feedbackStyles from "~features/youtube-summary/message-assistant-feedback/message-assistant-feedback.module.scss";

type MessageAssistantOptionButtonsProps = {
  messageId: string;
  messageContent: string;
  votedType?: VotedTypes;
  pausedByLength: boolean;
};

export function MessageAssistantOptionButtons(
  props: MessageAssistantOptionButtonsProps,
) {
  const { selected, setSelected } = useFeedbackSelected();

  return (
    <MessageRowContentOnly
      contentClassName={cn(
        feedbackStyles.feedback_container,
        feedbackStyles.flex_col,
      )}
    >
      <div
        className={cn(
          feedbackStyles.flex,
          feedbackStyles.flex_row,
          props.pausedByLength
            ? feedbackStyles.justify_between
            : feedbackStyles.justify_end,
        )}
      >
        {props.pausedByLength && (
          <MessageAssistantResumeButton
            messageId={props.messageId}
            onClick={() => setSelected("")}
          />
        )}

        <MessageAssistantFeedbackContainer
          messageId={props.messageId}
          textToCopy={props.messageContent}
          setFormOpen={() => setSelected(props.messageId)}
        />
      </div>

      {selected === props.messageId && (
        <MessageAssistantFeedbackForm
          messageId={props.messageId}
          closeFeedbackForm={() => setSelected("")}
        />
      )}
    </MessageRowContentOnly>
  );
}
