import {
  type PropsWithChildren,
  useDeferredValue,
  useEffect,
  useState,
} from "react";
import { VirtualizedList } from "~features/messages";
import { useMutationCancelMessage } from "~features/messages/hooks";
import { useWebSources } from "~features/messages/use-web-sources";
import { StopButton as StopSearchButton } from "~features/ui/answer/answer-stop-button";
import { Status } from "~features/util-types/status";
import {
  FeedbackSelectedContext,
  SearchResult,
  WebSourceDrawer,
} from "./result";
import { useIsPendingContext } from "./use-is-pending.context";
import { useQuerySearch } from "./use-query-search";
import { useSocketSearch } from "./use-socket-search";

import styles from "./result/result.module.scss";

type SearchResultsProps = PropsWithChildren<{
  channelId: string;
}>;

export function SearchResults({ channelId }: SearchResultsProps) {
  useSocketSearch(channelId);
  const { data: searchResults, isSuccess } = useQuerySearch(channelId);
  const deferredChannelId = useDeferredValue(channelId);
  const channelChanged = deferredChannelId !== channelId;

  const { addSource, clearSources } = useWebSources();

  const isPendingContext = useIsPendingContext();
  const cancelMutation = useMutationCancelMessage();
  const onClickStopButton = () => {
    const currentRespondingId =
      searchResults?.items
        ?.slice(-2)
        .find(
          (item) =>
            item.status === Status.INIT || item.status === Status.LOADING,
        )?.requestId ?? "";
    if (!currentRespondingId) return;

    cancelMutation.mutateAsync(currentRespondingId);
  };

  const [selectedFeedbackId, setSelectedFeedbackId] = useState("");

  useEffect(() => {
    if (!isSuccess) return;
    if (channelChanged) clearSources();

    searchResults.items
      .flatMap((item) => item.sources?.items ?? [])
      .forEach(addSource);
  }, [isSuccess, channelChanged, searchResults, addSource, clearSources]);

  return (
    <>
      <FeedbackSelectedContext.Provider
        value={{
          selected: selectedFeedbackId,
          setSelected: setSelectedFeedbackId,
        }}
      >
        {searchResults && (
          <VirtualizedList
            total={searchResults.total ?? 0}
            itemsRenderer={(virtualizer) => (virtualItem) => (
              <SearchResult
                key={`search-result-${virtualItem.index}`}
                ref={virtualizer.measureElement}
                virtualItem={virtualItem}
                {...(searchResults.items ?? [])[virtualItem.index]}
              />
            )}
            channelChanged={channelChanged}
            className={styles.result_wrapper}
          />
        )}
      </FeedbackSelectedContext.Provider>

      {isPendingContext.isPending && (
        <StopSearchButton
          onClick={onClickStopButton}
          className="absolute z-20 bottom-[72px] right-1/2 w-[122px] translate-x-1/2 shrink-0 text-nowrap"
        />
      )}

      <WebSourceDrawer />
    </>
  );
}
