import type { PropsWithChildren } from "react";
import { ToastsCoreLayout } from "~features/toast";
import { SideBar } from "~features/ui/side-bar";
import { MainLayout } from "~layouts/main";

type SearchLayoutProps = PropsWithChildren<{
  isHome?: boolean;
}>;

export function SearchLayout({ children, isHome = false }: SearchLayoutProps) {
  return (
    <>
      <SideBar />
      <MainLayout isHome={isHome}>{children}</MainLayout>
      <ToastsCoreLayout />
    </>
  );
}
