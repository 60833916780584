import { v } from "~libs/valibot";
import { MESSAGE_STATUS$SERVER } from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @example
  ```json
  {
    "id": "66f260c8a0241fecd40910c5",
    "request_id": "d216575a-7a40-11ef-a9b2-bef20ec8cb38",
    "persona_id": "6480922a1ec61303770dc724",
    "channel_id": "66f2606d877d3f79d8a9d1b7",
    "user_id": null,
    "client_id": null,
    "user_role": "assistant",
    "type": "continue_start_agent",
    "stop_reason": null,
    "content": "",
    "actions": [],
    "metadata": [],
    "gpt_info": {},
    "order": null,
    "created_at": "2024-09-24T06:49:08.746316Z",
    "updated_at": "2024-09-24T06:49:08.746318Z"
  }
  ```
 */
const ContinueStartAgentMessageSchema$Socket = v.pipe(
  v.object({
    type: v.literal(MESSAGE_STATUS$SERVER.CONTINUE_START_AGENT),
    id: MessagesSchemas$Api.Item.entries._id,
    request_id: MessagesSchemas$Api.Item.entries.request_id,
    persona_id: MessagesSchemas$Api.Item.entries.persona_id,
  }),

  v.transform((input) => ({
    messageId: input.id,
    requestId: input.request_id,
    personaId: input.persona_id,
  })),
);

export const parseContinueStartAgentMessage$Socket = (input: unknown) =>
  v.parse(ContinueStartAgentMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const ContinueStartAgentMessagePattern$Socket = getMessagePattern({
  type: MESSAGE_STATUS$SERVER.CONTINUE_START_AGENT,
});
