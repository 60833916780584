import type { PropsWithChildren } from "react";
import { HomeButton } from "~features/ui/home-button";
import { UsageIndicator } from "~features/usage-indicator";
import { useWindowWidth } from "~hooks/use-window-width";
import { cn } from "~utils/class-names";
import styles from "./header.module.scss";
import { MenuButtonMobile } from "./menu-button-mobile";

export type HeaderProps = PropsWithChildren<{
  className?: string;
  channelId?: string;
}>;

export function Header({ children, className }: HeaderProps) {
  return <header className={cn(styles.header, className)}>{children}</header>;
}

export function HeaderHome() {
  const { isMobileSmallSize } = useWindowWidth();

  return (
    <Header className={styles.home}>
      <div
        className={cn(isMobileSmallSize ? styles.home_wrap : styles.side_wrap)}
      >
        {isMobileSmallSize && (
          <span className={styles.menu_wrap}>
            <MenuButtonMobile />
          </span>
        )}
        <HomeButton />
      </div>
    </Header>
  );
}

export function HeaderWithPrompt({ children }: HeaderProps) {
  const { isMobileSmallSize } = useWindowWidth();
  return (
    <Header>
      {isMobileSmallSize ? (
        <>
          <div className={styles.top_wrap}>
            <div className={styles.btn_wrap}>
              <MenuButtonMobile />
              <HomeButton />
            </div>
            <UsageIndicator isFlexEnd={true} className="text-alan-black-5" />
          </div>
          <div className={styles.prompt_wrap}>{children}</div>
        </>
      ) : (
        <>
          <div className={styles.side_wrap}>
            <HomeButton />
          </div>

          <div className={styles.prompt_wrap}>{children}</div>
          <div className={styles.side_wrap}>
            <UsageIndicator isFlexEnd={true} className={styles.indicator} />
          </div>
        </>
      )}
    </Header>
  );
}
