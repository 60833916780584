export type { InferOutput } from "valibot";

import {
  any,
  array,
  boolean,
  enum_,
  literal,
  minLength,
  nullable,
  number,
  object,
  optional,
  parse,
  pipe,
  safeParse,
  string,
  transform,
  union,
  unknown,
  null as vNull,
} from "valibot";

export const v = {
  string,
  number,
  boolean,
  object,
  array,
  enum: enum_,

  any,
  unknown,
  literal,
  union,
  optional,
  nullable,
  null: vNull,

  minLength,

  transform,
  pipe,
  parse,
  safeParse,
} as const;
