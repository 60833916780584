import { memo } from "react";
import styles from "./message-assistant-feedback-submitted-message.module.scss";

const FEEDBACK_SUBMITTED_MESSAGE = "소중한 의견을 제공해 주셔서 감사합니다!";

function _MessageAssistantFeedbackSubmittedMessage() {
  return (
    <div className={styles.feedback_submitted_message}>
      {FEEDBACK_SUBMITTED_MESSAGE}
    </div>
  );
}

export const MessageAssistantFeedbackSubmittedMessage = memo(
  _MessageAssistantFeedbackSubmittedMessage,
);
