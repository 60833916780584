import { cn } from "~utils/class-names";
import styles from "./message-assistant-name.module.scss";

type MessageAssistantNameProps = {
  name: string;
};

export function MessageAssistantName(props: MessageAssistantNameProps) {
  return <p className={cn(styles.persona_name)}>{props.name}</p>;
}
