import { Fragment } from "react";
import { Carousel } from "~features/ui/carousel";
import { HoverTooltip } from "~features/ui/tooltip";
import type { SearchResult } from "../schemas";

type SearchResultWebSourcesProps = SearchResult.WebSources & {
  title?: string;
};

export function SearchResultWebSources({
  title = "출처 웹문서",
  total,
  items,
}: SearchResultWebSourcesProps) {
  return (
    <Carousel.List title={title} total={total}>
      {items.map((source) => (
        <Fragment key={`web-sources-${source.id}`}>
          <HoverTooltip link={source.url} source={source} className="relative">
            <Carousel.Card
              cardType="webSource"
              cardId={source.id}
              cardFavicon={source.favicon}
              cardTitle={`${source.id}. ${source.title}`}
              cardSource={source.source}
              cardUpdatedAt={source.updatedAt}
              cardUrl={source.url}
            />
          </HoverTooltip>
        </Fragment>
      ))}
    </Carousel.List>
  );
}
