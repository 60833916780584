import { ulid } from "ulid";
import {
  RESPONSE_MESSAGE_TYPES,
  type StartAgentMessage,
} from "~clients/socket";
import { MESSAGE_USER_ROLE } from "~features/messages/constants";
import type { SendMessageRequestsWithoutUserId } from "~features/youtube-summary/send-message-request.types";
import {
  type MessageOne,
  SuggestionsLoadingStatus,
} from "./message-list.schema";

export const createNewUserMessage = ({
  channelId,
  personaId,
  message,
}: SendMessageRequestsWithoutUserId): MessageOne => ({
  id: `${RESPONSE_MESSAGE_TYPES.LOADING}-${ulid()}`,
  requestId: "",
  /** @todo user message 추가 */
  type: RESPONSE_MESSAGE_TYPES._DUMMY,
  channelId,
  content: message,
  answerPersonaId: personaId,
  answerActions: [],
  isAnotherPersona: false,
  questionId: "",
  userRole: MESSAGE_USER_ROLE.USER,
  suggestions: [],
  suggestionsLoading: SuggestionsLoadingStatus.SUCCESS,
  pausedByLength: false,
  sources: {
    items: [],
    total: 0,
  },
});

export const createLoadingMessage = ({
  channelId,
  personaId,
}: Omit<SendMessageRequestsWithoutUserId, "message">): MessageOne => ({
  id: RESPONSE_MESSAGE_TYPES.LOADING,
  requestId: "",
  type: RESPONSE_MESSAGE_TYPES.LOADING,
  channelId,
  content: "",
  answerPersonaId: personaId,
  answerActions: [],
  isAnotherPersona: false,
  questionId: "",
  userRole: MESSAGE_USER_ROLE.ASSISTANT,
  suggestions: [],
  suggestionsLoading: SuggestionsLoadingStatus.SUCCESS,
  pausedByLength: false,
  sources: {
    items: [],
    total: 0,
  },
});

export const createStartAgentMessage = (
  personaId: StartAgentMessage["personaId"],
  requestId: string,
  lastMessage: MessageOne,
): MessageOne =>
  Object.assign({}, lastMessage, {
    id: `${RESPONSE_MESSAGE_TYPES.LOADING}-${ulid()}`,
    requestId,
    type: RESPONSE_MESSAGE_TYPES.IN_ANSWER,
    userRole: MESSAGE_USER_ROLE.ASSISTANT,
    content: "",
    channelId: lastMessage.channelId,
    questionId: lastMessage.questionId,
    suggestions: [],
    answerPersonaId: personaId,
    answerActions: [],
    pausedByLength: false,
  } as MessageOne);

export const createToolActionMessage = (
  personaId: string,
  lastMessage: MessageOne,
): MessageOne => {
  return Object.assign({}, lastMessage, {
    type: RESPONSE_MESSAGE_TYPES.IN_ANSWER,
    answerPersonaId: personaId,
  } as MessageOne);
};
