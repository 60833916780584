export type PostRequestMessageArgs<MessageData = any, CallbackArgs = any> = {
  message: RequestMessage<MessageData>;
  callback?: {
    name?: string;
    func: (...args: CallbackArgs[]) => void;
  };
};

export type RequestMessage<T = any> = {
  _id: string | number;
  type: RequestTypes;
  data: T;
};

export enum RequestTypes {
  /** Auth */
  accessToken = "get_access_token",
  refreshAccessToken = "refresh_access_token",
  login = "try_login",

  /** Messages */
  setPersona = "select_persona",
  setSttOperation = "set_stt_operation",

  /** App Permissions, Notifications */
  permission = "request_permission",
  checkPermission = "check_permission",
  pushNotification = "push_notification",
  isForeground = "is_foreground",

  /** App Info */
  openSettings = "open_settings",
  openProgramInfo$Windows = "open_program_info_window",
}

export type RequestCallbacks = {
  /** @todo requestId가 있어서 name이 필요할지 모르겟음 */
  callbackName?: string;
  callback: (...args: any[]) => void;
};

export type RequestNotification = {
  title: string;
  content: string;
  uri: string;
};
