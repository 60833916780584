import { v } from "~libs/valibot";
import { MESSAGE_STATUS$SERVER } from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @example
 ```json
 {
  "type": "message",
  "from": "group",
  "fromUserId": null,
  "group": "66967876e303453a6e23441d",
  "dataType": "json",
  "data": {
    "request_id": "3ca88dfc-437f-11ef-ab5a-568aa2b74468",
    "type": "saved_message"
  }
 }
 ```
 */
const SavedMessageSchema$Socket = v.pipe(
  v.object({
    type: v.literal(MESSAGE_STATUS$SERVER.SAVED_MESSAGE),
    request_id: MessagesSchemas$Api.Item.entries.request_id,
  }),

  v.transform((input) => ({
    requestId: input.request_id,
  })),
);

export const parseSavedMessage$Socket = (input: unknown) =>
  v.parse(SavedMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const SavedMessagePattern$Socket = getMessagePattern({
  type: MESSAGE_STATUS$SERVER.SAVED_MESSAGE,
});
