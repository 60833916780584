import { memo } from "react";

function _VotingUpSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="up-vote"
      width="18"
      height="18"
      fill="none"
    >
      <g
        className="icon_stroke"
        opacity=".7"
        stroke="#24354A"
        strokeWidth="1.5"
        strokeLinecap="round"
      >
        <path d="M12.928 16.557H2.505A.505.505 0 0 1 2 16.052V7.796c0-.279.226-.505.505-.505h2.332c.592 0 1.14-.31 1.445-.818l2.283-3.806A1.374 1.374 0 0 1 11.06 3.77l-.862 2.871a.505.505 0 0 0 .484.65h3.854a1.685 1.685 0 0 1 1.626 2.129l-1.608 5.897a1.685 1.685 0 0 1-1.626 1.241z" />
        <path d="M4.836 16.446V8" strokeLinecap="round" />
      </g>
    </svg>
  );
}

export const VotingUpSvg = memo(_VotingUpSvg);
