import type { MouseEventHandler } from "react";
import arrowIcon from "~assets/images/ico_arrow_right.png";
import { Button } from "~features/ui/button";
import { Thumbnail } from "~features/ui/thumbnail";
import { cn } from "~utils/class-names";
import styles from "./carousel-card.module.scss";

type CardType = "image" | "video" | "webSource"; //default: image

type CarouselCardProps = {
  cardType?: CardType;
  cardId?: string;
  cardUrl?: string;
  cardThumbnailSrc?: string;
  cardTitle?: string;
  cardFavicon?: string;
  cardSource?: string;
  cardUpdatedAt?: string;
  onClickButton?: MouseEventHandler;
};

export const CarouselCard: React.FC<CarouselCardProps> = ({
  cardType = "image",
  ...props
}) => {
  return (
    <div className={styles.carousel_card}>
      <a href={props.cardUrl} target="_blank" rel="noreferrer">
        {cardType !== "webSource" && (
          <Thumbnail
            thumbnailSrc={props.cardThumbnailSrc}
            isVideo={cardType === "video"}
          />
        )}
        <div
          className={cn({
            [styles.web_source_wrapper]: cardType === "webSource",
          })}
        >
          {props.cardTitle && (
            <p className={styles.card_title}>{props.cardTitle}</p>
          )}
          <p className={styles.card_info}>
            {props.cardFavicon && (
              <span className={styles.favicon}>
                <img src={props.cardFavicon} alt="favicon" />
              </span>
            )}
            {props.cardSource && <span>{props.cardSource}</span>}
            {Boolean(props.cardSource && props.cardUpdatedAt) && (
              <span>&nbsp;•&nbsp;</span>
            )}
            {props.cardUpdatedAt && <span>{props.cardUpdatedAt}</span>}
          </p>
        </div>
      </a>

      {cardType === "video" && (
        <Button
          overrideClass={styles.btn_summary}
          text="유튜브 요약하기"
          iconAfter={<img src={arrowIcon} alt="" />}
          size="small"
          fullWidth={true}
          color="gray"
          variant="line"
          onClick={props.onClickButton}
        />
      )}
    </div>
  );
};
