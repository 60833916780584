import { TIMES } from "~constants/times";
import { getYMD } from "./get-attributes-only";

const { floor } = Math;

export const KST_HOURS = TIMES.HOUR * 9;

/**
 * - API 데이터에 따라 KST 9시간 보정 필요
 *
 * - 문구 정책 참고
 * @see {@link https://day.js.org/docs/en/display/from-now}
 */
export const getFromNow = (
  date: string | number | null | undefined,
  adjust = 0,
): string => {
  const _date = parseDate(date);
  if (!_date) return "";

  const diff = new Date().getTime() - (_date.getTime() - adjust);

  if (diff < TIMES.SEC * 45) {
    return "방금 전";
  }

  if (diff < TIMES.SEC * 90) {
    return "1분 전";
  }

  if (diff < TIMES.HOUR) {
    return `${floor(diff / TIMES.MIN)}분 전`;
  }

  if (diff < TIMES.MIN * 90) {
    return "1시간 전";
  }

  if (diff < TIMES.DAY) {
    return `${floor(diff / TIMES.HOUR)}시간 전`;
  }

  const { year, month, day } = getYMD(_date);
  return `${year}. ${month}. ${day}.`;
};

const parseDate = (date: string | number | null | undefined) => {
  if (!date) return "";

  try {
    return new Date(date);
  } catch (error) {
    console.error("[getFromNow] date parsing error\n", error);
    return "";
  }
};
