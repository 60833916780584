import type { ModalContent } from "./modal-contents.types";

import {
  DownloadAppAosImage,
  DownloadAppIosImage,
  RegistrationCompleteImage,
  RegistrationImage,
} from "./images";

export const createWaitingListEnrollModalContents = (
  confirmClickCallback?: () => void,
): ModalContent => ({
  img: RegistrationImage,
  title:
    "현재 앨런을 사용하기 위해서는<br />사용자 등록 신청 및 대기가 필요합니다.",
  message: "사용자 등록이 완료되면 휴대폰 번호로 안내 메시지가 발송됩니다.",
  button: "사용자 등록 신청",
  confirmEvent: confirmClickCallback,
});

export const createWaitingListCompleteModalContents = (
  confirmClickCallback?: () => void,
): ModalContent => ({
  img: RegistrationCompleteImage,
  title: "사용자 등록 신청이 완료되었습니다.",
  message:
    "순차적으로 등록이 진행되니 조금만 기다려 주시기 바랍니다.\n사용자 등록이 완료되면 휴대폰 번호로 안내 메시지가 발송됩니다.",
  button: "확인",
  buttonBlack: true,
  confirmEvent: confirmClickCallback,
});

export const createAndroidAppDownloadModalContents = (
  confirmClickCallback?: () => void,
): ModalContent => ({
  img: DownloadAppAosImage,
  title: "QR 코드를 스캔하고 앱을 다운 받으세요!",
  button: "앱 다운로드",
  buttonBlack: true,
  confirmEvent: confirmClickCallback,
});

export const createIosAppDownloadModalContents = (
  confirmClickCallback?: () => void,
): ModalContent => ({
  img: DownloadAppIosImage,
  title: "QR 코드를 스캔하고 앱을 다운 받으세요!",
  button: "앱 다운로드",
  buttonBlack: true,
  confirmEvent: confirmClickCallback,
});
