import {
  type InferOutput,
  array,
  enum_,
  nullable,
  number,
  object,
  optional,
  pipe,
  string,
  transform,
  union,
} from "valibot";
import { RESPONSE_MESSAGE_TYPES } from "~clients/socket";
import {
  MESSAGE_ERROR_CONTENTS,
  MESSAGE_USER_ROLE,
} from "~features/messages/constants";
import { transformWebSources } from "~features/search/schemas/transform.utils";
import { v } from "~libs/valibot";

export enum SuggestionsLoadingStatus {
  LOADING = 0,
  SUCCESS = 1,
  FAILED = 2,
}

export const MessageCore$Server = object({
  /** message id */
  _id: string(),
  channel_id: string(),
  /** user 질문(request) id - 질문-답변 동일한 id */
  request_id: string(),
  user_id: string(),
  persona_id: string(),
  userRole: enum_(MESSAGE_USER_ROLE),
  content: string(),
  suggest_questions: array(string()),
  references: optional(
    array(
      object({
        number: union([number(), string()]),
        source: string(),
        title: string(),
        date: optional(nullable(string())),
        content: string(),
        thumbnail: optional(nullable(string())),
      }),
    ),
  ),

  // 아래는 당장 쓰이지 않는 속성들
  // created_at: string(),
  // updated_at: string(),
  // gpt_info: object({})
});

export const MessageResponse$Server = object({
  ...MessageCore$Server.entries,
  /** user request는 null, response는 answer_complete */
  type: nullable(enum_(RESPONSE_MESSAGE_TYPES)),
  actions: array(
    object({
      name: string(),
      arguments: object({}),
      speak: string(),
      created_at: string(),
    }),
  ),
  stop_reason: nullable(string()), // "stop", "length", "canceled"
});

const MessageListSchema$Server = object({
  messages: array(MessageResponse$Server),
  total: number(),
});

export const MessageListSchema = pipe(
  MessageListSchema$Server,
  transform((input) => {
    if (input.messages.length === 0) {
      return {
        messages: [],
        total: 0,
      };
    }

    return {
      messages: input.messages.map((m, idx) => {
        const sharedMessage = {
          id: m._id,
          content: m.content,
          requestId: m.request_id,
          questionId: m.request_id,
          channelId: m.channel_id,
          answerPersonaId: m.persona_id,
          sources: m.references
            ? transformWebSources(m.references)
            : { items: [], total: 0 },
        };

        if (m.userRole === MESSAGE_USER_ROLE.USER) {
          return Object.assign({}, sharedMessage, {
            type: m.type ?? RESPONSE_MESSAGE_TYPES.USER_MESSAGE,
            userRole: MESSAGE_USER_ROLE.USER,
            content: m.content,
            answerActions: [],
            suggestions: [],
            suggestionsLoading: SuggestionsLoadingStatus.SUCCESS,
            pausedByLength: false,
            isAnotherPersona: false,
          });
        }

        if (
          m.type === RESPONSE_MESSAGE_TYPES.CONTENT_FILTERED_ERROR ||
          m.stop_reason === RESPONSE_MESSAGE_TYPES.CONTENT_FILTERED_ERROR
        ) {
          return Object.assign({}, sharedMessage, {
            type: RESPONSE_MESSAGE_TYPES.ERROR,
            userRole: MESSAGE_USER_ROLE.ASSISTANT,
            content: MESSAGE_ERROR_CONTENTS.content_filter,
            answerActions: m.actions.map((act) => ({
              name: act.name,
              content: act.speak,
            })),
            suggestions: [],
            suggestionsLoading: SuggestionsLoadingStatus.SUCCESS,
            pausedByLength: false,
            isAnotherPersona: false,
          });
        }

        if (m.type === RESPONSE_MESSAGE_TYPES.CANCEL) {
          return Object.assign({}, sharedMessage, {
            type: RESPONSE_MESSAGE_TYPES.ERROR,
            userRole: MESSAGE_USER_ROLE.ASSISTANT,
            content: MESSAGE_ERROR_CONTENTS.canceled,
            answerActions: [],
            suggestions: [],
            suggestionsLoading: SuggestionsLoadingStatus.SUCCESS,
            pausedByLength: false,
            isAnotherPersona: false,
          });
        }

        const isLast = idx === input.messages.length - 1;
        /** @todo type 판단 로직 함수로 분리 */
        const isInAnswer =
          m.type === RESPONSE_MESSAGE_TYPES.IN_ANSWER ||
          m.type === RESPONSE_MESSAGE_TYPES.SAVED_MESSAGE ||
          m.type === RESPONSE_MESSAGE_TYPES.START_AGENT;
        const messageType =
          typeof m.type === "string"
            ? isInAnswer
              ? RESPONSE_MESSAGE_TYPES.IN_ANSWER
              : m.type
            : RESPONSE_MESSAGE_TYPES._DUMMY;

        return Object.assign({}, sharedMessage, {
          type: messageType,
          userRole: m.userRole,
          content: m.content,
          isAnotherPersona: false,
          suggestions: isLast ? m.suggest_questions : [],
          suggestionsLoading:
            isLast &&
            m.suggest_questions.length === 0 &&
            m.type === RESPONSE_MESSAGE_TYPES.FINISH_ANSWER
              ? SuggestionsLoadingStatus.LOADING
              : SuggestionsLoadingStatus.SUCCESS,
          pausedByLength: isLast && m.stop_reason === "length",
        });
      }),
      total: input.total,
    };
  }),
);

export type MessageList = InferOutput<typeof MessageListSchema>;

export type MessageOne = MessageList["messages"][`${number}`];

export const transformResponse = (response: unknown) => {
  const parseResult = v.safeParse(MessageListSchema, response, {
    abortEarly: true,
  });
  if (!parseResult.success) {
    console.error(parseResult.issues);
    return {
      messages: [],
      total: 0,
    };
  }

  return parseResult.output;
};
