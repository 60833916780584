import { useQuery } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";

/**
  * feedbacks type
  *
  * @example
  * - url {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v1/thumbs_messages}
  *
  ```json
  [
    {
      "_id": "64c9f678d4c34048a4d0ab69",
      "message": "질문 이해 못함",
      "enabled": true
    },
    {
      "_id": "64c9f6b4d4c34048a4d0ab6a",
      "message": "부정확한 답변",
      "enabled": true
    },
    {
      "_id": "64c9f6ded4c34048a4d0ab6b",
      "message": "불쾌감 유발",
      "enabled": true
    }
  ]
  ```
  */
type RatingMessages = {
  _id: string;
  message: string;
  enabled: boolean;
}[];

export const useQueryRatingMessages = () => {
  return useQuery({
    queryKey: ["chat", "message", "rating"],
    queryFn: fetchRatingMessages,
    refetchInterval: false,
    staleTime: 5 * 60 * 1_000,
    retry: 2,
    refetchOnWindowFocus: true,
    placeholderData: [],
  });
};

const fetchRatingMessages = async () =>
  await KyClient.get("thumbs_messages").json<RatingMessages>();
