import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import { PromptContext, type PromptContextStates } from "./context";

type PromptProps = PropsWithChildren<{
  context: PromptContextStates;
  className?: string;
}>;

export function PromptRoot({ context, className, children }: PromptProps) {
  return (
    <PromptContext.Provider value={context}>
      <div className={cn("", className)}>{children}</div>
    </PromptContext.Provider>
  );
}
