import type { MouseEvent, ReactNode } from "react";
import { cn } from "~utils/class-names";

type VotingButtonProps = {
  upOrDown: "up" | "down";
  Image: ReactNode;
  className?: string;
  onClickVotingButton: () => void;
};

/**
 * @Todo common.scss에서 스타일 분리
 */
export function VotingButton({
  Image,
  className = "",
  onClickVotingButton,
}: VotingButtonProps) {
  const onClick = (e: MouseEvent) => {
    e.preventDefault();
    onClickVotingButton();
  };

  return (
    <button type="button" className={cn(className)} onClick={onClick}>
      {Image}
    </button>
  );
}
