import type { ReactNode } from "react";
import { cn } from "~utils/class-names";

type CopyButtonProps = {
  onClickCopyButton: () => void;
  Image: ReactNode;
  className?: string;
};

/** @todo common.scss에서 스타일 분리 */
export function CopyButton({
  className = "",
  onClickCopyButton,
  Image,
}: CopyButtonProps) {
  const onClick = () => {
    onClickCopyButton();
  };

  return (
    <button type="button" onClick={onClick} className={cn(className)}>
      {Image}
    </button>
  );
}
