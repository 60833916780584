import type { ReactNode } from "react";
import { cn } from "~utils/class-names";
import styles from "./main.module.scss";

const Main = ({
  children,
  isHome,
}: {
  children: ReactNode;
  isHome?: boolean;
}) => {
  return (
    <main className={cn(styles.main_wrapper, { [styles.home]: isHome })}>
      {children}
    </main>
  );
};

export default Main;
