import {
  transformImages,
  transformSteps,
  transformVideos,
  transformWebSources,
} from "~features/search/schemas/transform.utils";
import { Status } from "~features/util-types/status";
import { v } from "~libs/valibot";
import {
  MESSAGE_STATUS$SERVER,
  MESSAGE_STOP_REASON$SERVER,
} from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @example
 ```json
 {
  "type": "message",
  "from": "group",
  "fromUserId": null,
  "group": "66967876e303453a6e23441d",
  "dataType": "json",
  "data": {
    "id": "669693b41fc473b188800f6d",
    "request_id": "472415d0-4389-11ef-a4a5-a61d9fe6d6bc",
    "persona_id": "669191ccfa9cf2010f533369",
    "channel_id": "66967876e303453a6e23441d",
    "user_id": "65af73b6583a64a91bd6e002",
    "client_id": "7b96ccc8-f019-45e0-82df-62f40aa26553",
    "user_role": "assistant",
    "type": "answer_complete",
    "stop_reason": "stop",
    "content": "현재 GitHub Copilot은 주로 OpenAI의 Codex 모델을 사용하고 있지만, **GPT-4가 적용된 GitHub Copilot X**가 출시될 예정입니다. 이는 GPT-4 모델을 기반으로 한 새로운 버전의 Copilot으로, 더 강력한 기능과 성능을 제공할 것으로 기대됩니다[(출처11)](https://revf.tistory.com/277)[(출처12)](https://namu.wiki/w/GPT-4).\n\n따라서, 현재 GitHub Copilot에서 직접적으로 ChatGPT-4를 사용할 수는 없지만, 곧 출시될 Copilot X에서 GPT-4의 기능을 활용할 수 있을 것입니다.",
    "actions": [
      {
        "name": "search_web",
        "arguments": {},
        "speak": "관련 최신 정보를 확인하고 있어요.",
        "created_at": "2024-07-16T15:37:29.893207Z"
      }
    ],
    
    "order": null,
    "created_at": null,
    "updated_at": "2024-07-16T15:37:29.893242Z"
    }
  }
  ```
 */
export const FinishAnswerMessageSchema$Socket = v.pipe(
  v.object({
    type: v.literal(MESSAGE_STATUS$SERVER.ANSWER_COMPLETE),
    stop_reason: v.literal(MESSAGE_STOP_REASON$SERVER.STOP),
    id: MessagesSchemas$Api.Item.entries._id,
    request_id: MessagesSchemas$Api.Item.entries.request_id,
    actions: MessagesSchemas$Api.Item.entries.actions,
    content: MessagesSchemas$Api.Item.entries.content,
    references: MessagesSchemas$Api.Item.entries.references,
    images: MessagesSchemas$Api.Item.entries.images,
    videos: MessagesSchemas$Api.Item.entries.videos,
  }),

  v.transform((input) => ({
    id: input.id,
    requestId: input.request_id,
    toolSteps: transformSteps(input.actions, Status.SUCCESS),
    content: input.content,
    sources: transformWebSources(input.references),
    images: transformImages(input.images),
    videos: transformVideos(input.videos),
  })),
);

export const parseFinishAnswerMessage$Socket = (input: unknown) =>
  v.parse(FinishAnswerMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const FinishAnswerMessagePattern$Socket = getMessagePattern({
  type: MESSAGE_STATUS$SERVER.ANSWER_COMPLETE,
  stop_reason: MESSAGE_STOP_REASON$SERVER.STOP,
});
