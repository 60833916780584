import type { MouseEventHandler } from "react";
import ArrowIcon from "~assets/images/ico_arrow_down.png";
import { Button } from "~features/ui/button";
import { cn } from "~utils/class-names";
import styles from "./accordion-button.module.scss";

type AccordionButtonProps = {
  isOpen?: boolean;
  overrideClass?: string;
  onClick?: MouseEventHandler;
};

const AccordionButton: React.FC<AccordionButtonProps> = ({
  isOpen,
  overrideClass,
  onClick,
}) => {
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (onClick) onClick(event);
  };

  return (
    <Button
      onClick={handleClick}
      overrideClass={cn(
        styles.btn_accordion,
        {
          [styles.on]: isOpen,
        },
        overrideClass,
      )}
      type="onlyIcon"
      size="medium"
      aria-label="accordion button"
      iconBefore={<img src={ArrowIcon} alt="toggle button" />}
    />
  );
};

export default AccordionButton;
