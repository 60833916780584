import type { KeyboardEvent, MouseEvent, SyntheticEvent } from "react";

type CustomHandlerArgs<EV> = {
  handler?: (e: EV) => void;
  preventDefault?: boolean;
  stopPropagation?: boolean;
};

/** @todo preventDefault 불가능한 이벤트 */
const PREVENT_DEFAULT_BLACK_SET = new Set(["touchmove", "wheel"]);

const DEFAULT_OPTIONS: CustomHandlerArgs<SyntheticEvent> = {
  preventDefault: true,
  stopPropagation: true,
} as const;

export const createEventHandler =
  <EV extends SyntheticEvent = MouseEvent | KeyboardEvent>(
    options: CustomHandlerArgs<EV> = DEFAULT_OPTIONS,
  ) =>
  (e: EV) => {
    (options.preventDefault ?? DEFAULT_OPTIONS.preventDefault) &&
      !PREVENT_DEFAULT_BLACK_SET.has(e.type) &&
      e.preventDefault();
    (options.stopPropagation ?? DEFAULT_OPTIONS.stopPropagation) &&
      e.stopPropagation();
    return options.handler ? options.handler(e) : undefined;
  };
