import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./completions.module.scss";
import { CompletionContext, type CompletionContextStates } from "./context";

type PromptCompletionsProps = PropsWithChildren<{
  context: CompletionContextStates;
  className?: string;
}>;

export function CompletionsRoot({
  context,
  className,
  children,
}: PromptCompletionsProps) {
  return (
    <CompletionContext.Provider value={context}>
      <ul className={cn(styles.completions, className)}>{children}</ul>
    </CompletionContext.Provider>
  );
}
