import { cn } from "~utils/class-names";
import styles from "./button.module.scss";

type ButtonType = "button" | "onlyIcon";
type ButtonVariant = "default" | "line" | "radius" | "naked";
type ButtonSize = "default" | "small" | "medium" | "large";
type ButtonColor = "primary" | "black" | "white" | "gray";

type ButtonProps = {
  type?: ButtonType;
  size?: ButtonSize;
  variant?: ButtonVariant;
  color?: ButtonColor;
  customWidth?: string;
  fullWidth?: boolean;
  isShadow?: boolean;

  text?: string;
  iconBefore?: React.ReactElement;
  iconAfter?: React.ReactElement;

  isDisabled?: boolean;
  onClick?: React.MouseEventHandler;
  onKeyDown?: React.KeyboardEventHandler;
  buttonId?: string;
  overrideClass?: string;
  href?: string;
};

const Button: React.FC<ButtonProps> = ({
  type = "button",
  size = "",
  variant = "",
  color = "white",
  customWidth,
  fullWidth,
  isShadow,
  text,
  iconBefore,
  iconAfter,
  isDisabled,
  buttonId,
  overrideClass,

  ...props
}) => {
  const className = cn(
    size && styles[size],
    variant && styles[variant],
    styles[color],
    {
      [styles.isShadow]: isShadow,
      [styles.full]: fullWidth,
    },
    styles.btn,
    overrideClass,
  );

  const content = (
    <>
      {iconBefore && <span className={cn(styles.icon)}>{iconBefore}</span>}
      {text}
      {iconAfter && <span className={cn(styles.icon)}>{iconAfter}</span>}
    </>
  );

  switch (type) {
    case "onlyIcon":
      return (
        <button
          type="button"
          id={buttonId}
          onClick={props.onClick}
          onKeyDown={props.onKeyDown}
          className={cn(
            styles.only_icon,
            styles[size],
            styles[variant],
            {
              [styles.isShadow]: isShadow,
            },
            overrideClass,
          )}
          disabled={isDisabled}
          style={
            customWidth ? { width: customWidth, height: "auto" } : undefined
          }
        >
          {iconBefore}
        </button>
      );

    case "button":
    default:
      return props.href ? (
        <a
          href={props.href}
          id={buttonId}
          onClick={props.onClick}
          onKeyDown={props.onKeyDown}
          className={className}
          style={
            customWidth ? { width: customWidth, height: "auto" } : undefined
          }
        >
          {content}
        </a>
      ) : (
        <button
          {...props}
          type="button"
          id={buttonId}
          onClick={props.onClick}
          onKeyDown={props.onKeyDown}
          className={className}
          disabled={isDisabled}
          style={
            customWidth ? { width: customWidth, height: "auto" } : undefined
          }
        >
          {content}
        </button>
      );
  }
};

export default Button;
