import Markdown from "markdown-to-jsx";
import { Fragment } from "react";
import { MarkdownCode, MarkdownCodeBlock } from "./code";
import {
  MarkdownHeading1,
  MarkdownHeading2,
  MarkdownHeading3,
  MarkdownHeading4,
  MarkdownHeading5,
  MarkdownHeading6,
} from "./headings";
import { MarkdownImage } from "./image";
import { MarkdownLink } from "./link";
import { MarkdownListItem, MarkdownOL, MarkdownUL } from "./lists";
import { MarkdownParagraph } from "./paragraph";
import { MarkdownTable } from "./table";

type MarkdownRendererProps = {
  contents: string;
  className?: string;
};

const getRefinedContents = (contents: string) =>
  contents
    .replace(/(\S+)\n(\S+)/g, "$1\n\n$2")
    .replace(/(\S+)\n(\s+)?\`\`\`/g, "$1\n\n$2```")
    .trim();

export function MarkdownRenderer({ contents }: MarkdownRendererProps) {
  const refinedContents = getRefinedContents(contents);

  return (
    <Markdown
      options={{
        wrapper: Fragment,
        slugify: (str) => str,
        forceBlock: true,
        disableParsingRawHTML: true,
        overrides: {
          p: { component: MarkdownParagraph },
          h1: { component: MarkdownHeading1 },
          h2: { component: MarkdownHeading2 },
          h3: { component: MarkdownHeading3 },
          h4: { component: MarkdownHeading4 },
          h5: { component: MarkdownHeading5 },
          h6: { component: MarkdownHeading6 },
          ul: {
            component: MarkdownUL,
          },
          ol: {
            component: MarkdownOL,
          },
          li: {
            component: MarkdownListItem,
          },
          a: {
            component: MarkdownLink,
          },
          pre: {
            component: MarkdownCodeBlock,
          },
          code: {
            component: MarkdownCode,
          },
          kbd: { component: MarkdownCode },
          table: {
            component: MarkdownTable,
          },
          img: {
            component: MarkdownImage,
          },
        },
      }}
    >
      {refinedContents}
    </Markdown>
  );
}
