import classNames from "classnames";
import Button from "~features/ui/button/button";
import styles from "./action-button.module.scss";

type ActionButtonProps = {
  iconSrc?: string;
  isOff?: boolean;
  isOn?: boolean;
  onClick?: React.MouseEventHandler;
};

const ActionButton: React.FC<ActionButtonProps> = (props) => {
  return (
    <Button
      {...props}
      overrideClass={classNames(styles.btn_action, {
        [styles.off]: props.isOff,
        [styles.on]: props.isOn,
      })}
      type="onlyIcon"
      size="medium"
      iconBefore={<img src={props.iconSrc} alt="" />}
      onClick={props.onClick}
    />
  );
};

export default ActionButton;
