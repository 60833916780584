import type { FC, MouseEventHandler } from "react";
import { createPortal } from "react-dom";
import SttIcon from "~assets/images/ico_stt.png";
import SttPauseIcon from "~assets/images/ico_stt_pause.png";
import {
  type NativeBridgeAndroid,
  useNativeBridge,
} from "~clients/native-bridge";
import { useSttMode } from "~components/chat/message-input";
import { Button } from "~features/ui/button";
import { SttLottie } from "~features/ui/lottie/stt";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./stt-mic-button.module.scss";

type SttMicButtonProps = {
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

export const SttMicButton: FC<SttMicButtonProps> = ({ onClick, ...props }) => {
  const { nativeBridge } = useNativeBridge();
  const { isSttMode, updateIsSttMode } = useSttMode();

  const handleClick = createEventHandler({
    handler: (_event) => {
      const nextIsSttMode = !isSttMode;
      updateIsSttMode(nextIsSttMode);
      (nativeBridge as NativeBridgeAndroid)?.requestUpdateSttState(
        nextIsSttMode,
      );
    },
  });

  const deactivateSttMode = () => {
    if (isSttMode) {
      updateIsSttMode(false);
      (nativeBridge as NativeBridgeAndroid)?.requestUpdateSttState(false);
    }
  };

  return (
    <>
      <Button
        onClick={handleClick}
        overrideClass={styles.btn_stt}
        type="onlyIcon"
        aria-label="STT button"
        iconBefore={
          <img src={isSttMode ? SttPauseIcon : SttIcon} alt="stt button" />
        }
        {...props}
      />

      {isSttMode &&
        createPortal(
          <section
            onClick={deactivateSttMode}
            className="absolute bottom-0 z-50 w-screen h-screen bg-alan-neutral-5/20"
          >
            <SttLottie onClickSttLottie={deactivateSttMode} />
          </section>,
          document.body,
        )}
    </>
  );
};
