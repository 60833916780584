import { useQueryClient } from "@tanstack/react-query";
import { useCallback, useEffect, useMemo, useRef } from "react";
import useWebSocket from "react-use-websocket";
import { P } from "ts-pattern";
import { useNativeBridge } from "~clients/native-bridge";
import { createSocketMessageHandler } from "~clients/socket";
import { WEBSOCKET_PROTOCOL } from "~clients/socket/constants";
import { useQuerySocketUrl } from "~clients/socket/use-query-socket-url";
import {
  MESSAGE_ERROR_CONTENTS,
  MESSAGE_STATUS,
} from "~features/messages/constants";
import { useTimeoutError } from "~features/messages/hooks";
import { SocketMessageParsers } from "~features/messages/schemas";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { Status } from "~features/util-types/status";
import { cloneObject } from "~utils/clone-object";
import type { SearchResult } from "./schemas";
import { RESULT_MESSAGE_PATTERNS } from "./schemas";
import {
  findTargetResultByMessageId,
  findTargetResultByRequestId,
  findTargetResultStatusInit,
} from "./utils.socket";

const THROTTLE_MILLISECOND = 64;
const INIT_ARRAY_LENGTH = 1_500;

/** @todo Refactor - 유틸 함수로 분리 */
const REFERENCES_REGEXP_IN_ANSWER =
  /(\[\^[0-9]{1,9}\^\]|\[(?:\^(?:[0-9]{1,9}(?:\^\]?)?)?)?$)/g;
const FOOTNOTE_REGEXP_IN_ANSWER =
  /(\[\^[0-9]{1,9}\^\](?:\:.*\n?)?|\[(?:\^(?:[0-9]{1,9}(?:\^\]?)?)?)?$)/g;

/** @todo 코어로직 useSocketV2로 분리 */
export const useSocketSearch = (channelId: string) => {
  const queryClient = useQueryClient();
  const queryKey = useMemo(
    () => QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId),
    [channelId],
  );

  const { data: channelSocketUrl, isSuccess } = useQuerySocketUrl();
  const { lastJsonMessage: lastData, sendJsonMessage } = useWebSocket(
    channelSocketUrl ?? "",
    {
      share: true,
      protocols: WEBSOCKET_PROTOCOL,

      /** @todo message 직접 보내면 연결 끊어짐? */
      heartbeat: false,

      onOpen: (_event) => {
        // logger.debug("[useSocket.onOpen]", { channelId, channelSocketUrl });
      },
      onError: (_event) => {
        console.error("[useSocket.onError]\n", { channelId, channelSocketUrl });
      },
      onClose: (_event) => {
        // logger.debug("[useSocket.onClose]", { channelId, channelSocketUrl });
      },
    },
    isSuccess && channelSocketUrl.length > 0,
  );

  const tokensQueue = useRef<string[]>([]);
  const prevMessageContentRef = useRef("");

  const { nativeBridge } = useNativeBridge();

  const handleSocketMessage = useCallback(
    createSocketMessageHandler({ channelId, sendJsonMessage }),
    [channelId],
  );

  const optimisticUpdateResults = useCallback(
    (
      updateFn: (
        newResults: SearchResult.Page,
        prevResults: SearchResult.Page,
      ) => void,
    ) => {
      queryClient.setQueryData<SearchResult.Page>(queryKey, (prevResults) => {
        if (!prevResults) {
          return prevResults;
        }

        const newResults = cloneObject(prevResults);
        updateFn(newResults, prevResults);
        return newResults;
      });
    },
    [queryKey, queryClient],
  );

  const { debouncedTimeoutHandler } = useTimeoutError();

  /** @todo 공통 로직 분리 */
  const createNotificationMessage = useCallback(
    (messageId: string) => {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set("message_id", messageId);
      searchParams.delete("platform");

      return {
        title: "검색이 완료됐어요!",
        content: "지금 검색 결과를 확인하세요.",
        uri: `/search/${channelId}?${searchParams.toString()}`,
      };
    },
    [channelId],
  );

  const resetStates = useCallback(() => {
    queryClient.invalidateQueries({ queryKey });
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.AUTH.USER });
    prevMessageContentRef.current = "";
    tokensQueue.current = [];
  }, [queryClient, queryKey]);

  useEffect(() => {
    handleSocketMessage(lastData)
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.IN_ANSWER], ({ data }) => {
        const { order, token, requestId } =
          SocketMessageParsers[MESSAGE_STATUS.IN_ANSWER](data);
        tokensQueue.current[order] = token;
        optimisticUpdateResults((newResults, _prevResults) => {
          /** @todo 현재 inAnswer message 상태 저장 로직 분리 */
          const targetResult = findTargetResultByRequestId(
            newResults.items,
            requestId,
          );
          if (!targetResult || targetResult.status === Status.ERROR) {
            return;
          }

          targetResult.content = prevMessageContentRef.current
            .concat(tokensQueue.current.join(""))
            .replace(FOOTNOTE_REGEXP_IN_ANSWER, "")
            .replace(REFERENCES_REGEXP_IN_ANSWER, "");
          targetResult.status = Status.LOADING;
        });

        debouncedTimeoutHandler();
      })
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.SAVED], ({ data }) => {
        const { requestId } = SocketMessageParsers[MESSAGE_STATUS.SAVED](data);
        tokensQueue.current = new Array(INIT_ARRAY_LENGTH).fill("");

        optimisticUpdateResults((newResults, prevResults) => {
          const targetResult = findTargetResultStatusInit(
            newResults.items,
            requestId,
          );
          if (!targetResult) {
            console.debug(
              "[useSocketSearch.handleSocketMessage.saved] not found targetResult\n",
              { requestId, prevResults },
            );
            return newResults;
          }
          if (targetResult.status === Status.ERROR) {
            return prevResults;
          }

          targetResult.requestId ||= requestId;
          targetResult.status = Status.LOADING;
        });

        debouncedTimeoutHandler();
      })
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.START_AGENT], ({ data }) => {
        const { requestId } =
          SocketMessageParsers[MESSAGE_STATUS.START_AGENT](data);

        optimisticUpdateResults((newResults, prevResults) => {
          const targetResult = findTargetResultStatusInit(
            newResults.items,
            requestId,
          );
          if (!targetResult) {
            console.debug(
              "[useSocketSearch.handleSocketMessage.start-agent] not found targetResult\n",
              { requestId, prevResults },
            );
            return newResults;
          }
          if (targetResult.status === Status.ERROR) {
            return prevResults;
          }

          targetResult.requestId ||= requestId;
          targetResult.status = Status.LOADING;
        });

        debouncedTimeoutHandler();
      })
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.CONTINUE_START_AGENT],
        ({ data }) => {
          const { requestId } =
            SocketMessageParsers[MESSAGE_STATUS.CONTINUE_START_AGENT](data);

          optimisticUpdateResults((newResults, prevResults) => {
            const targetResult = findTargetResultByRequestId(
              newResults.items,
              requestId,
            );
            if (!targetResult) {
              console.debug(
                "[useSocketSearch.handleSocketMessage.continue-start-agent] not found targetResult\n",
                { requestId, prevResults },
              );
              return;
            }
            if (targetResult.status === Status.ERROR) {
              return prevResults;
            }

            targetResult.status = Status.LOADING;
          });

          debouncedTimeoutHandler();
        },
      )
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.TOOL_ACTION], ({ data }) => {
        const { toolSteps, requestId } =
          SocketMessageParsers[MESSAGE_STATUS.TOOL_ACTION](data);

        optimisticUpdateResults((newResults, prevResults) => {
          const targetResult = findTargetResultStatusInit(
            newResults.items,
            requestId,
          );
          if (!targetResult) {
            console.debug(
              "[useSocketSearch.handleSocketMessage.tool-action] not found targetResult\n",
              { requestId, prevResults },
            );
            return;
          }
          if (targetResult.status === Status.ERROR) {
            return prevResults;
          }

          Object.assign(targetResult, {
            status: Status.LOADING,
            toolSteps:
              /** @description action 순서와 다르게 메시지 오는 경우 예외처리 */
              targetResult.toolSteps.length > toolSteps.length
                ? targetResult.toolSteps
                : toolSteps,
          } as Partial<SearchResult.Item>);
        });

        debouncedTimeoutHandler();
      })
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.FINISH_ANSWER],
        ({ data }) => {
          const { requestId, content, images, videos, sources, id } =
            SocketMessageParsers[MESSAGE_STATUS.FINISH_ANSWER](data);

          optimisticUpdateResults((newResults, prevResults) => {
            const targetResult = findTargetResultByRequestId(
              newResults.items,
              requestId,
            );
            if (!targetResult) {
              return;
            }
            if (targetResult.status === Status.ERROR) {
              return prevResults;
            }

            Object.assign(targetResult, {
              id,
              status: Status.SUCCESS,
              content,
              sources,
              images,
              videos,
            } as Partial<SearchResult.Item>);

            resetStates();
            nativeBridge?.requestNotification(createNotificationMessage(id));
          });

          debouncedTimeoutHandler.cancel();
        },
      )
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.PAUSED_BY_LENGTH],
        ({ data }) => {
          const { requestId, content, images, videos, sources, id } =
            SocketMessageParsers[MESSAGE_STATUS.PAUSED_BY_LENGTH](data);

          optimisticUpdateResults((newResults, prevResults) => {
            const targetResult = findTargetResultByRequestId(
              newResults.items,
              requestId,
            );
            if (!targetResult) {
              return;
            }
            if (targetResult.status === Status.ERROR) {
              return prevResults;
            }

            Object.assign(targetResult, {
              status: Status.SUCCESS,
              content,
              sources,
              images,
              videos,
            } as Partial<SearchResult.Item>);

            resetStates();
            nativeBridge?.requestNotification(createNotificationMessage(id));
          });

          debouncedTimeoutHandler.cancel();
        },
      )
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.RELATED_QUESTIONS],
        ({ data }) => {
          const { messageId, questions } =
            SocketMessageParsers[MESSAGE_STATUS.RELATED_QUESTIONS](data);

          optimisticUpdateResults((newResults, prevResults) => {
            const targetResult = findTargetResultByMessageId(
              newResults.items,
              messageId,
            );
            if (!targetResult) {
              return;
            }
            if (targetResult.status === Status.ERROR) {
              return prevResults;
            }

            targetResult.questions = questions;
          });

          debouncedTimeoutHandler.cancel();
        },
      )
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.CANCEL], ({ data }) => {
        const { requestId } = SocketMessageParsers[MESSAGE_STATUS.CANCEL](data);

        optimisticUpdateResults((newResults, _prevResults) => {
          const targetResult = findTargetResultByRequestId(
            newResults.items,
            requestId,
          );
          if (!targetResult) {
            return;
          }

          Object.assign(targetResult, {
            status: Status.ERROR,
            content: MESSAGE_ERROR_CONTENTS.canceled,
          } as Partial<SearchResult.Item>);
          resetStates();
        });

        debouncedTimeoutHandler.cancel();
      })
      .with(
        RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.ERROR_CONTENT_FILTERED],
        ({ data }) => {
          const { requestId } =
            SocketMessageParsers[MESSAGE_STATUS.ERROR_CONTENT_FILTERED](data);

          optimisticUpdateResults((newResults, _prevResults) => {
            const targetResult = findTargetResultByRequestId(
              newResults.items,
              requestId,
            );
            if (!targetResult) {
              return;
            }

            Object.assign(targetResult, {
              status: Status.ERROR,
              content: MESSAGE_ERROR_CONTENTS.content_filter,
            } as Partial<SearchResult.Item>);
            resetStates();
          });

          debouncedTimeoutHandler.cancel();
        },
      )
      .with(RESULT_MESSAGE_PATTERNS[MESSAGE_STATUS.ERROR], ({ data }) => {
        const { requestId } =
          SocketMessageParsers[MESSAGE_STATUS.ERROR_CONTENT_FILTERED](data);

        optimisticUpdateResults((newResults, _prevResults) => {
          const targetResult = findTargetResultByRequestId(
            newResults.items,
            requestId,
          );
          if (!targetResult) {
            return;
          }

          Object.assign(targetResult, {
            status: Status.ERROR,
            content: MESSAGE_ERROR_CONTENTS.error,
          } as Partial<SearchResult.Item>);
          resetStates();
        });
        debouncedTimeoutHandler.cancel();
      })
      /** @todo 없겠지만 확인필요 */
      .with(
        {
          data: {
            user: {
              user_role: P.not("assistant"),
            },
          },
        },
        ({ data }) => {
          console.debug(
            "[useSocketSearch.handleSocketMessage] not assistant message\n",
            data,
          );
        },
      )
      .otherwise(() => {
        console.debug(
          "[useSocketSearch.handleSocketMessage] not matched message\n",
          lastData,
        );
      });
  }, [
    lastData,
    handleSocketMessage,
    optimisticUpdateResults,
    resetStates,
    createNotificationMessage,
    nativeBridge,
    debouncedTimeoutHandler,
  ]);

  return {
    //
  };
};
