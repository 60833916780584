import { useMutation, useQueryClient } from "@tanstack/react-query";
import { match } from "ts-pattern";
import {
  KyClient,
  STATUS_CODES,
  isKyHttpError,
  parseKyHttpError,
} from "~clients/fetch";
import {
  type NativeBridgeCore,
  PermissionNames,
  useNativeBridge,
} from "~clients/native-bridge";
import { useUser } from "~features/auth";
import { useRespondingStatus } from "~features/messages/hooks/use-responding-status";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { setDefaultErrorToast } from "~features/toast";
import { useUserAgent } from "~features/user-agent";
import {
  getLocalStorageValue,
  setLocalStorageValue,
} from "~utils/localstorage-value";
import type { Messages } from "./message-list";
import {
  createLoadingMessage,
  createNewUserMessage,
} from "./message-list/create-message.utils";
import type { SendMessageRequestsWithoutUserId } from "./send-message-request.types";

export const useMutationSendMessage = () => {
  const { user } = useUser();
  const queryClient = useQueryClient();
  const { setIsPending } = useRespondingStatus();

  const { isIos } = useUserAgent();
  const { nativeBridge } = useNativeBridge();

  return useMutation({
    mutationFn: sendUserMessage(user.id),

    onMutate: async ({ channelId, message, personaId }) => {
      setIsPending();
      const queryKey = QUERY_KEYS.SUMMARY.getYoutubeChatQueryKey(channelId);
      await queryClient.cancelQueries({ queryKey });

      queryClient.setQueryData<Messages>(queryKey, (prevMessages) => {
        const newMessages = [
          createNewUserMessage({
            channelId,
            personaId,
            message,
          }),
          createLoadingMessage({
            channelId,
            personaId,
          }),
        ];

        return prevMessages
          ? {
              messages: prevMessages.messages.concat(newMessages),
              total: prevMessages.total + 1,
            }
          : {
              messages: newMessages,
              total: 2,
            };
      });

      if (nativeBridge && !isIos) {
        requestNativeNotificationPermission(nativeBridge);
      }

      return {
        prevMessages: queryClient.getQueryData(queryKey) ?? [],
      };
    },

    onError: async (error, { channelId }, context) => {
      if (isKyHttpError(error)) {
        const { status, response } = (await parseKyHttpError<unknown>(error))
          .data;
        match(status).with({ code: STATUS_CODES.RATE_LIMIT }, () => {
          setDefaultErrorToast(response as string);
          queryClient.setQueryData(
            QUERY_KEYS.SUMMARY.getYoutubeChatQueryKey(channelId),
            context?.prevMessages ?? [],
          );
        });
      }
    },
  });
};

const sendUserMessage =
  (userId: string) =>
  async ({
    message,
    personaId,
    channelId,
  }: SendMessageRequestsWithoutUserId) => {
    return await KyClient.post(`channels/${channelId}/messages`, {
      json: {
        channel_id: channelId,
        content: message,
        user_id: userId,
        persona_id: personaId,
      },
      hooks: {
        beforeError: [
          (err) => {
            console.error(err);
            return err;
          },
        ],
      },
    });
  };

const STORAGE_KEY = "gepeto.alreadyPermissionToPush";

const requestNativeNotificationPermission = async (
  nativeBridge: NativeBridgeCore,
) => {
  if (nativeBridge.hasPermission(PermissionNames.pushNotification)) return;

  const hasRequestedPermission = getLocalStorageValue<boolean | undefined>(
    STORAGE_KEY,
  );
  if (typeof hasRequestedPermission !== "undefined") return;

  nativeBridge
    .requestPermissionAsync({
      permission: PermissionNames.pushNotification,
      message: "답장이 왔을때 알려드릴까요?",
    })
    .then(({ granted }) => {
      setLocalStorageValue(STORAGE_KEY, granted);
    });
};
