import { NativeBridgeCore } from "./_core";
import { RequestTypes } from "./request.types";

declare global {
  interface Window {
    webkit?: {
      messageHandlers: {
        iOSInterface: {
          postMessage: (args?: any) => void;
        };
      };
    };
  }
}

export class NativeBridgeIos extends NativeBridgeCore {
  constructor() {
    super();
    if (!this.bridge) {
      this.setupBridgeInterface();
    }
  }

  protected setupBridgeInterface(): void {
    this.bridge = window.webkit?.messageHandlers?.iOSInterface?.postMessage;

    if (this.bridge && this.requestQueue.length > 0) {
      this.runQueueMessages();
    }
  }

  protected sendMessageToBridge(message: string) {
    window.webkit?.messageHandlers?.iOSInterface?.postMessage(message);
  }

  /**
   * @description
   * - ios는 푸쉬 알림 요청X - 왠지는 모르겠음
   */
  async requestNotification() {
    return;
  }

  requestUpdateSttState(isSttMode: boolean) {
    const requestMessage = this.createRequestMessage({
      type: RequestTypes.setSttOperation,
      data: isSttMode,
    });

    this.postRequestMessage({ message: requestMessage });
  }
}
