import { MessageAssistantSuggestion } from "./message-assistant-suggestion";
import styles from "./message-assistant-suggestion.module.scss";

export function MessageAssistantSuggestionLoading() {
  return (
    <MessageAssistantSuggestion
      suggestion="추천 질문 생성 중"
      className={styles.loading}
    />
  );
}
