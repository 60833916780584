import type { ReactElement } from "react";
import { useCopyButton } from "~features/clipboard";
import { DEFAULT_MESSAGES, setChatMessageCopyToast } from "~features/toast";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { getHljsCode } from "./code.utils";

import "highlight.js/styles/vs2015.css";
import styles from "./code.module.scss";

type MarkdownCodeProps = {
  className?: string;
  children?: {
    props: { className?: string; children?: string } | ReactElement;
  };
};

export function MarkdownCode({ className = "", children }: MarkdownCodeProps) {
  return (
    <code className={cn(styles.code_inline, className)}>
      {children as ReactElement}
    </code>
  );
}

export function MarkdownCodeBlock({
  className = "",
  children,
}: MarkdownCodeProps) {
  const { copy, toastEnabled } = useCopyButton();
  // @ts-ignore
  const { lang, value, originCode } = getHljsCode(children?.props ?? {});

  const copyCode = createEventHandler({
    handler: () => {
      copy(originCode);
      toastEnabled && setChatMessageCopyToast(DEFAULT_MESSAGES.COPY);
    },
  });

  return (
    <pre className={cn(styles.code_block, className)}>
      <div className={styles.code_block_header}>
        <p className={styles.code_block_header_title}>{lang}</p>
        <button
          type="button"
          aria-label="코드 복사하기"
          onClick={copyCode}
          className={styles.code_block_header_copy_button}
        />
      </div>

      <div
        className={cn("hljs", lang, styles.code_block_contents)}
        dangerouslySetInnerHTML={{ __html: value }}
      />
    </pre>
  );
}
