import { toast } from "react-toastify";
import { CONTAINER_IDS, TOAST_IDS } from "./constants";

type SetToastArgs = {
  containerId: string;
  toastId?: string;
};

export const setErrorToast =
  ({ containerId }: SetToastArgs) =>
  (message: string) => {
    toast.error(message, { containerId });
  };

export const setDefaultErrorToast = setErrorToast({
  containerId: CONTAINER_IDS.TOP,
  toastId: TOAST_IDS.ONLY,
});
