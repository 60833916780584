import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMemo } from "react";
import { KyClient } from "~clients/fetch";
import { RESPONSE_MESSAGE_TYPES } from "~clients/socket";
import { useChannel } from "~features/channel";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { cloneObject } from "~utils/clone-object";
import {
  type MessageList,
  SuggestionsLoadingStatus,
} from "./message-list.schema";

export const useMutationAnswerResume = () => {
  const queryClient = useQueryClient();
  const { channelId } = useChannel();
  const queryKey = useMemo(
    () => QUERY_KEYS.SUMMARY.getYoutubeChatQueryKey(channelId),
    [channelId],
  );

  return useMutation({
    mutationFn: requestResume,

    onMutate: async (messageId) => {
      queryClient.setQueryData(queryKey, (prevMessages: MessageList) => {
        const nextMessages = cloneObject(prevMessages);
        const targetMessage = nextMessages.messages.find(
          (message) => message.id === messageId,
        );
        if (!targetMessage) return prevMessages;
        targetMessage.pausedByLength = false;
        targetMessage.type = RESPONSE_MESSAGE_TYPES.IN_ANSWER;
        targetMessage.suggestions = [];
        targetMessage.suggestionsLoading = SuggestionsLoadingStatus.SUCCESS;
        return nextMessages;
      });
    },
  });
};

/**
 * @example {@link https://api.alan.est.ai/api/v1/messages/66a7c69b4b16ba3080eeb57f/continue}
 */
const createApiPath = (messageId: string) => `messages/${messageId}/continue`;

const requestResume = async (messageId: string) => {
  KyClient.post(createApiPath(messageId));
};
