import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./card.module.scss";

type CardTitleProps = PropsWithChildren<{
  className?: string;
  as?: keyof JSX.IntrinsicElements;
}>;

export function CardTitle({ as = "h3", className, children }: CardTitleProps) {
  const Tag = as;

  return <Tag className={cn(styles.card_title, className)}>{children}</Tag>;
}
