import { v } from "~libs/valibot";

export const MessageInputReceivedDataSchema = v.pipe(
  v.string(),

  v.transform((data) => ({
    message: data,
  })),
);

export const SetSttActivatedReceivedDataSchema = v.pipe(
  v.boolean(),

  v.transform((data) => ({
    isSttActivated: data,
  })),
);

export enum EnterKeyActions {
  send = "send",
  breakLine = "break_line",
}

export const EnterKeyActionReceivedDataSchema = v.pipe(
  v.enum(EnterKeyActions),

  v.transform((data) => ({
    enterKeyAction: data,
  })),
);

export const MessageSendReceivedDataSchema = v.pipe(
  v.string(),

  v.transform((data) => ({
    message: data,
  })),
);

export const SetVirtualKeyboardActivatedReceivedDataSchema = v.pipe(
  v.boolean(),

  v.transform((data) => ({
    isVirtualKeyboardActivated: data,
  })),
);
