import { type NativeMessageCore, NativeMessageTypes } from "./receive.types";

export const EMPTY_NATIVE_MESSAGE: NativeMessageCore = {
  requestId: "",
  type: NativeMessageTypes._empty,
  data: "",
  hasError: false,
  errorType: "",
  hasStatistic: false,
  statisticEventName: "",
};
