import { createContext, useContext } from "react";

export type HistoryListItem = {
  id: string;
  channelId: string;
  title: string;
  updatedAt: string;
  content?: string;
  isYoutubePersona: boolean;
  searchText?: string;
};

export const HistoryListItemContext = createContext<
  HistoryListItem | undefined
>(undefined);

export const useHistoryListItemContext = () => {
  const context = useContext(HistoryListItemContext);
  if (!context) {
    throw new Error(
      "useHistoryItemContext must be used within a HistoryItemProvider",
    );
  }

  return context;
};
