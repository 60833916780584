import { match } from "ts-pattern";
import { pipe, safeParse, trim, string as vString, url as vUrl } from "valibot";
import type { Timestamp } from "./youtube-summary-shared.schema";

const { floor } = Math;

export const seconds2Timestamp = (sec: number): Timestamp => {
  const hour = floor(sec / 3600);
  const min = floor(sec / 60);
  const secStr = String(floor(sec % 60)).padStart(2, "0");

  // @ts-ignore
  return `${hour}:${min}:${secStr}`;
};

export const timestamp2Seconds = (timestamp: Timestamp) => {
  const [hours, minutes, seconds] = timestamp.split(":").map(Number);
  return seconds + minutes * 60 + hours * 3600;
};

/** @todo 유튭 url 정규식 활용 */
export const UrlSchema = pipe(vString(), trim(), vUrl());

export const validateUrl = (url: string) => safeParse(UrlSchema, url).success;

const YOUTUBE_URL_REG_EXPS = {
  /** @example {@link https://www.youtube.com/watch?v=CJAXnFgbHww} */
  DEFAULT: /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=(\S+)/,

  /** @example {@link https://youtu.be/CJAXnFgbHww} */
  DEFAULT_SHORT: /^(https?:\/\/)?youtu\.be\/(\S+)/,

  /** @example {@link https://www.youtube.com/embed/CJAXnFgbHww} */
  EMBED: /^(https?:\/\/)?(www\.)?youtube\.com\/embed\/(\S+)/,

  /** @example {@link https://youtu.be/embed/CJAXnFgbHww} */
  EMBED_SHORT: /^(https?:\/\/)?youtu\.be\/embed\/(\S+)/,

  /** @example {@link https://www.youtube-nocookie.com/embed/CJAXnFgbHww} */
  EMBED_NO_COOKIE: /^(https?:\/\/)?www\.youtube-nocookie\.com\/embed\/(\S+)/,

  /** @example {@link https://youtube.com/shorts/q3i4jtp-byQ} */
  SHORTS: /^(https?:\/\/)?(www\.)?youtube\.com\/shorts\/(\S+)/,

  /** @example {@link https://www.youtube.com/live/XMD0IHC3-24?si=vppEhy1MLuJK1Qmr} */
  LIVE: /^(https?:\/\/)?(www\.)?youtube\.com\/live\/(\S+)/,

  /** @example {@link https://youtu.be/live?v=lPkrwy5ywS4} */
  LIVE_SHORT: /^(https?:\/\/)?youtu\.be\/live\?v=(\S+)/,
} as const;

export const parseVideoIdFromYoutubeUrl = (url: string) => {
  const parsed = parseUrl(url);

  return match(url)
    .when(
      (url) =>
        YOUTUBE_URL_REG_EXPS.DEFAULT.test(url) ||
        YOUTUBE_URL_REG_EXPS.LIVE_SHORT.test(url),
      () => {
        return parsed.searchParams.get("v") ?? "";
      },
    )
    .when(
      (url) =>
        YOUTUBE_URL_REG_EXPS.EMBED_SHORT.test(url) ||
        YOUTUBE_URL_REG_EXPS.EMBED.test(url) ||
        YOUTUBE_URL_REG_EXPS.EMBED_NO_COOKIE.test(url) ||
        YOUTUBE_URL_REG_EXPS.SHORTS.test(url) ||
        YOUTUBE_URL_REG_EXPS.LIVE.test(url),
      () => {
        const [_, _type, videoId] = parsed.pathname.split("/");
        return videoId;
      },
    )
    .when(
      (url) => YOUTUBE_URL_REG_EXPS.DEFAULT_SHORT.test(url),
      () => {
        const [_, videoId] = parsed.pathname.split("/");
        return videoId;
      },
    )
    .otherwise(() => {
      return "";
    });
};

/** @todo refactor */
const parseUrl = (url: string) => {
  if (url.startsWith("http")) {
    return new URL(url);
  }

  try {
    return new URL(`https://${url}`);
  } catch {
    return new URL("");
  }
};
