import { createStore, useStore } from "zustand";

type MessageSocketResponseStates = {
  isAnswerStarted: boolean;
  isAnswering: boolean;
  isAnswerComplete: boolean;
  isAnswerCanceled: boolean;

  // TODO: suggestions 분리
  isFetchingSuggestQuestions: boolean;
  isFetchedSuggestQuestions: boolean;
};

type MessageSocketResponseActions = {
  setAnswerStarted: () => void;
  setIsAnswering: () => void;
  setAnswerComplete: () => void;
  setAnswerCanceled: () => void;

  // TODO: suggestions 분리
  setIsFetchingSuggestQuestions: () => void;
  setIsFetchedSuggestQuestions: () => void;
  reset: () => void;
};

type MessageSocketResponseStore = MessageSocketResponseStates &
  MessageSocketResponseActions;

const INIT_STATES: MessageSocketResponseStates = {
  isAnswerStarted: false,
  isAnswering: false,
  isAnswerComplete: false,
  isAnswerCanceled: false,
  isFetchingSuggestQuestions: false,
  isFetchedSuggestQuestions: false,
};

const store = createStore<MessageSocketResponseStore>((set) => ({
  ...INIT_STATES,
  setAnswerStarted: () => set({ ...INIT_STATES, isAnswerStarted: true }),
  setIsAnswering: () =>
    set({ ...INIT_STATES, isAnswerStarted: true, isAnswering: true }),
  setAnswerComplete: () => set({ ...INIT_STATES, isAnswerComplete: true }),
  setAnswerCanceled: () => set({ ...INIT_STATES, isAnswerCanceled: true }),

  setIsFetchingSuggestQuestions: () =>
    set({ ...INIT_STATES, isFetchingSuggestQuestions: true }),
  setIsFetchedSuggestQuestions: () =>
    set({ ...INIT_STATES, isFetchedSuggestQuestions: true }),
  reset: () => set({ ...INIT_STATES }),
}));

/** @TODO useRespondingStatus로 이전 */
export const useMessageSocketResponseStore = () => useStore(store);

export const setAnswerStarted = () => store.getState().setAnswerStarted();
export const setIsAnswering = () => store.getState().setIsAnswering();
export const setAnswerComplete = () => store.getState().setAnswerComplete();
export const setAnswerCanceled = () => store.getState().setAnswerCanceled();
export const setIsFetchingSuggestQuestions = () =>
  store.getState().setIsFetchingSuggestQuestions();
export const setIsFetchedSuggestQuestions = () =>
  store.getState().setIsFetchedSuggestQuestions();
export const resetSocketResponseStates = () => store.getState().reset();
