import { useQueryClient } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import debounce from "just-debounce-it";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNativeBridge } from "~clients/native-bridge";
import { DEFAULT_TITLE } from "~constants/page-title";
import { TIMES } from "~constants/times";
import { WithNativeAccessToken, userQueryOptions } from "~features/auth";
import {
  getChannelQueryOptions,
  useAuthGuard,
  useChannel,
} from "~features/channel";
import { useQueryChannel } from "~features/channel/use-query-channel";
import { useHistory } from "~features/history";
import { TimeoutErrorContext } from "~features/messages/hooks";
import {
  DeprecatedPersonaContext,
  personasQueryOptions,
  usePersonas,
} from "~features/personas";
import { RawTextContext } from "~features/prompt";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { SearchResults, getSearchQueryOptions } from "~features/search";
import {
  SearchHeaderDesktop,
  SearchHeaderMobile,
  SearchLayout,
} from "~features/search/layouts";
import { IsPendingContext } from "~features/search/use-is-pending.context";
import { useQuerySearch } from "~features/search/use-query-search";
import { useUserAgent } from "~features/user-agent";
import { Status } from "~features/util-types/status";
import { useWindowWidth } from "~hooks/use-window-width";
import { v } from "~libs/valibot";
import { logger } from "~utils/logger";

const SearchResultSearchSchema = v.object({
  message_id: v.optional(v.string()),
});

export const Route = createFileRoute("/search/$channelId")({
  component: () => (
    <WithNativeAccessToken>
      <SearchResultPage />
    </WithNativeAccessToken>
  ),

  beforeLoad: async ({ context: { queryClient }, params: { channelId } }) => {
    if (
      !useUserAgent.getState().isWeb &&
      !useNativeBridge.getState().nativeBridge
    )
      return { isLoggedIn: false };

    return queryClient.ensureQueryData(userQueryOptions).then((result) => {
      const isLoggedIn = result.id.length > 0;
      if (isLoggedIn) {
        queryClient.prefetchQuery(personasQueryOptions);
        queryClient.prefetchQuery(getChannelQueryOptions(channelId));
        queryClient.prefetchQuery(getSearchQueryOptions(channelId));
      }
      return { isLoggedIn };
    });
  },

  onError: (error) => {
    logger.errorRemote({
      scope: "SearchResultChannelRoute.onError",
      error: error.message,
      stack: error.stack,
    });
  },

  validateSearch: SearchResultSearchSchema,
});

function SearchResultPage() {
  const { isMobileSmallSize } = useWindowWidth();
  useAuthGuard();

  const { channelId, isChanged } = useChannel();
  const { data: channel } = useQueryChannel(channelId);
  const { data: searchResults } = useQuerySearch(channelId);

  const [isDeprecatedPersona, setIsDeprecatedPersona] = useState(false);
  const [rawText, setRawText] = useState("");
  const [rawKeyboardText, setRawKeyboardText] = useState("");
  const [isPending, setIsPending] = useState(false);

  const queryClient = useQueryClient();
  const [hasTimeoutError, setHasTimeoutError] = useState(false);
  const debouncedTimeoutHandler = useCallback(
    debounce(() => {
      queryClient.refetchQueries({
        queryKey: QUERY_KEYS.CHANNELS.getChannelMessagesQueryKey(channelId),
      });
    }, TIMES.SEC * 15),
    [],
  );

  const firstPrompt = useMemo(
    () => searchResults?.items?.[0]?.title ?? "",
    [searchResults],
  );

  const {
    getPersonaById,
    setPersona,
    isSuccess: isSuccessQueryPersonas,
  } = usePersonas();

  const { items } = useHistory();
  const channelTitle = useMemo(() => {
    if (channelId.length === 0) return "";
    return items.find((item) => item.channelId === channelId)?.title ?? "";
  }, [channelId, items]);

  useEffect(() => {
    if (channelTitle.length > 0) {
      document.title = `${channelTitle} | ${DEFAULT_TITLE}`;
    }
  }, [channelTitle]);

  const personaId = channel?.personaId ?? "";

  useEffect(() => {
    if (!personaId || !isSuccessQueryPersonas) return;

    const persona = getPersonaById(personaId);
    if (!persona) {
      setIsDeprecatedPersona(true);
      return;
    }

    setPersona(persona);
    setIsDeprecatedPersona(false);
  }, [personaId, getPersonaById, setPersona, isSuccessQueryPersonas]);

  useEffect(() => {
    if (!isChanged || !channelId) return;
    firstPrompt && setRawText(firstPrompt);

    return () => {
      debouncedTimeoutHandler.cancel();
    };
  }, [isChanged, channelId, firstPrompt, debouncedTimeoutHandler]);

  useEffect(() => {
    if (!searchResults) return;

    const nextIsPending = searchResults.items
      .slice(-1)
      .some(
        (item) => item.status === Status.INIT || item.status === Status.LOADING,
      );
    if (isPending !== nextIsPending) setIsPending(nextIsPending);
  }, [searchResults, isPending]);

  return (
    <SearchLayout>
      <DeprecatedPersonaContext.Provider
        value={{ isDeprecated: isDeprecatedPersona }}
      >
        <TimeoutErrorContext.Provider
          value={{
            hasTimeoutError,
            setHasTimeoutError,
            debouncedTimeoutHandler,
          }}
        >
          <IsPendingContext.Provider value={{ isPending, setIsPending }}>
            <RawTextContext.Provider
              value={{
                rawText,
                setRawText,
                rawKeyboardText,
                setRawKeyboardText,
              }}
            >
              {isMobileSmallSize ? (
                <SearchHeaderMobile />
              ) : (
                <SearchHeaderDesktop />
              )}
            </RawTextContext.Provider>
            <SearchResults channelId={channelId} />
          </IsPendingContext.Provider>
        </TimeoutErrorContext.Provider>
      </DeprecatedPersonaContext.Provider>
    </SearchLayout>
  );
}
