import { createContext, useContext } from "react";

type FeedbackSelectedContextStates = {
  selected: string;
  setSelected: (selected: string) => void;
};

export const FeedbackSelectedContext = createContext<
  FeedbackSelectedContextStates | undefined
>(undefined);

export function useFeedbackSelected() {
  const context = useContext(FeedbackSelectedContext);
  if (!context) {
    throw new Error(
      "useFeedbackSelected must be used within a FeedbackSelectedProvider",
    );
  }

  return context;
}
