export { SendingButton } from "./sending-button";

import { VotingButton } from "./voting-button";
import { VotingDownSvg } from "./voting-down-svg";
import { VotingUpSvg } from "./voting-up-svg";

export const VotingButtons = {
  Root: VotingButton,
  UpSvg: VotingUpSvg,
  DownSvg: VotingDownSvg,
};

import { CopyButton as CopyRoot } from "./copy-button";
import { CopySvg } from "./copy-svg";

export const CopyButton = {
  Root: CopyRoot,
  CopySvg,
};
