import styles from "./main-title.module.scss";
import { Title1 } from "./title";

export function MainTitle() {
  return (
    <Title1 className={styles.title}>
      <p className={styles.title_text}>무엇을 찾아 드릴까요?</p>
    </Title1>
  );
}
