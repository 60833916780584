import type { PropsWithChildren } from "react";
import { PromptWithCompletion } from "~features/prompt";
import { HeaderWithPrompt } from "~layouts/header";

export function SearchHeaderDesktop({ children }: PropsWithChildren) {
  return (
    <HeaderWithPrompt>
      {/* TODO: styles.prompt_area */}
      <div className={"relative w-full h-full"}>
        {children}
        <PromptWithCompletion />
      </div>
    </HeaderWithPrompt>
  );
}
