import { type PropsWithChildren, useEffect } from "react";
import { useNativeBridge } from "~clients/native-bridge";
import { useUserAgent } from "~features/user-agent";
import { useAccessToken } from "./use-access-token";

export function WithNativeAccessToken({ children }: PropsWithChildren) {
  const { isWeb, platform } = useUserAgent();

  if (platform.length > 0 && !isWeb) {
    return <WhenIsNativeApps>{children}</WhenIsNativeApps>;
  }

  return children;
}

function WhenIsNativeApps({ children }: PropsWithChildren) {
  const { accessToken, setAccessToken } = useAccessToken();
  const { nativeBridge } = useNativeBridge();

  useEffect(() => {
    if (accessToken) return;
    nativeBridge?.requestAccessTokenAsync().then((accessTokenData) => {
      setAccessToken(accessTokenData);
    });
  }, [nativeBridge, setAccessToken, accessToken]);

  return children;
}
