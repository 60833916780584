import { createFileRoute, redirect } from "@tanstack/react-router";

export const Route = createFileRoute("/channels/$channelId")({
  component: () => null,
  beforeLoad: ({ params }) => {
    const { channelId } = params;
    if (channelId === "new") {
      throw redirect({ to: "/", search: (prev) => prev });
    }

    throw redirect({
      to: "/search/$channelId",
      params: { channelId },
      search: (prev) => prev,
    });
  },
});
