import { create, useStore } from "zustand";

type CardStates = {
  openedCards: Set<string>;
  playtimeCard: string;
};

type CardActions = {
  toggleCardOpened: (cardId: string) => void;
  clearCardOpened: () => void;
  setPlaytimeCard: (cardId: string) => void;
};

const INIT_STATES: CardStates = {
  openedCards: new Set([]),
  playtimeCard: "",
};

const store = create<CardStates & CardActions>((set) => ({
  ...INIT_STATES,
  toggleCardOpened: (cardId: string) =>
    set((prevStates) => {
      const newCards = new Set(prevStates.openedCards);
      newCards.has(cardId) ? newCards.delete(cardId) : newCards.add(cardId);
      return {
        openedCards: newCards,
      };
    }),
  clearCardOpened: () => set({ openedCards: new Set([]) }),
  setPlaytimeCard: (cardId) => set({ playtimeCard: cardId }),
}));

export const useCard = () => {
  return useStore(store);
};
