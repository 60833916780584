import { P } from "ts-pattern";
import { v } from "~libs/valibot";
import {
  MESSAGE_STATUS$SERVER,
  MESSAGE_STOP_REASON$SERVER,
} from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * `content_filter` in stop_reason
 *
 * @example
  ```json
  {
    
    "data": {
      "id": null,
      "request_id": "fb458ab0-4efd-11ef-9d11-025f82757915",
      "type": "answer_complete",
      "stop_reason": "content_filter",

    }
  }
  ```
 *
 * `content_filter` in type
 *
 * @example
  ```json
  {
    "data": {
      "id": null,
      "request_id": "fb458ab0-4efd-11ef-9d11-025f82757915",
      "type": "content_filter",
      "stop_reason": null || "content_filter",
      // ...
    }
    // ...
  }
  ```
 *
 */
const ContentFilterErrorMessageSchema$Socket = v.pipe(
  v.union([
    v.object({
      type: v.literal(MESSAGE_STATUS$SERVER.CONTENT_FILTER),
      request_id: MessagesSchemas$Api.Item.entries.request_id,
    }),
    v.object({
      stop_reason: v.literal(MESSAGE_STATUS$SERVER.CONTENT_FILTER),
      request_id: MessagesSchemas$Api.Item.entries.request_id,
    }),
  ]),

  v.transform((input) => ({
    requestId: input.request_id,
  })),
);

export const parseContentFilterErrorMessage$Socket = (input: unknown) =>
  v.parse(ContentFilterErrorMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const ContentFilterErrorMessagePattern$Socket = getMessagePattern(
  P.union(
    {
      stop_reason: MESSAGE_STOP_REASON$SERVER.CONTENT_FILTER,
    },
    { type: MESSAGE_STOP_REASON$SERVER.CONTENT_FILTER },
  ),
);
