import { create, useStore } from "zustand";

import type { ModalContent } from "./modal-contents.types";

type ModalStates = {
  hasModalContent: boolean;
  modalContent: ModalContent;
};

type ModalActions = {
  setModalContents: (modalContent: ModalContent) => void;
  resetModalContents: () => void;
};

const INIT_STATES: ModalStates = {
  hasModalContent: false,
  modalContent: {
    img: "",
    title: "",
    message: "",
    button: "",
  },
} as const;

const store = create<ModalStates & ModalActions>((set) => ({
  ...INIT_STATES,
  setModalContents: (modalContent) =>
    set({ hasModalContent: true, modalContent }),
  resetModalContents: () => set(INIT_STATES),
}));

export const useModal = () => useStore(store);
