import { useCallback, useEffect } from "react";
import { create } from "zustand";
import { useChannel } from "~features/channel";
import { getRandomItem } from "~features/random";
import { useQuerySearchRecommends } from "~features/search-recommends";

type PlaceholderRecommendsStates = {
  placeholderText: string;
  setPlaceholderText: (placeholderText: string) => void;
};

const usePlaceholderRecommendsStore = create<PlaceholderRecommendsStates>(
  (set) => ({
    placeholderText: "",
    setPlaceholderText: (placeholderText) => set({ placeholderText }),
  }),
);

export const usePlaceholderWithRecommends = (rawTextValue: string) => {
  const { data: searchRecommends, isSuccess } = useQuerySearchRecommends();
  const { placeholderText, setPlaceholderText } =
    usePlaceholderRecommendsStore();
  const { channelId, prevChannelId } = useChannel();

  const setNextPlaceholderText = useCallback(() => {
    if (!isSuccess || searchRecommends.keywords.length === 0) return;

    const nextPlaceholderText = getRandomItem(searchRecommends.keywords, {
      exclude: rawTextValue,
    });
    setPlaceholderText(nextPlaceholderText);
  }, [isSuccess, searchRecommends?.keywords, rawTextValue, setPlaceholderText]);

  useEffect(() => {
    if (placeholderText !== rawTextValue) return;
    setNextPlaceholderText();
  }, [placeholderText, rawTextValue, setNextPlaceholderText]);

  useEffect(() => {
    if (channelId === prevChannelId) return;
    setNextPlaceholderText();
  }, [channelId, prevChannelId, setNextPlaceholderText]);

  return { placeholderText };
};
