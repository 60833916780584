import { useMutation } from "@tanstack/react-query";
import { match } from "ts-pattern";
import {
  KyClient,
  STATUS_CODES,
  isKyHttpError,
  parseKyHttpError,
} from "~clients/fetch";
import { usePersonas } from "~features/personas";
import { setDefaultErrorToast } from "~features/toast";
import { type ChannelId, transformResponse } from "./create-channel.schema";

export const useMutationCreateChannelId = (_personaId?: string) => {
  const { persona } = usePersonas();

  return useMutation({
    mutationFn: async () => {
      return match({ personaId: _personaId ?? persona?.id ?? "" })
        .returnType<Promise<ChannelId>>()
        .with({ personaId: "" }, async () => {
          throw new Error("[useMutationCreateChannelId] Persona Not Found");
        })
        .otherwise(async ({ personaId }) => {
          return await createChannelId({ personaId }).then(transformResponse);
        });
    },

    onError: async (error) => {
      if (isKyHttpError(error)) {
        const { status, response } = (await parseKyHttpError<unknown>(error))
          .data;
        match(status).with({ code: STATUS_CODES.RATE_LIMIT }, () => {
          setDefaultErrorToast(response as string);
        });
      }
    },
  });
};

type RequestChannelIdArgs = { personaId: string };

/**
 * Example
 * - {@link https://func08-api-estsoft-algpt-stage.azurewebsites.net/api/v1/channels}
 */
const API_PATH = "channels";

const createChannelId = async ({ personaId }: RequestChannelIdArgs) => {
  return await KyClient.post(API_PATH, {
    json: {
      persona_id: personaId,
    },
  }).json();
};
