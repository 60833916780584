import {
  VotedTypes,
  useMutationSendVote,
  useQueryFeedbacks,
} from "~features/feedback";
import { cn } from "~utils/class-names";
import { MessageAssistantCopyButton } from "./message-assistant-copy-button";
import { MessageAssistantDownVoteButton } from "./message-assistant-down-vote-button";
import { MessageAssistantUpVoteButton } from "./message-assistant-up-vote-button";
import { useDownReasonSubmitted } from "./use-down-reason-submitted";

import styles from "./message-assistant-feedback.module.scss";

type MessageAssistantFeedbackContainerProps = {
  messageId: string;
  textToCopy: string;
  setFormOpen: () => void;
};

export function MessageAssistantFeedbackContainer(
  props: MessageAssistantFeedbackContainerProps,
) {
  const { data } = useQueryFeedbacks({ messageIds: [props.messageId] });
  const { type: votedType } = data?.get(props.messageId) || {};
  const sendVoteMutation = useMutationSendVote();
  const { downReasonSubmitted } = useDownReasonSubmitted(props.messageId);

  const { isUp, isDown } = {
    isUp: votedType === VotedTypes.UP,
    isDown: votedType === VotedTypes.DOWN,
  };

  const voteUp = () => {
    sendVoteMutation.mutateAsync({
      messageId: props.messageId,
      type: VotedTypes.UP,
    });
  };

  const voteDown = () => {
    !isDown &&
      sendVoteMutation.mutateAsync({
        messageId: props.messageId,
        type: VotedTypes.DOWN,
      });
    props.setFormOpen();
    /** @todo scrollIntoView */
  };

  return (
    <div className={cn(styles.feedback_buttons)}>
      {!isDown && (
        <MessageAssistantUpVoteButton
          messageId={props.messageId}
          voted={isUp}
          onClick={voteUp}
        />
      )}
      {!isUp && (
        <MessageAssistantDownVoteButton
          messageId={props.messageId}
          voted={isDown}
          reasonSubmitted={downReasonSubmitted}
          onClick={voteDown}
        />
      )}
      <MessageAssistantCopyButton textToCopy={props.textToCopy} />
    </div>
  );
}
