import {
  FloatingFocusManager,
  autoUpdate,
  flip,
  shift,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from "@floating-ui/react";
import { useState } from "react";
import { useNativeBridge } from "~clients/native-bridge";
import {
  redirectToOAuthLogin,
  useMutationSignOut,
  useUser,
} from "~features/auth";
import MenuBox from "~features/ui/popup-menu/menu-box/menu-box";
import { useUserAgent } from "~features/user-agent";
import { createEventHandler } from "~utils/create-event-handler";
import { Button } from "../button";
import LogoutIcon from "./images/ico_logout.png";
import SettingIcon from "./images/ico_setting_gray.png";
import UserImg from "./images/ico_user.png";
import styles from "./user.module.scss";

type UserIconProps = {
  displayName: string;
  thumbnailUrl?: string;
};

export function UserIcon({ displayName, thumbnailUrl = "" }: UserIconProps) {
  return (
    <div className={styles.icon_user}>
      {thumbnailUrl ? (
        <img src={thumbnailUrl} alt="user icon" />
      ) : (
        // @TODO: 현재는 로그인하면 무조건 아래 아이콘 보여주고, 추후 정책을 통해 변경
        // displayName
        <img src={UserImg} alt="default icon" />
      )}
    </div>
  );
}

type SideBarUserProps = {
  displayName: string;
  thumbnailUrl?: string;
};

function UserFullName({ displayName }: SideBarUserProps) {
  return <span>{displayName}님</span>;
}

export function LoginButton() {
  const { isWeb } = useUserAgent();
  const { nativeBridge } = useNativeBridge();

  const onClick = createEventHandler({
    handler: () => {
      isWeb ? redirectToOAuthLogin() : nativeBridge?.requestLogin();
    },
  });

  return (
    <>
      <div className={styles.login_box}>
        <div>
          <p className={styles.title}>
            새로워진 실시간 AI 검색,
            <br /> Alan을 만나보세요.
          </p>
          <p className={styles.detail}>
            언제 어디서나 당신의 질문에 답하는 똑똑한 AI 검색을 경험하세요.
          </p>
        </div>
        <Button
          overrideClass={styles.btn_login}
          fullWidth={true}
          text="로그인"
          color="primary"
          onClick={onClick}
        />
      </div>
    </>
  );
}

function SignOutButton() {
  const signOutMutation = useMutationSignOut();

  const onClickLogoutButton = createEventHandler({
    handler: () => {
      signOutMutation.mutateAsync().then(() => {
        window.location.replace("/");
      });
    },
  });

  return (
    <button
      type="button"
      onClick={onClickLogoutButton}
      className={styles.btn_logout}
    >
      <img src={LogoutIcon} alt="" />
      <span>로그아웃</span>
    </button>
  );
}

function UserMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onClickMenuButton = createEventHandler({
    handler: () => {
      setIsMenuOpen((prev) => !prev);
    },
  });

  const { refs, context, floatingStyles } = useFloating({
    open: isMenuOpen,
    onOpenChange: setIsMenuOpen,
    placement: "top-start",
    whileElementsMounted: autoUpdate,
    middleware: [flip({ fallbackAxisSideDirection: "end" }), shift()],
  });

  const dismiss = useDismiss(context, {});
  const role = useRole(context);
  const { getReferenceProps, getFloatingProps } = useInteractions([
    dismiss,
    role,
  ]);

  return (
    <div className={styles.popup_menu}>
      <span ref={refs.setReference} {...getReferenceProps()}>
        <Button
          type="onlyIcon"
          size="medium"
          onClick={onClickMenuButton}
          aria-label="setting button"
          iconBefore={<img src={SettingIcon} alt="" />}
        />
      </span>

      {isMenuOpen && (
        <FloatingFocusManager context={context} modal={false}>
          <div>
            <MenuBox
              ref={refs.setFloating}
              width="200px"
              floatingStyles={floatingStyles}
              {...getFloatingProps()}
            >
              <SignOutButton />
            </MenuBox>
          </div>
        </FloatingFocusManager>
      )}
    </div>
  );
}

export function User() {
  const { isLoggedIn, user } = useUser();
  const { isMobile, isWeb } = useUserAgent();

  if (!isMobile && !isLoggedIn) {
    return <LoginButton />;
  }

  return (
    <div className={styles.user_box}>
      <div className={styles.user_info}>
        <UserIcon displayName={user.name[0]} />
        <UserFullName displayName={user.name} />
      </div>

      {isWeb && <UserMenu />}
    </div>
  );
}
