import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import type { PropsWithChildren } from "react";

export const tanStackQueryClient = new QueryClient();

export function TanStackQueryProvider(props: PropsWithChildren) {
  return (
    <QueryClientProvider client={tanStackQueryClient}>
      {props.children}
    </QueryClientProvider>
  );
}
