import { memo } from "react";

function _VotingDownSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      aria-label="down-vote"
      width="18"
      height="18"
      fill="none"
    >
      <g
        className="icon_stroke"
        opacity=".7"
        stroke="#24354A"
        strokeWidth="1.5"
        strokeLinecap="round"
      >
        <path d="M5.072 1.443h10.423c.279 0 .505.226.505.505v8.256c0 .279-.226.505-.505.505h-2.332c-.592 0-1.14.31-1.445.818l-2.283 3.806a1.374 1.374 0 0 1-2.494-1.102l.86-2.871a.505.505 0 0 0-.483-.65H3.464A1.685 1.685 0 0 1 1.838 8.58l1.608-5.897c.2-.733.866-1.241 1.626-1.241z" />
        <path d="M13.164 1.554V10" strokeLinecap="round" />
      </g>
    </svg>
  );
}

export const VotingDownSvg = memo(_VotingDownSvg);
