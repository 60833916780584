import { useEffect, useMemo, useState } from "react";
import AccordionButton from "~features/ui/accordion-button/accordion-button";
import { Status } from "~features/util-types/status";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import type { SearchResult } from "../schemas";

import styles from "./tool-steps.module.scss";

type SearchResultToolStepsProps = {
  toolSteps: SearchResult.ToolSteps[];
  searchStatus: Status;
  title?: string;
};

export function SearchResultToolSteps({
  title = "답변 과정",
  toolSteps,
  searchStatus,
}: SearchResultToolStepsProps) {
  const { isAnswerLoading, isAnswerCompleted } = useMemo(
    () => ({
      isAnswerLoading:
        searchStatus === Status.LOADING || searchStatus === Status.INIT,
      isAnswerCompleted: searchStatus === Status.SUCCESS,
    }),
    [searchStatus],
  );
  /** @todo re-render issue -> zustand로 변경 */
  const [isDetailOpen, setIsDetailOpen] = useState(isAnswerLoading);

  const summaryBadgeText = useMemo(() => {
    if (isAnswerLoading) return "답변 중";
    if (isAnswerCompleted) return "답변 완료";
    return "";
  }, [isAnswerLoading, isAnswerCompleted]);

  const toggleDetailOpen = createEventHandler({
    handler: () => {
      setIsDetailOpen((prev) => !prev);
    },
  });

  useEffect(() => {
    isAnswerLoading && setIsDetailOpen(true);
    isAnswerCompleted && setIsDetailOpen(false);
  }, [isAnswerLoading, isAnswerCompleted]);

  return (
    <details open={isDetailOpen} className={styles.accordion_wrapper}>
      <summary
        onClick={toggleDetailOpen}
        onKeyDown={toggleDetailOpen}
        className={styles.accordion_top}
      >
        <p className={styles.title}>{title}</p>

        <div className={styles.right_wrap}>
          {isDetailOpen && summaryBadgeText.length > 0 && (
            <SummaryBadge text={summaryBadgeText} />
          )}
          <AccordionButton isOpen={isDetailOpen} />
        </div>
      </summary>

      <ul className={styles.accordion_contents}>
        {toolSteps?.map((step) => (
          <li
            className={cn({
              [styles.loading]: step.status === Status.LOADING,
            })}
            key={`search-process-${step.order}`}
          >
            <span className={styles.icon}>
              {step.status === Status.SUCCESS ? (
                <SuccessIcon />
              ) : (
                <LoadingIcon />
              )}
            </span>
            <p>{step.speak}</p>
          </li>
        ))}
      </ul>
    </details>
  );
}

type SummaryBadgeProps = {
  text: string;
};

const SummaryBadge = ({ text }: SummaryBadgeProps) => (
  <span className={styles.status_badge}>{text}</span>
);

const LoadingIcon = () => (
  <svg
    className={styles.ico_loading}
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
  >
    <circle cx="7" cy="7.5" r="6.125" stroke="#248AFF" strokeWidth="1.75" />
    <rect
      x="7"
      y="7.5"
      width="7"
      height="7"
      fill="url(#paint0_linear_1424_1656)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_1424_1656"
        x1="12.8333"
        y1="8.08333"
        x2="10.5"
        y2="12.1667"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" stopOpacity="0.3" />
        <stop offset="1" stopColor="white" />
      </linearGradient>
    </defs>
  </svg>
);
const SuccessIcon = () => (
  <svg
    className={styles.ico_success}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
  >
    <path d="M2.75 7.5L6.75 11.5L13.25 5" stroke="#1A212C" strokeWidth="1.5" />
  </svg>
);
