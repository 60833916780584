import { Platforms } from "~features/user-agent";
import AosIcon from "./images/ico_android.png";
import IosIcon from "./images/ico_ios.png";
import WindowIcon from "./images/ico_window.png";

export const getAppDetails = (appType: Platforms) => {
  switch (appType) {
    case Platforms.windows:
      return { icon: WindowIcon, text: "Windows" };
    case Platforms.android:
      return { icon: AosIcon, text: "Android" };
    case Platforms.ios:
      return { icon: IosIcon, text: "iOS" };
    default:
      return { icon: "", text: "App" };
  }
};

export const openWindowsAppDownload = () => {
  window.open(
    "https://advert.estsoft.com/?event=202307041326449",
    "_blank",
    "noopener noreferrer",
  );
};

export const openAndroidAppDownload = () => {
  window.open(
    "https://ad.altools.co.kr/Web_AOS",
    "_blank",
    "noopener noreferrer",
  );
};

export const openIosAppDownload = () => {
  window.open(
    "https://ad.altools.co.kr/Web_iOS",
    "_blank",
    "noopener noreferrer",
  );
};
