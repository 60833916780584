import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import styles from "./message-row.module.scss";

type MessageRowProps = PropsWithChildren<{
  className?: string;
}>;

export function MessageRow(props: MessageRowProps) {
  return (
    <div className={cn(styles.message_row, styles.padding, props.className)}>
      {props.children}
    </div>
  );
}
