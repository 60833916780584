import { ReloadIcon } from "@radix-ui/react-icons";
import { useMessageSocketResponseStore } from "~components/chat/message-list";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { requestSuggestions } from "./request-suggestions";
import type { SuggestionsRequest } from "./suggestion-query.schema";

import buttonStyles from "./button.module.scss";

type ReloadSuggestionButtonProps = SuggestionsRequest & {
  onClick?: () => void;
  className?: string;
};

export function ReloadSuggestionButton({
  channelId,
  messageId,
  ...props
}: ReloadSuggestionButtonProps) {
  const { setIsFetchingSuggestQuestions } = useMessageSocketResponseStore();
  const onClick = createEventHandler({
    handler: async () => {
      if (!(messageId && channelId)) return;

      requestSuggestions({ channelId, messageId });
      setIsFetchingSuggestQuestions();
      props.onClick?.();
    },
  });

  return (
    <button
      onClick={onClick}
      type="button"
      className={cn(
        buttonStyles.suggestion_button,
        buttonStyles.flex,
        buttonStyles.text_blue,
        buttonStyles.rounded_20px,
        props.className,
      )}
    >
      <span>추천 질문 다시 생성</span>
      <ReloadIcon />
    </button>
  );
}
