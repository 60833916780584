import { CopyButton } from "~components/chat/message-buttons";
import { useCopyButton } from "~features/clipboard";
import { DEFAULT_MESSAGES, setChatMessageCopyToast } from "~features/toast";
import { createEventHandler } from "~utils/create-event-handler";

import styles from "./message-assistant-feedback.module.scss";

type MessageAssistantCopyButtonProps = {
  textToCopy: string;
  title?: string;
  onClick?: () => void;
};

const DEFAULT_BUTTON_TITLE = "복사하기";

export function MessageAssistantCopyButton(
  props: MessageAssistantCopyButtonProps,
) {
  const { copy, toastEnabled } = useCopyButton();

  const onClick = createEventHandler({
    handler: () => {
      copy(props.textToCopy);

      toastEnabled && setChatMessageCopyToast(DEFAULT_MESSAGES.COPY);

      // TODO: GA 통계
      props.onClick?.();
    },
  });

  return (
    <button
      type="button"
      className={styles.feedback_button}
      aria-label={props.title ? props.title : DEFAULT_BUTTON_TITLE}
      onClick={onClick}
    >
      <CopyButton.CopySvg />
    </button>
  );
}
