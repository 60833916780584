import { createFileRoute } from "@tanstack/react-router";
import { getYoutubeSummaryQueryOptions } from "~features/youtube-summary";
import { TabsSearchSchema } from "~features/youtube-summary/tabs";
import { logger } from "~utils/logger";

export const Route = createFileRoute("/summary/youtube/$channelId")({
  validateSearch: TabsSearchSchema,

  loader: async ({ context, params }) => {
    context.queryClient.ensureQueryData(
      getYoutubeSummaryQueryOptions(params.channelId),
    );
  },

  onError: (error) => {
    logger.errorRemote({
      scope: "SummaryYoutubeChannelRoute.onError",
      error: error.message,
      stack: error.stack,
    });
  },
});
