import type { PropsWithChildren } from "react";
import styles from "./headings.module.scss";

type MarkdownHeadingProps = PropsWithChildren;

export function MarkdownHeading1(props: MarkdownHeadingProps) {
  return <h1 className={styles.h1}>{props.children}</h1>;
}

export function MarkdownHeading2(props: MarkdownHeadingProps) {
  return <h2 className={styles.h2}>{props.children}</h2>;
}

export function MarkdownHeading3(props: MarkdownHeadingProps) {
  return <h3 className={styles.h3}>{props.children}</h3>;
}

export function MarkdownHeading4(props: MarkdownHeadingProps) {
  return <h4 className={styles.h4}>{props.children}</h4>;
}

export function MarkdownHeading5(props: MarkdownHeadingProps) {
  return <h5 className={styles.h5}>{props.children}</h5>;
}

export function MarkdownHeading6(props: MarkdownHeadingProps) {
  return <h6 className={styles.h6}>{props.children}</h6>;
}
