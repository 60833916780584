import { Drawer } from "~features/ui/drawer";
import { cn } from "~utils/class-names";
import { useWebSourceDrawer } from "./use-web-source-drawer";
import styles from "./web-source-drawer.module.scss";

export function WebSourceDrawer() {
  const {
    enabled,
    isOpen,
    setIsOpen,
    selectedWebSource: source,
    selectWebSource,
  } = useWebSourceDrawer();
  if (!isOpen || !enabled) return null;

  const closeDrawer = () => {
    setIsOpen(false);
    selectWebSource(undefined);
  };

  return (
    <Drawer.Root closeDrawer={closeDrawer}>
      {source?.id && (
        <p className="text-sm font-semibold text-alan-black-5">
          출처 {source?.id}
        </p>
      )}

      <div className="flex flex-col gap-3 justify-start items-start">
        {/* TODO: 검색API 데이터에 썸네일 없음 */}

        <div className="flex flex-col gap-1 justify-start items-start">
          <a href={source?.url} target="_blank" rel="noreferrer">
            <Drawer.Title title={source?.title ?? ""} />
          </a>

          <div
            className={cn(
              "flex items-center justify-start gap-3 text-alan-black-5 font-normal",
              styles.text_small,
            )}
          >
            {source?.favicon && (
              <img
                src={source.favicon}
                alt=""
                loading="lazy"
                width={16}
                className="object-cover rounded-full border border-solid aspect-auto border-slate-300"
              />
            )}

            <div className="flex justify-start items-baseline line-clamp-1">
              {source?.source && (
                <a
                  href={source.url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-sm text-slate-500"
                >
                  {source?.source}
                </a>
              )}
              {Boolean(source?.source && source?.updatedAt) && (
                <span>&nbsp;•&nbsp;</span>
              )}
              {source?.updatedAt && (
                <p className="text-sm text-slate-500">{source?.updatedAt}</p>
              )}
            </div>
          </div>
        </div>

        <Drawer.ContentText contentText={source?.snippet ?? ""} />
      </div>
    </Drawer.Root>
  );
}
