import { Link } from "@tanstack/react-router";
import { Button } from "~features/ui/button";
import { useSidebar } from "~features/ui/side-bar";
import { createEventHandler } from "~utils/create-event-handler";
import styles from "./home-button.module.scss";

export interface HomeButtonProps {
  isDefault?: boolean;
  onClick?: () => void;
}

const HomeButton: React.FC<HomeButtonProps> = (props) => {
  const { setIsOpen } = useSidebar();
  const onClick = createEventHandler({
    preventDefault: false,
    handler: () => {
      setIsOpen(false);
      props.onClick?.();
    },
  });

  return (
    <Link to={"/"} className={styles.btn_logo_link} onClick={onClick}>
      <Button
        {...props}
        overrideClass={styles.btn_logo}
        href="/"
        type="onlyIcon"
        variant="radius"
        aria-label="search button"
        iconBefore={
          <svg
            width="75"
            height="20"
            viewBox="0 0 75 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M23.0946 19.5984H18.8098L16.8157 15.743H6.19808L4.25781 19.5984H0L9.70035 0.910309H13.1777L23.0946 19.5984ZM11.453 5.38153L7.70718 12.7711H15.2796L11.453 5.38153Z"
              fill="black"
            />
            <path
              d="M25.7957 2.91834H23.8285V0H29.4876V19.5984H25.7957V2.91834Z"
              fill="black"
            />
            <path
              d="M50.2763 19.7323H46.6113V18.3133C45.1022 19.4645 43.0002 20 40.6827 20H40.1976C35.1314 20 32.7599 18.6881 32.7599 15.6091V14.913C32.7599 11.7805 34.9427 10.3079 40.7097 10.3079H41.6259C43.3236 10.3079 45.0214 10.5756 46.6113 11.1111V10.7095C46.6113 8.6747 45.6412 7.76439 42.111 7.76439C39.4161 7.76439 37.0447 8.13922 34.6733 8.72825L33.7031 5.70281C36.2093 5.08701 38.7424 4.76573 41.9493 4.73896C48.2551 4.68541 50.2763 6.96118 50.2763 10.4953V19.7323ZM40.1976 17.0013H40.6827C43.135 17.0013 44.9944 16.5997 46.6113 15.5556V14.1633C44.8327 13.5475 43.1619 13.3066 41.1947 13.3066H40.4402C37.422 13.3066 36.4518 13.9759 36.4518 14.9933V15.4485C36.4518 16.3855 37.2872 17.0013 40.1976 17.0013Z"
              fill="black"
            />
            <path
              d="M54.5753 4.87283H58.2403V7.25569C59.5338 5.72959 61.7705 4.73896 64.7887 4.73896H65.4624C70.9059 4.73896 74.1667 7.20214 74.1667 11.7537V19.5984H70.4748V11.7805C70.4748 9.23695 68.7231 7.76439 65.5433 7.76439H64.7887C62.04 7.76439 59.938 8.96921 58.2403 10.9772V19.5984H54.5753V4.87283Z"
              fill="black"
            />
          </svg>
        }
      />
    </Link>
  );
};

export default HomeButton;
