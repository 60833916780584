import { create } from "zustand";

type SttModeStates = {
  isSttMode: boolean;
  sttText: string;
  /** @todo keyboardText 는 useTextarea에서 관리하는게 나을 듯? */
  keyboardText: string;
};

type SttModeActions = {
  updateIsSttMode: (isSttMode: SttModeStates["isSttMode"]) => void;
  updateSttText: (
    updater:
      | SttModeStates["sttText"]
      | ((prevSttText: SttModeStates["sttText"]) => SttModeStates["sttText"]),
  ) => void;
  updateKeyboardText: (keyboardText: SttModeStates["keyboardText"]) => void;
};

const INITIAL_STATES: SttModeStates = {
  isSttMode: false,
  sttText: "",
  keyboardText: "",
};

/** @todo sttText, keyboardText 제거 */
export const useSttMode = create<SttModeStates & SttModeActions>((set) => ({
  ...INITIAL_STATES,
  updateIsSttMode: (isSttMode) => set({ isSttMode }),
  updateSttText: (updater) =>
    set((prevStates) => ({
      sttText:
        typeof updater === "string" ? updater : updater(prevStates.sttText),
    })),
  updateKeyboardText: (keyboardText) => set({ keyboardText }),
}));
