type GetRandomItemOptions = {
  exclude?: string;
};

export const getRandomItem = (
  arr: string[],
  options: GetRandomItemOptions = {},
) => {
  if (arr.length === 0) {
    return "";
  }
  const filteredArr = arr.filter((item) => item !== options?.exclude);
  const randomIndex = Math.floor(Math.random() * filteredArr.length);
  return filteredArr[randomIndex];
};
