import { type InferOutput, v } from "~libs/valibot";

const SendMessageRequestsSchema = v.object({
  channelId: v.string(),
  userId: v.string(),
  message: v.string(),
  personaId: v.string(),
});

const SendMessageRequestSchema$Api = v.pipe(
  SendMessageRequestsSchema,

  v.transform((req) => ({
    channel_id: req.channelId,
    content: req.message,
    user_id: req.userId,
    persona_id: req.personaId,
  })),
);

export const transformSendMessageRequests = (input: unknown) =>
  v.safeParse(SendMessageRequestSchema$Api, input, { abortEarly: true });

export type SendMessageRequests = InferOutput<typeof SendMessageRequestsSchema>;

export type SendMessageRequestsWithoutUserId = Omit<
  SendMessageRequests,
  "userId"
>;
