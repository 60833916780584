import { useQuery } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { useChannel } from "~features/channel";
import { transformResponse } from "./message-feedback.schema";

const defaultQueryKeyFeedbacks = ["chat", "message", "feedbacks"];

type GetQueryKeyFeedbacksArgs = {
  channelId: string;
  messageIds: string[];
};

export const getQueryKeyFeedbacks = ({
  channelId,
  messageIds,
}: GetQueryKeyFeedbacksArgs) =>
  defaultQueryKeyFeedbacks.concat(channelId).concat(messageIds.sort());

type useQueryFeedbacksArgs = {
  messageIds: string[];
};

export const useQueryFeedbacks = ({ messageIds }: useQueryFeedbacksArgs) => {
  const { channelId } = useChannel();

  return useQuery({
    enabled:
      channelId.length > 0 && messageIds.length > 0 && messageIds[0].length > 0,
    queryKey: getQueryKeyFeedbacks({ channelId, messageIds }),
    queryFn: async () => fetchFeedbacks(messageIds).then(transformResponse),
    refetchOnMount: true,
    refetchOnWindowFocus: true,
    refetchInterval: false,
    staleTime: TIMES.MIN,
    placeholderData: new Map(),
  });
};

/**
 * @example
 * - {@link https://api.alan.est.ai/api/v2/thumbs?message_ids[]=6685e675ce0e21e0a846212c&message_ids[]=6685e69a1caf14a6010de15f&message_ids[]=6685e7895b6b46edf710a92f}
 */
const API_PATH = "api/v2/thumbs";

const fetchFeedbacks = async (messageIds: string[]) =>
  await KyClient.get(API_PATH, {
    prefixUrl: import.meta.env.VITE_GEPETO_API_BASE_URL,
    searchParams: messageIds.map((messageId) => ["message_ids[]", messageId]),
  }).json();
