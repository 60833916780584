import { create } from "zustand";
import type { SearchResult } from "~features/search/schemas";

type WebSourcesStates = {
  sources: Map<string, SearchResult.WebSource>;
  addSource: (source: SearchResult.WebSource) => void;
  clearSources: () => void;
};

export const useWebSources = create<WebSourcesStates>((set) => ({
  sources: new Map(),
  addSource: (source) =>
    set((state) => {
      if (state.sources.get(source.id)?.url === source.url) return state;
      state.sources.set(source.id, source);
      return { sources: state.sources };
    }),
  clearSources: () => set({ sources: new Map() }),
}));
