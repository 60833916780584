import { queryOptions, useQuery } from "@tanstack/react-query";
import { KyClient } from "~clients/fetch";
import { TIMES } from "~constants/times";
import { QUERY_KEYS } from "~features/providers/tanstack-query";
import { INIT_SEARCH_RECOMMENDATIONS, transformResponse } from "./schemas";

export const useQuerySearchRecommends = () => {
  return useQuery(searchRecommendsQueryOptions);
};

export const searchRecommendsQueryOptions = queryOptions({
  queryKey: QUERY_KEYS.SEARCH.RECOMMENDATIONS,
  queryFn: async () =>
    await KyClient("search/recommend-keywords").json().then(transformResponse),
  refetchInterval: TIMES.HOUR * 1,
  refetchOnMount: true,
  refetchOnWindowFocus: false,
  placeholderData: INIT_SEARCH_RECOMMENDATIONS,
});
