import { useQuery } from "@tanstack/react-query";
import { messagesQueryOptionsFactory } from "~features/messages";

import { parseSearchResultsFromApi } from "./schemas/search-results.schemas";

export const useQuerySearch = (channelId: string) => {
  return useQuery(getSearchQueryOptions(channelId));
};

export const getSearchQueryOptions = messagesQueryOptionsFactory({
  parseResponse: parseSearchResultsFromApi,
});
