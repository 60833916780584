import { useQueryClient } from "@tanstack/react-query";
import { useCallback } from "react";
import { useChannel } from "~features/channel";
import { type Feedbacks, getQueryKeyFeedbacks } from "~features/feedback";

/**
 * @todo 매번 계산 하는 거보다는 messages query 자체에 포함시키는게 좋을 듯
 */
export const useDownReasonSubmitted = (messageId: string) => {
  const { channelId } = useChannel();
  const queryClient = useQueryClient();

  const getMessageFeedbacks = useCallback(
    (messageId: string) =>
      queryClient
        .getQueryData<Feedbacks>(
          getQueryKeyFeedbacks({ channelId, messageIds: [messageId] }),
        )
        ?.get(messageId),
    [queryClient, channelId],
  );

  const downReasonSubmitted =
    (getMessageFeedbacks(messageId)?.downReasons.length ?? 0) > 0;

  return {
    downReasonSubmitted,
  };
};
