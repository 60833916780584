import { type MouseEvent, type PropsWithChildren, useState } from "react";
import { cn } from "~utils/class-names";
import { Tooltip } from "./tooltip";

import styles from "./alan-guide-tooltip.module.scss";

type GuideTooltip = PropsWithChildren<{
  isPositionBottom?: boolean;
  title?: string;
}>;

const DEFAULT_BUTTON_TITLE = "앨런 활용 가이드";

export function AlanGuideTooltip(props: GuideTooltip) {
  const [isOpen, setIsOpen] = useState(false);

  const onClickToggleButton = (e: MouseEvent) => {
    e.preventDefault();
    setIsOpen((prev) => !prev);
  };

  const onClickCloseButton = () => {
    setIsOpen(false);
  };

  return (
    <Tooltip.Wrapper className={styles.wrapper}>
      <button
        type="button"
        aria-label={DEFAULT_BUTTON_TITLE}
        onClick={onClickToggleButton}
        className={cn(styles.toggle_button, { [styles.is_open]: isOpen })}
      />

      {isOpen && (
        <Tooltip.Contents
          className={cn(styles.contents, {
            [styles.position_bottom]: props.isPositionBottom,
          })}
        >
          <p>
            <Tooltip.Title className={styles.title}>
              {props.title ?? DEFAULT_GUIDE_TITLE}
            </Tooltip.Title>
            {props.children ?? <DefaultGuideContent />}
          </p>

          <Tooltip.CloseButton onClick={onClickCloseButton} />
        </Tooltip.Contents>
      )}
    </Tooltip.Wrapper>
  );
}

export const DEFAULT_GUIDE_TITLE = "앨런 활용 가이드";

export function DefaultGuideContent() {
  return (
    <>
      · 답변의 정확도나 적절성에 일부 불확실성이 있을 수 있어요.
      <br />· 개인정보와 같은 민감한 비공개 정보는 입력하지 말아주세요.
      <br />· 명확하고 상세하게 검색하면 더 잘 대답해요.
      <br />· URL과 함께 질문하면 참고하여 답변할 수 있어요.
      <br />· 현재는 하루에 정해진 검색 개수만큼만 검색할 수 있어요.
      <br />
      &nbsp;&nbsp;(검색, 관련된 질문 시 차감)
      <br />· 품질 개선을 위해 검색어 및 답변이 활용될 수 있어요.
    </>
  );
}

export const YOUTUBE_GUIDE_TITLE = "유튜브 요약 앨런 활용 가이드";

export function YoutubeDetailPageGuideContent() {
  return (
    <>
      · 앨런은 OpenAI의 GPT를 기반으로 답변을 제공해요.
      <br />· 답변의 정확도나 적절성에 일부 불확실성이 있을 수 있어요.
      <br />· 품질 개선을 위해 질문 및 답변이 처리될 수 있어요.
      <br />· 개인정보와 같은 민감한 비공개 정보는 입력하지 말아주세요.
      <br />· 명확하고 상세하게 질문하면 더 잘 대응해요
      <br />· 배경 정보를 제공하면 더 정확하게 답변해요.
      <br />· URL과 함께 질문하면 참고하여 답변해요.
      <br />· 현재는 하루에 질문 개수만큼 질문이 가능해요.
      <br />
      &nbsp;&nbsp;(요약, 질문, 추천 질문 클릭시 차감)
    </>
  );
}
