import { create } from "zustand";

type ReloadStates = {
  counts: number;
  lastUpdatedTime: number;
};

type ReloadActions = {
  updateTime: () => void;
};

export const useReload = create<ReloadStates & ReloadActions>((set) => ({
  counts: 0,
  lastUpdatedTime: Date.now(),
  updateTime: () =>
    set((prev) => ({ lastUpdatedTime: Date.now(), counts: prev.counts + 1 })),
}));
