import type { MouseEvent, PropsWithChildren } from "react";

type BackgroundProps = PropsWithChildren<{
  closeDrawer: (e: MouseEvent) => void;
}>;

export function Background({ children, closeDrawer }: BackgroundProps) {
  return (
    <section className="w-full bg-black opacity-10 h-svh" onClick={closeDrawer}>
      {children}
    </section>
  );
}
