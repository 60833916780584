import { v } from "~libs/valibot";
import { MESSAGE_STATUS$SERVER } from "../constants";
import { MessagesSchemas$Api } from "./schemas.api";
import { getMessagePattern } from "./shared";

/**
 * @example
 ```json
 {
    "type": "message",
    "from": "group",
    "fromUserId": null,
    "group": "66d7bcd0f7efa816d9710306",
    "dataType": "json",
    "data": {
        "request_id": "4575ae10-6a67-11ef-9727-7a984b8a0053",
        "type": "canceled"
    }
 }
 ````
*/
const CancelMessageSchema$Socket = v.pipe(
  v.object({
    type: v.literal(MESSAGE_STATUS$SERVER.CANCELED),
    request_id: MessagesSchemas$Api.Item.entries.request_id,
  }),

  v.transform((input) => ({
    requestId: input.request_id,
  })),
);

export const parseCancelMessage$Socket = (input: unknown) =>
  v.parse(CancelMessageSchema$Socket, input, {
    abortEarly: true,
  });

export const CancelMessagePattern$Socket = getMessagePattern({
  type: MESSAGE_STATUS$SERVER.CANCELED,
});
