import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";

import styles from "./message-assistant-feedback-form-button.module.scss";

type MessageAssistantFeedbackFormButtonProps = {
  available: boolean;
  title: string;
  className?: string;
  onClick?: () => void;
};

export function MessageAssistantFeedbackFormButton(
  props: MessageAssistantFeedbackFormButtonProps,
) {
  const onClick = createEventHandler({
    handler: () => {
      props.available && props.onClick?.();
    },
  });

  return (
    <button
      type="button"
      onClick={onClick}
      className={cn(styles.feedback_form_buttons, props.className)}
      disabled={!props.available}
    >
      {props.title}
    </button>
  );
}
