import type { VirtualItem } from "@tanstack/react-virtual";
import { type PropsWithChildren, forwardRef } from "react";
import { cn } from "~utils/class-names";
import styles from "./result.module.scss";

type ResultSectionProps = PropsWithChildren<{
  className?: string;
  virtualItem: VirtualItem;
}>;

export const ResultSection = forwardRef<HTMLDivElement, ResultSectionProps>(
  function ResultSection({ children, className, virtualItem }, ref) {
    return (
      <section
        ref={ref}
        data-index={virtualItem.index}
        className={cn(styles.result_section, className)}
      >
        {children}
      </section>
    );
  },
);
