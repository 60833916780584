import {
  type PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { default as YoutubePlayer } from "youtube-player";
import type { YouTubePlayer } from "youtube-player/dist/types";
import { setDefaultErrorToast } from "~features/toast";
import { useUserAgent } from "~features/user-agent";
import { useQueryRequestSummary } from "~features/youtube-summary";

type TYoutubePlayerContext = {
  youtubePlayers: YouTubePlayer[];
  seekAndPlay: (timeSec: number) => void;
};

const YoutubePlayerContext = createContext<TYoutubePlayerContext>({
  youtubePlayers: [],
  seekAndPlay: () => {},
});
export const useYoutubePlayerContext = () => useContext(YoutubePlayerContext);

type YoutubePlayerProviderProps = PropsWithChildren<{
  containerIds: string[];
}>;

/**
 * @description
 * - YoutubePlayer 인스턴스 생성은 페이지 로딩시 최초 1회만 발생해야 함, 그렇지 않으면 player 조작 불가능..
 * - zustand에선 hook 호출할 떄마다 YoutubePlayer를 새로 생성하는 문제가 있어 context로 전환
 */
export function YoutubePlayerProvider({
  containerIds,
  children,
}: YoutubePlayerProviderProps) {
  const { isAndroid } = useUserAgent();
  const [youtubePlayers, setYoutubePlayers] = useState<YouTubePlayer[]>([]);
  const { data: youtubeSummary } = useQueryRequestSummary();
  const videoId = youtubeSummary?.video.id ?? "";

  const seekAndPlay = useCallback(
    (timeSec: number) => {
      const player = youtubePlayers[0];
      if (!player) return;

      player
        .getPlayerState()
        .then(() => player.seekTo(timeSec, true))
        .catch((e) => {
          console.error("[YoutubePlayer.seekAndPlay]\n", e);
          setDefaultErrorToast("유튜브 플레이어에서 오류가 발생했습니다");
        });
    },
    [youtubePlayers],
  );

  useEffect(() => {
    /** @todo 조건문 개선 - 현재 players에서 갖고있는 Id와 props로 들어온 id가 일치하는지 */
    if (videoId.length > 0 && youtubePlayers.length === 0) {
      setYoutubePlayers(
        containerIds.map((id) =>
          YoutubePlayer(
            id,
            /**
             * @see {@link https://developers.google.com/youtube/player_parameters?hl=ko#Parameters}
             * videoId 반드시 필요,
             * 없으면 player로 조작 불가능
             */
            {
              videoId,
              width: "100%",
              playerVars: {
                modestbranding: 1,
                playsinline: 1,
                rel: 0,
                autoplay: 0,
                iv_load_policy: 3,
              },
            },
            true,
          ),
        ),
      );
    }
  }, [videoId, youtubePlayers, containerIds]);

  useEffect(() => {
    if (!isAndroid) return;

    const player = youtubePlayers[0];
    if (!player) return;
    player.on("ready", () => {
      player.mute().then(() => player.pauseVideo());
    });
  }, [youtubePlayers, isAndroid]);

  return (
    <YoutubePlayerContext.Provider value={{ youtubePlayers, seekAndPlay }}>
      {children}
    </YoutubePlayerContext.Provider>
  );
}
