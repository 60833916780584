import { match } from "ts-pattern";
import { ulid } from "ulid";
import type { InferOutput } from "valibot";
import { parse, pipe, safeParse, transform } from "valibot";
import { SuggestionsLoadingStatus } from "~features/youtube-summary/message-list";
import { timestamp2Seconds } from "./utils";
import { YoutubeSummaryServerSchemas } from "./youtube-summary-server.schema";

export enum VideoTypes {
  youtube = "youtube",
}

export enum SummaryStatus {
  init = "init",
  start = "start",
  loading = "loading",
  summarizing = "summarizing",
  success = "success",
  error = "error",
}

export type YoutubeSummary = InferOutput<typeof YoutubeSummarySchema>;

export const INIT_DATA: YoutubeSummary = {
  status: SummaryStatus.init,
  tldr: "",
  video: {
    id: "",
    type: VideoTypes.youtube,
    title: "",
    url: "",
  },
  summary: [],
  scripts: [],
  suggestions: [],
  suggestionsLoading: SuggestionsLoadingStatus.SUCCESS,
};

const START_DATA: YoutubeSummary = Object.assign({}, INIT_DATA, {
  status: SummaryStatus.start,
});

const ERROR_DATA: YoutubeSummary = Object.assign({}, INIT_DATA, {
  status: SummaryStatus.error,
});

export const YoutubeSummarySchema = pipe(
  YoutubeSummaryServerSchemas.Complete,

  transform((res) => {
    const {
      video_id,
      title,
      metadata: scripts,
      summary,
      suggest_questions,
    } = res;

    return {
      status: SummaryStatus.success,

      tldr: summary.total_summary?.join("\n\n") ?? "",

      video: {
        id: video_id,
        type: VideoTypes.youtube,
        title,
        url: `https://www.youtube.com/watch?v=${video_id}`,
      },

      /**
       * id: string
       * title: string
       * timestamp: Timestamp
       * timeSec: number
       * summary: {id: string, content: string}[]
       * script: string
       */
      summary: summary.chapters.map((chapter) => ({
        id: ulid(),
        title: chapter.title,
        timestamp: chapter.timestamp,
        timeSec: timestamp2Seconds(chapter.timestamp),
        summary: chapter.summary.map((line) => ({
          id: ulid(),
          content: line,
        })),
        script: chapter.detail ?? [],
      })),

      /**
       * id: string
       * title: string
       * timestamp: Timestamp
       * timeSec: number
       * scripts: {id: string, timestamp: Timestamp, timeSec: number, content: string}[]
       */
      scripts: scripts
        .filter((script) => script.scripts.length > 0)
        .map((ch) => ({
          id: ulid(),
          title: String(ch.chapter_title),
          timestamp: ch.scripts[0].timestamp,
          timeSec: timestamp2Seconds(ch.scripts[0].timestamp),
          scripts: ch.scripts.map((script) => ({
            id: ulid(),
            timestamp: script.timestamp,
            timeSec: timestamp2Seconds(script.timestamp),
            content: script.content,
          })),
        })),

      suggestions: suggest_questions ?? [],

      suggestionsLoading:
        (suggest_questions?.length ?? 0) > 0
          ? SuggestionsLoadingStatus.SUCCESS
          : SuggestionsLoadingStatus.LOADING,
    };
  }),
);

export const LOADING_DATA: YoutubeSummary = Object.assign({}, INIT_DATA, {
  status: SummaryStatus.loading,
});

const YoutubeSummaryLoadingSchema = pipe(
  YoutubeSummaryServerSchemas.LoadingInfo,
  transform((res): YoutubeSummary => {
    return Object.assign({}, LOADING_DATA, {
      video: { id: res.video_id, title: res.title ?? "" },
    } as Partial<YoutubeSummary>);
  }),
);

export const transformResponse = (res: unknown) => {
  return match(res)
    .returnType<YoutubeSummary>()
    .when(
      () =>
        safeParse(YoutubeSummaryServerSchemas.Complete, res, {
          abortEarly: true,
        }).success,
      () => {
        return parse(YoutubeSummarySchema, res, { abortPipeEarly: true });
      },
    )
    .when(
      () =>
        safeParse(YoutubeSummaryServerSchemas.LoadingInfo, res, {
          abortEarly: true,
        }).success,
      () => {
        return parse(YoutubeSummaryLoadingSchema, res, {
          abortPipeEarly: true,
        });
      },
    )
    .when(
      () =>
        safeParse(YoutubeSummaryServerSchemas.Init, res, { abortEarly: true })
          .success,
      () => {
        return START_DATA;
      },
    )
    .when(
      () =>
        safeParse(YoutubeSummaryServerSchemas.RequestReceived, res, {
          abortEarly: true,
        }).success,
      () => {
        return START_DATA;
      },
    )
    .when(
      () =>
        safeParse(YoutubeSummaryServerSchemas.InvalidDataError, res, {
          abortEarly: true,
        }).success,
      () => {
        /** @todo 에러 로그 수집 */
        return ERROR_DATA;
      },
    )
    .when(
      () =>
        safeParse(YoutubeSummaryServerSchemas.VideoNotFoundError, res, {
          abortEarly: true,
        }).success,
      () => {
        /** @todo 에러 로그 수집 */
        return ERROR_DATA;
      },
    )
    .when(
      () =>
        safeParse(YoutubeSummaryServerSchemas.ContentFilterError, res, {
          abortEarly: true,
        }).success,
      () => {
        /** @todo 에러 로그 수집 */
        return ERROR_DATA;
      },
    )

    .when(
      () =>
        safeParse(YoutubeSummaryServerSchemas.ServerError, res, {
          abortEarly: true,
        }).success,
      () => {
        /** @todo 에러 로그 수집 */
        return ERROR_DATA;
      },
    )
    .otherwise(() => {
      /** @todo 에러 로그 수집 */
      console.warn("[YoutubeSummarySchema.parse.error]\n", res);
      return ERROR_DATA;
    });
};
