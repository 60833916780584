/**
 * @link
 * - prod: https://api.alan.est.ai/auth/sign-in?state=http://localhost:3000/channels/new
 * - stage: https://func08-api-estsoft-algpt-stage.azurewebsites.net/auth/sign-in?state=http://localhost:3000/channels/new
 * - dev: https://gepeto-api-function.azurewebsites.net/auth/sign-in?state=http://localhost:3000/channels/new
 */
const API_URL = `${import.meta.env.VITE_GEPETO_API_BASE_URL}/auth/sign-in`;

export const redirectToOAuthLogin = (
  nextPage = window.location.origin.concat(window.location.search),
) => {
  const redirectUrl = new window.URL(API_URL);
  redirectUrl.searchParams.set("callback", nextPage);
  window.location.replace(redirectUrl.href);
};
