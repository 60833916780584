import { type InferOutput, v } from "~libs/valibot";

export enum NativeMessageTypes {
  _empty = "",

  /** Auth */
  accessTokenResponse = "get_access_token.response",
  refreshAccessTokenResponse = "refresh_access_token.response",
  setAccessToken = "set_access_token",
  /** setAccessToken */
  initialize = "initialize",

  /** Message Input */
  updateIsStt = "set_stt_state",
  inputMessage = "input_message",
  sendMessage = "send_message",
  enterKeyAction = "enter_key_action",
  useVirtualKeyboard = "use_virtual_keyboard",

  /** Permissions, App Options */
  checkPermissionResponse = "check_permission.response",
  requestPermissionResponse = "request_permission.response",

  /** Etc. */
  isForeground = "is_foreground.response",
  /** app reload */
  setForeground = "set_foreground",

  /** 사실상 안쓰이는 */
  setSettingOptions = "set_setting_options",
  getSettingOptionsResponse = "get_setting_options.response",
  gaLogEvent = "ga_log_event",
}

export type AccessTokenResponse = {
  access_token: string;
  token_type: string;
  expires_in: number;
};

export enum NativeErrorMessageTypes {
  noLogin = "no_login",
  noManager = "no_manager",
}

const NativeMessageReceivedSchema = v.object({
  type: v.enum(NativeMessageTypes),
  request_id: v.optional(v.union([v.number(), v.string()])),
  data: v.optional(v.any()),

  /** @todo vEnum(NativeErrorMessageTypes) + unknown strings */
  error: v.optional(v.string()),
  event: v.optional(v.string()),

  /** 불필요 */
  // _id: union([vNumber(), vString()]),
});

export const NativeMessageSchemaCore = v.pipe(
  NativeMessageReceivedSchema,
  v.transform((messageRecieved) => {
    const errorType = messageRecieved.error ?? "";
    const statisticEventName = messageRecieved.event ?? "";

    return {
      requestId:
        messageRecieved.request_id === undefined
          ? ""
          : messageRecieved.request_id,
      type: messageRecieved.type,
      data: messageRecieved.data ?? "",

      hasError: errorType !== "",
      errorType,

      hasStatistic: statisticEventName !== "",
      statisticEventName,
    };
  }),
);

export type NativeMessageCore = InferOutput<typeof NativeMessageSchemaCore>;

export const NativeMessageAccessTokenResponseDataSchema = v.pipe(
  v.object({
    access_token: v.string(),
    token_type: v.optional(v.string(), "bearer"),
    /**
     * @description windows 앱에서 string으로 들어옴
     * @example "1800"
     */
    expires_in: v.union([v.number(), v.string()]),
  }),

  v.transform((result) => ({
    accessToken: result.access_token,
    tokenType: result.token_type,
    expiresIn: Number(result.expires_in),
  })),
);

export type NativeMessageAccessTokenResponse = InferOutput<
  typeof NativeMessageAccessTokenResponseDataSchema
>;
