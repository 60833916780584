export enum MESSAGE_STATUS$SERVER {
  SAVED_MESSAGE = "saved_message",
  START_AGENT = "start_agent",
  ACTION = "action",
  ANSWER_CONTINUE = "answer_continue",
  CONTINUE_START_AGENT = "continue_start_agent",
  ANSWER_COMPLETE = "answer_complete",
  ERROR = "error",
  CONTENT_FILTER = "content_filter",
  CANCELED = "canceled",

  SUGGEST_QUESTIONS = "suggest_questions",
}

export enum MESSAGE_STOP_REASON$SERVER {
  STOP = "stop",
  LENGTH = "length",
  CANCELED = "canceled",
  CONTENT_FILTER = "content_filter",
}
