const envMode = import.meta.env.MODE;
const debugMode = Boolean(import.meta.env.VITE_GEPETO_DEBUG === "true");

class LoggerCore {
  constructor(
    protected readonly remoteBaseUrl = import.meta.env
      .VITE_DEBUG_REMOTE_BASE_URL ?? "",
  ) {}

  debug(...args: any[]) {
    console.debug(...args);
  }

  debugRemote(data: Record<string, any>) {
    this.postMessage(data);
  }

  errorRemote(data: Record<string, any>) {
    this.postMessage(data);
  }

  postMessage(data: Record<string, any>) {
    if (!this.remoteBaseUrl) return;

    fetch(`${this.remoteBaseUrl}?scope=${data.scope ?? ""}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(
        Object.assign({}, data, {
          metadata: {
            envMode,
            timestamp: new Date().toLocaleString("ko", {
              timeZone: "Asia/Seoul",
            }),
            userAgent: window.navigator.userAgent,
            location: window.location.href,
          },
        }),
      ),
    });
  }
}

class LoggerProduction extends LoggerCore {
  debugRemote(data: Record<string, any>) {
    return;
  }
}

export const logger = debugMode ? new LoggerCore() : new LoggerProduction();
