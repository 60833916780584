import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useMessageSocketResponseStore } from "../message-list";
import type { SuggestionsRequest } from "./suggestion-query.schema";
import { getSuggestionQueryKey } from "./use-query-suggestions";

export const useMutationSuggestions = () => {
  const queryClient = useQueryClient();

  const { setIsFetchingSuggestQuestions, setIsFetchedSuggestQuestions } =
    useMessageSocketResponseStore();

  return useMutation({
    mutationFn: async (suggestRequest: SuggestionsRequest) => {
      await queryClient.invalidateQueries({
        queryKey: getSuggestionQueryKey(suggestRequest),
      });
    },

    onMutate: () => {
      setIsFetchingSuggestQuestions();
      // TODO?: resetQuestionSuggestions();
    },

    onSuccess: () => {
      setIsFetchedSuggestQuestions();
    },
  });
};
