import type { MouseEventHandler, PropsWithChildren } from "react";
import {
  DeleteButton,
  SendingButton,
  SttMicButton,
} from "~features/ui/prompt/buttons";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";
import { usePromptContext } from "./context";
import { useRawTextContext } from "./raw-text.context";

import { useIsPendingContext } from "~features/search";
import styles from "./buttons.module.scss";

type PromptButtonsProps = PropsWithChildren<{
  className?: string;
}>;

export function PromptButtons({ className, children }: PromptButtonsProps) {
  const rawTextContext = useRawTextContext();
  const promptContext = usePromptContext();
  const isPendingContext = useIsPendingContext();

  const onClickDeleteButton = createEventHandler({
    handler: () => {
      rawTextContext.setRawText("");
      promptContext.textareaRef.current?.focus();
    },
    stopPropagation: false,
  });

  const onClickSubmitButton = () => promptContext.sendPrompt();

  return (
    <div className={cn(styles.buttons, className)}>
      {rawTextContext.rawText.length > 0 && (
        <DeleteButton onClick={onClickDeleteButton} />
      )}

      {promptContext.isSttEnabled && <SttMicButton />}

      {children ?? (
        <SendingButton
          type={isPendingContext.isPending ? "stop" : "default"}
          onSubmit={onClickSubmitButton}
          onClick={promptContext.cancelPrompt}
        />
      )}
    </div>
  );
}

type PromptButtonProps = PropsWithChildren<{
  onClickButton: MouseEventHandler<HTMLButtonElement>;
  className?: string;
}>;

export function PromptButton({
  children,
  className,
  onClickButton,
}: PromptButtonProps) {
  return (
    <button type="button" onClick={onClickButton} className={cn(className)}>
      {children}
    </button>
  );
}

export function PromptButtonSubmit({
  children,
  className,
  onClickButton,
}: PromptButtonProps) {
  return (
    <button type="submit" onClick={onClickButton} className={cn(className)}>
      {children}
    </button>
  );
}
