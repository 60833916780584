import { cn } from "~utils/class-names";

type SkeletonPulseProps = {
  className?: string;
  animationDelay?: string;
};

export function SkeletonPulse({
  className,
  animationDelay = "0",
}: SkeletonPulseProps) {
  return (
    <div
      className={cn(
        "animate-pulse w-full h-3 rounded-sm bg-slate-300 shrink-0",
        className,
      )}
      style={{ animationDelay }}
    />
  );
}
