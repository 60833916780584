import { useCallback } from "react";
import {
  createAndroidAppDownloadModalContents,
  createIosAppDownloadModalContents,
  useModal,
} from "~features/modals";
import { Platforms, useUserAgent } from "~features/user-agent";
import { useWindowWidth } from "~hooks/use-window-width";
import { AppDownloadButton } from "./app-download-button";
import {
  openAndroidAppDownload,
  openIosAppDownload,
  openWindowsAppDownload,
} from "./utils";

type AppDownloadButtonsNotMobileProps = {
  isWindows: boolean;
  isHome?: boolean;
};

function AppDownloadButtonsNotMobile({
  isWindows,
  isHome,
}: AppDownloadButtonsNotMobileProps) {
  const { isMobileSmallSize: isMobileSize } = useWindowWidth();
  const { setModalContents } = useModal();

  const windowsAppDownloader = useCallback(() => {
    openWindowsAppDownload();
  }, []);

  const androidAppDownloader = useCallback(() => {
    return isMobileSize
      ? openAndroidAppDownload()
      : setModalContents(
          createAndroidAppDownloadModalContents(openAndroidAppDownload),
        );
  }, [setModalContents, isMobileSize]);

  const iosAppDownloader = useCallback(() => {
    return isMobileSize
      ? openIosAppDownload()
      : setModalContents(createIosAppDownloadModalContents(openIosAppDownload));
  }, [setModalContents, isMobileSize]);

  return (
    <>
      {!isWindows && !isMobileSize && (
        <AppDownloadButton
          type={Platforms.windows}
          isHome={isHome}
          onClick={windowsAppDownloader}
          onKeyDown={windowsAppDownloader}
        />
      )}

      <AppDownloadButton
        type={Platforms.android}
        isHome={isHome}
        onClick={androidAppDownloader}
        onKeyDown={androidAppDownloader}
      />

      <AppDownloadButton
        type={Platforms.ios}
        isHome={isHome}
        onClick={iosAppDownloader}
        onKeyDown={iosAppDownloader}
      />
    </>
  );
}

type AppDownloadButtonsProps = {
  isHome?: boolean;
};

export function AppDownloadButtons({ isHome }: AppDownloadButtonsProps) {
  const { isMobile, isWindows } = useUserAgent();
  if (isMobile) {
    return null;
  }

  return <AppDownloadButtonsNotMobile isWindows={isWindows} isHome={isHome} />;
}
