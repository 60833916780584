import { useEffect } from "react";
import { useChannel } from "~features/channel";
import { PromptWithCompletion, useMobileLayer } from "~features/prompt";
import { MobileLayer } from "~features/prompt/mobile-layer";
import { HeaderWithPrompt } from "~layouts/header";

export function SearchHeaderMobile() {
  const { isChanged, channelId } = useChannel();
  const { isOpen, setIsOpen } = useMobileLayer();

  const openMobileLayer = () => {
    setIsOpen(true);
  };

  const closeMobileLayer = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    if (channelId && isChanged) {
      setIsOpen(false);
    }
  }, [isChanged, channelId, setIsOpen]);

  return (
    <HeaderWithPrompt>
      {isOpen ? (
        <MobileLayer>
          <PromptWithCompletion
            className="absolute top-0"
            focusOnMount
            afterSendPrompt={closeMobileLayer}
          />
        </MobileLayer>
      ) : (
        <div onClick={openMobileLayer}>
          <PromptWithCompletion
            className="absolute top-0"
            onFocusTextarea={openMobileLayer}
          />
        </div>
      )}
    </HeaderWithPrompt>
  );
}
