import { useQueryClient } from "@tanstack/react-query";
import {
  type PropsWithChildren,
  forwardRef,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { RESPONSE_MESSAGE_TYPES } from "~clients/socket";
import { ReloadSuggestionButton } from "~components/chat/question-suggestions/reload-suggestion-button";
import { useMutationSuggestions } from "~components/chat/question-suggestions/use-mutation-suggestions";
import { TIMES } from "~constants/times";
import { useChannel } from "~features/channel";
import { MarkdownRenderer } from "~features/markdown-renderer";
import { usePersonas } from "~features/personas";
import { useMutationSendMessage } from "~features/youtube-summary/use-mutation-send-message";
import { cn } from "~utils/class-names";
import { cloneObject } from "~utils/clone-object";
import { MessageAssistantOptionButtons } from "./message-assistant-option-buttons";
import { MessageAssistantPersona } from "./message-assistant-persona";
import { MessageAssistantScrollObserverTarget } from "./message-assistant-scroll-observer-target";
import { MessageAssistantSuggestion } from "./message-assistant-suggestion";
import { MessageAssistantSuggestionLoading } from "./message-assistant-suggestion-loading";
import { checkIsInAnswer } from "./message-assistant-utils";
import { MessageContainer } from "./message-container";
import {
  type MessageList,
  type MessageOne,
  SuggestionsLoadingStatus,
} from "./message-list.schema";
import { MessageLoadingIcon } from "./message-loading-icon";
import { MessageRowContentOnly } from "./message-row-content";

import { QUERY_KEYS } from "~features/providers/tanstack-query";
import suggestionStyles from "./message-assistant-suggestion.module.scss";
import styles from "./message-assistant.module.scss";
import rowStyles from "./message-row.module.scss";

type MessageAssistantLastProps = PropsWithChildren<
  Pick<
    MessageOne,
    | "id"
    | "content"
    | "answerPersonaId"
    | "suggestions"
    | "suggestionsLoading"
    | "type"
    | "pausedByLength"
  > & {
    scrollToBottom?: () => void;
  }
>;

/**
 * @todo 다른 persona 답변 컴포넌트 분리
 */
export const MessageAssistantLast = forwardRef<
  HTMLDivElement,
  MessageAssistantLastProps
>(function MessageAssistantLast(props, inAnswerScrollTargetRef) {
  const queryClient = useQueryClient();
  const { channelId } = useChannel();
  const sendMessage = useMutationSendMessage();

  const { persona: originalPersona } = usePersonas();
  const { getPersonaById } = usePersonas();
  const persona = useMemo(
    () => getPersonaById(props.answerPersonaId),
    [props.answerPersonaId, getPersonaById],
  );

  const fetchSuggestionsMutation = useMutationSuggestions();

  const isFeedbackButtonsVisible = useMemo(
    () => props.type === RESPONSE_MESSAGE_TYPES.FINISH_ANSWER,
    [props.type],
  );

  const messageListQueryKey = useMemo(
    () => QUERY_KEYS.SUMMARY.getYoutubeChatQueryKey(channelId),
    [channelId],
  );

  const [isSuggestionLoading, isSuggestionSuccess, isSuggestionFailed] =
    useMemo(
      () => [
        props.suggestionsLoading === SuggestionsLoadingStatus.LOADING,
        props.suggestionsLoading === SuggestionsLoadingStatus.SUCCESS,
        props.suggestionsLoading === SuggestionsLoadingStatus.FAILED,
      ],
      [props.suggestionsLoading],
    );

  const timerIdRef = useRef(0);

  const onClickSuggestion = (suggestion: string) => {
    sendMessage.mutateAsync({
      message: suggestion,
      personaId: originalPersona.id,
      channelId,
    });

    props.scrollToBottom?.();
  };

  if (props.type === RESPONSE_MESSAGE_TYPES.LOADING) {
    return (
      <>
        <MessageLoadingIcon />
        <MessageAssistantScrollObserverTarget ref={inAnswerScrollTargetRef} />
      </>
    );
  }

  const onClickReloadSuggestion = () => {
    fetchSuggestionsMutation.mutateAsync({ channelId, messageId: props.id });
    queryClient.setQueryData(
      messageListQueryKey,
      (prevMessages: MessageList) => {
        const newMessages = cloneObject(prevMessages);

        const targetMessage = newMessages.messages.find(
          (message) => message.id === props.id,
        );
        if (targetMessage) {
          targetMessage.suggestionsLoading = SuggestionsLoadingStatus.LOADING;
        }

        return newMessages;
      },
    );
  };

  useEffect(() => {
    if (isSuggestionLoading) {
      timerIdRef.current = window.setTimeout(() => {
        queryClient.setQueryData<MessageList>(
          messageListQueryKey,
          (prevMessages) => {
            if (!prevMessages) return;

            const newMessages = cloneObject(prevMessages);
            const targetMessage = newMessages.messages.find(
              (message) => message.id === props.id,
            );
            if (targetMessage) {
              targetMessage.suggestionsLoading =
                SuggestionsLoadingStatus.FAILED;
            }
            return newMessages;
          },
        );
      }, TIMES.SEC * 30);
    }

    if (isSuggestionSuccess && timerIdRef.current > 0) {
      window.clearTimeout(timerIdRef.current);
      timerIdRef.current = 0;
    }
    return () => {
      if (timerIdRef.current > 0) {
        window.clearTimeout(timerIdRef.current);
        timerIdRef.current = 0;
      }
    };
  }, [
    isSuggestionLoading,
    isSuggestionSuccess,
    messageListQueryKey,
    props.id,
    queryClient,
  ]);

  return (
    <>
      <MessageContainer
        className={cn(
          styles.padding_base,
          styles.padding_x0,
          styles.padding_t18,
          styles.padding_b18,
          styles.bg_light_blue,
        )}
      >
        <MessageAssistantPersona name={persona?.name ?? ""} />

        <MessageRowContentOnly
          contentClassName={cn(
            {
              [styles.before_answer]:
                checkIsInAnswer(props.type) &&
                props.content.trim().length === 0,
            },
            rowStyles.row_gap_0,
          )}
        ></MessageRowContentOnly>

        <MessageRowContentOnly
          contentClassName={cn({
            [styles.in_answer]: checkIsInAnswer(props.type),
          })}
        >
          <MarkdownRenderer contents={props.content} />
        </MessageRowContentOnly>

        {props.type === RESPONSE_MESSAGE_TYPES.IN_ANSWER && (
          <MessageAssistantScrollObserverTarget ref={inAnswerScrollTargetRef} />
        )}

        {isFeedbackButtonsVisible && (
          <MessageAssistantOptionButtons
            messageId={props.id}
            messageContent={props.content}
            pausedByLength={props.pausedByLength}
          />
        )}
      </MessageContainer>

      {/*
       * @todo 컴포넌트분리
       */}
      <MessageContainer
        className={cn(
          suggestionStyles.li_container,
          styles.padding_base,
          styles.padding_x0,
        )}
      >
        <MessageRowContentOnly
          rowClassName={cn(styles.bg_white)}
          contentClassName={cn(
            rowStyles.row_gap_0,
            suggestionStyles.content_container,
          )}
        >
          {isSuggestionLoading && <MessageAssistantSuggestionLoading />}

          {isSuggestionSuccess &&
            props.suggestions.map((suggestion) => (
              <MessageAssistantSuggestion
                key={`message-assistant-suggestion-${suggestion}`}
                suggestion={suggestion}
                onClick={() => onClickSuggestion(suggestion)}
              />
            ))}

          {isSuggestionFailed && (
            <ReloadSuggestionButton
              channelId={channelId}
              messageId={props.id}
              onClick={onClickReloadSuggestion}
              className={suggestionStyles.suggestion_button}
            />
          )}
        </MessageRowContentOnly>
      </MessageContainer>
    </>
  );
});
