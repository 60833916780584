import { type FormEventHandler, useCallback, useRef, useState } from "react";
import TextAreaAutoSize from "react-textarea-autosize";
import {
  useMutationSendFeedbackMessage,
  useQueryRatingMessages,
} from "~features/feedback";
import { cn } from "~utils/class-names";
import { MessageAssistantFeedbackFormButton } from "./message-assistant-feedback-form-button";
import { MessageAssistantFeedbackSubmittedMessage } from "./message-assistant-feedback-submitted-message";

import buttonStyles from "./message-assistant-feedback-form-button.module.scss";
import styles from "./message-assistant-feedback-form.module.scss";

type MessageAssistantFeedbackFormProps = {
  messageId: string;
  closeFeedbackForm: () => void;
};

const FEEDBACK_MESSAGE_PLACEHOLDER =
  "더 좋은 답변을 드릴 수 있도록 평가 이유를 알려주세요!(선택사항)";

const FORM_INPUT_NAMES = {
  REASON: "feedback-reason",
  MESSAGE: "feedback-message",
} as const;

export function MessageAssistantFeedbackForm(
  props: MessageAssistantFeedbackFormProps,
) {
  const formRef = useRef<HTMLFormElement | null>(null);
  const { data: ratingReasons } = useQueryRatingMessages();
  const sendFeedbackMutation = useMutationSendFeedbackMessage();
  const [submitAvailable, setSubmitAvailable] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const onClickCloseButton = () => {
    props.closeFeedbackForm();
  };

  const getFormData = useCallback(
    () => new FormData(formRef.current ?? undefined),
    [],
  );

  const submitFeedback = async () => {
    setSubmitted(true);

    const formData = getFormData();
    sendFeedbackMutation.mutateAsync({
      messageId: props.messageId,
      reasonsIds: formData.getAll(FORM_INPUT_NAMES.REASON) as string[],
      feedbackMessage: formData.get(FORM_INPUT_NAMES.MESSAGE) as string,
    });
  };

  const onChangeForm: FormEventHandler = (_e) => {
    setSubmitAvailable(getFormData().has(FORM_INPUT_NAMES.REASON));
  };

  const onClickSubmitButton: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    submitFeedback();
  };

  return (
    <div className={styles.feedback_form_container}>
      {!submitted && (
        <form
          ref={formRef}
          onChange={onChangeForm}
          onSubmit={onClickSubmitButton}
          className={styles.form}
        >
          <TextAreaAutoSize
            title=""
            name={FORM_INPUT_NAMES.MESSAGE}
            className={cn(styles.textarea)}
            placeholder={FEEDBACK_MESSAGE_PLACEHOLDER}
            wrap="soft"
          />

          <div className={styles.reason_checkboxes}>
            {ratingReasons
              ?.filter((ratingReason) => ratingReason.enabled)
              .map((ratingReason) => (
                <div
                  key={`messages-assistant-feedback-${props.messageId}-${ratingReason._id}`}
                  className=""
                >
                  <input
                    type="checkbox"
                    name={FORM_INPUT_NAMES.REASON}
                    value={ratingReason._id}
                    id={`feedback-reason-${props.messageId}-${ratingReason._id}`}
                  />
                  <label
                    htmlFor={`feedback-reason-${props.messageId}-${ratingReason._id}`}
                  >
                    {ratingReason.message}
                  </label>
                </div>
              ))}
          </div>

          <div className={styles.buttons_container}>
            <MessageAssistantFeedbackFormButton
              available={true}
              title="닫기"
              onClick={onClickCloseButton}
            />
            <MessageAssistantFeedbackFormButton
              available={submitAvailable}
              title="제출"
              onClick={submitFeedback}
              className={buttonStyles.submit}
            />
          </div>
        </form>
      )}

      {submitted && <MessageAssistantFeedbackSubmittedMessage />}
    </div>
  );
}
