import { VotingButtons } from "~components/chat/message-buttons";
import { cn } from "~utils/class-names";
import { createEventHandler } from "~utils/create-event-handler";

import feedbackStyles from "./message-assistant-feedback.module.scss";

type MessageAssistantDownVoteButtonProps = {
  messageId: string;
  voted: boolean;
  reasonSubmitted: boolean;
  onClick: () => void;
};

const DOWN_VOTE_TITLE = "아쉬워요";

export function MessageAssistantDownVoteButton(
  props: MessageAssistantDownVoteButtonProps,
) {
  const onClickDownVote = createEventHandler({
    handler: () => {
      if (props.reasonSubmitted) return;
      props.onClick();
    },
  });

  return (
    <button
      type="button"
      className={cn(feedbackStyles.feedback_button, {
        [feedbackStyles.voted]: props.voted,
      })}
      aria-label={DOWN_VOTE_TITLE}
      onClick={onClickDownVote}
      disabled={props.reasonSubmitted}
    >
      <VotingButtons.DownSvg />
    </button>
  );
}
