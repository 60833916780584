import type { PropsWithChildren } from "react";
import { cn } from "~utils/class-names";
import { MessageRow } from "./message-row";

import styles from "./message-row.module.scss";

type MessageRowContentOnlyProps = PropsWithChildren<{
  rowClassName?: string;
  contentClassName?: string;
}>;

export function MessageRowContentOnly(props: MessageRowContentOnlyProps) {
  return (
    <MessageRow className={props.rowClassName}>
      <div className={cn(styles.content, props.contentClassName)}>
        {props.children}
      </div>
    </MessageRow>
  );
}
