import {
  type InferOutput,
  literal,
  minLength,
  object,
  parse,
  pipe,
  string,
  transform,
} from "valibot";

export type ChannelId = InferOutput<typeof ChannelIdSchema>;

const ChannelIdSchema$Server = object({
  /** @description 채널 생성 성공 여부 - 실패하면 schema validation 에러 */
  result: literal(true),
  inserted_id: pipe(string(), minLength(1)),
});

const ChannelIdSchema = pipe(
  ChannelIdSchema$Server,
  transform(({ inserted_id }) => ({ channelId: inserted_id })),
);

export const transformResponse = (response: unknown) =>
  parse(ChannelIdSchema, response, { abortEarly: true });
