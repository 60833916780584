import ReactGA from "react-ga4";

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries
// const apiKey = import.meta.env.VITE_GEPETO_FB_APIKEY;
// const authDomain = import.meta.env.VITE_GEPETO_FB_AUTH_DOMAIN;
// const projectId = import.meta.env.VITE_GEPETO_FB_PROJECT_ID;
// const storageBucket = import.meta.env.VITE_GEPETO_FB_STORAGE_BUCKET;
// const messagingSenderId = import.meta.env.VITE_GEPETO_FB_MESSAGE_SENDER_ID;
// const appId = import.meta.env.VITE_GEPETO_FB_APP_ID;
const measurementId: string =
  import.meta.env.VITE_GEPETO_FB_MEASUREMENT_ID ?? "";

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: apiKey,
//   authDomain: authDomain,
//   projectId: projectId,
//   storageBucket: storageBucket,
//   messagingSenderId: messagingSenderId,
//   appId: appId,
//   measurementId: measurementId
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export default getAnalytics(app);

ReactGA.initialize(measurementId);
