import { create } from "zustand";

type TextareaHeightStates = {
  height: number;
  setHeight: (height: number) => void;
};

export const useTextInputContainerHeight = create<TextareaHeightStates>(
  (set) => ({
    height: 107,
    setHeight: (height) => set({ height }),
  }),
);
